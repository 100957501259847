import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { BetType, BetCategory, BetSpecialType } from '@obr-core/config/betting'
import { onGetEventByIdErrors } from '@obr-core/errors/events.errors'
import { parseRacesH2H } from '@obr-core/resources/RaceResource'
import { errorLogger } from '@obr-core/services/ErrorLogger'

export class EventResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/events`
    private readonly apiPathEventBrowser: string = `${HTTP_REST_V2}/eventbrowser`
    private readonly apiPathPickbets: string = `${HTTP_REST_V2}/events/pickbets`

    /**
     * Method returns calendar events
     */
    public async getCalendarEvents(
        date: string
    ): Promise<OBR.Events.CalendarEvent[]> {
        try {
            const res = await http.get(`${this.apiPath}/date/${date}`)

            return parseCalendarEvents(res.data)
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method returns event by id
     */
    public async getEventById(id: string): Promise<OBR.Events.Event | null> {
        try {
            const url = `${this.apiPath}/${id}`
            const reponse = await http.get(url)

            return this.parseEvent(reponse.data)
        } catch (error: any) {
            onGetEventByIdErrors(error)
        }

        return null
    }

    /**
     * Method returns H2H event by id
     */
    public async getH2HEventById(id: string): Promise<OBR.Events.Event> {
        try {
            const { data } = await http.get<{
                races: OBR.Race.Race[]
                event: OBR.Events.Event
            }>(`${this.apiPath}/h2h/${id}`)

            data.races = parseRacesH2H(data.races, data.event)
            return this.parseEvent(data)
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method returns Special event by id
     */
    public async getSpecialEventById(id: string): Promise<OBR.Events.Event> {
        try {
            const { data } = await http.get(`${this.apiPath}/special/${id}`)

            // TODO: assure the same shape of response like for all specials in `getSpecials`
            // Chanage backend response
            return Promise.resolve(
                parseSpecialEvent({ ...data.event, races: data.races })
            )
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method collects events with filter options
     * @param date - Date string format 'YYYY-MM-DD', antepost: "1"
     */
    public async getAllEvents(
        filter: OBR.Events.EventFilter
    ): Promise<OBR.Events.Event[]> {
        const isAntepost = filter.antepost === '1'
        const isVirtual = filter.virtual

        // Filter options
        const filters = isAntepost
            ? `antepost=${filter.antepost}`
            : isVirtual
            ? 'virtual=1'
            : `date=${filter.date}`

        try {
            const { data } = await http.get(
                `${this.apiPathEventBrowser}?${filters}`
            )

            const events = data?.events || []

            return isAntepost
                ? this.parseAntePost(events)
                : this.parseCurrentEvent(events)
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method return pickbets by events
     */
    public async getPickbets(eventId: string): Promise<OBR.Events.Pickbet[]> {
        try {
            const url = `${this.apiPathPickbets}/event/${eventId}`
            const response = await http.get<OBR.Events.Pickbet[]>(url)

            return response.data
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method return pickbets by raceid
     */
    public async getPickbetsByRaceId(
        raceId: string
    ): Promise<OBR.Events.Pickbet[]> {
        try {
            const url = `${this.apiPathPickbets}/race/${raceId}`
            const response = await http.get<OBR.Events.Pickbet[]>(url)

            return response.data
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Method return pickbet
     */
    public async getPickbet(
        pickbetId: string
    ): Promise<OBR.Events.Pickbet | null> {
        try {
            const url = `${this.apiPathPickbets}/pickbet/${pickbetId}`
            const response = await http.get<OBR.Events.Pickbet[]>(url)

            return response.data?.[0] || null
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Get Specials for specials page
     */
    public async getSpecials(): Promise<OBR.Events.Event[]> {
        try {
            const url = `${this.apiPath}/specials`
            const response = await http.get(url)

            return response.data.map((event: OBR.Events.Event) =>
                parseSpecialEvent(event)
            )
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    private parseEvent(data: any): OBR.Events.Event {
        return parseEvent(data)
    }

    /**
     * Parse response to filter API for AntePost True and Special False
     * @param response API response
     */
    private parseAntePost(events: OBR.Events.Event[]): OBR.Events.Event[] {
        const antePostEvents: OBR.Events.Event[] = events.filter(
            (event: OBR.Events.Event) => {
                return event.is_ante_post === true
            }
        )

        return antePostEvents
    }

    /**
     * Parse response to filter API
     * @param response API response
     */
    private parseCurrentEvent(events: OBR.Events.Event[]): OBR.Events.Event[] {
        return [...events]
    }
}

/**
 * Parse Special Event Response
 * @param response API response
 */
export function parseCalendarEvents(
    data: OBR.Events.CalendarEventsResponse
): OBR.Events.CalendarEvent[] {
    return [...data.events]
}

/**
 * Parser for all event
 * @param data response from event call
 * @returns { OBR.Events.Event }
 */
export function parseEvent(data: any): OBR.Events.Event {
    data.races.forEach((race: any) => {
        if (!race.id_event) {
            race.id_event = data.event.id
        }
        if (!race.country) {
            race.country = data.event.country
        }
        if (!race.event_title) {
            race.event_title = data.event.title
        }
    })
    return {
        ...data.event,
        id: `${data.event.id}`,
        ...{ races: [...data.races] },
        ...{
            media: Array.isArray(data.media) ? null : data.media,
        },
        banner: data.banners?.[0],
    }
}

/**
 * Parser special event
 */
export function parseSpecialEvent(event: OBR.Events.Event): OBR.Events.Event {
    // TODO: change ids to strings in backend, change names of Ids from just id to idEvent, idRace , idRunner ...
    // Add post_time
    return {
        ...event,
        id: `${event.id}`,
        special_type: BetSpecialType.SPECIAL,
        races: event.races.map((race: OBR.Race.Race) => {
            return {
                ...race,
                id: `${race.id}`,
                id_event: `${event.id}`,
                bet_types: [
                    {
                        bet_type: BetType.WIN,
                        categories: [BetCategory.FIXED],
                    },
                ],
                event_title: event.title,
                special_type: BetSpecialType.SPECIAL,
                runners: race.runners.map((runner: any, index: number) => {
                    return {
                        ...runner,
                        id: `${runner.id}`,
                        program_number: index + 1,
                        odds_fxp: 0,
                        odds_prc: 0,
                        id_subject: `${runner.id}`,
                        special_type: BetSpecialType.SPECIAL,
                    }
                }),
            }
        }),
    }
}
