import { router } from '@obr-core/router/Router'
import { userStoreService } from '@obr-core/services/store'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { displayReleaseInfoModal } from '@obr-core/helpers/release-info.helpers'
import { abortController } from '@obr-core/lib/http'
import { ROUTE_NOT_FOUND } from '@obr-core/config/route'

/**
 * Handle all generic errors, also used for handaling vue errors
 *
 * i.e. show error in console and report to (Sentry)
 */
export function onGenericError(error: Error | OBR.Errors.SimpleError) {
    // Create exception on sentry
    if (error instanceof Error) {
        errorLogger.createException(error)
    }

    // Show console error
    window.console.error(error)
}

/**
 * Handle authentication error
 *
 * i.e. display error modal with session expired message
 */
export function onAuthenticationError() {
    userStoreService.authRequired(true)
}

export function onCanceledRequestError(): void {
    // no-op
}

export function onCSRFTokenExpired(): void {
    // abort all requests after CSRF token expires
    // and display the release info modal to make user reload the page
    abortController.abort()
    displayReleaseInfoModal()
}

/**
 * Handle authentication error before
 */
export function onAuthenticationBeforeError() {
    userStoreService.authSessionError()
}

/**
 * Not found, used in Event Card or Race Card
 *
 * e.g. if race/event id is not correct or response is 404
 */
export function onNotFoundError() {
    router.getRouter()?.push({ name: ROUTE_NOT_FOUND })
}
