import { AppResource } from '@obr-core/resources/AppResource'

/**
 * User Service
 * Singleton
 */
export class AppService {
    private static instance: AppService
    private appResource: AppResource

    private constructor() {
        this.appResource = new AppResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): AppService {
        if (AppService.instance === undefined) {
            AppService.instance = new AppService()
        }
        return AppService.instance
    }

    /**
     * Get bootstrap data
     */
    public async getBootstrap() {
        return this.appResource.getBootstrap()
    }

    public async getSSR() {
        return this.appResource.getSSRBootstrap()
    }
}
