import { BonusResource } from '@obr-core/resources/BonusResource'

/**
 * Bonus Service
 * Singleton
 */
export class BonusService {
    private static instance: BonusService
    private bonusResource: BonusResource

    private constructor() {
        this.bonusResource = new BonusResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): BonusService {
        if (BonusService.instance === undefined) {
            BonusService.instance = new BonusService()
        }
        return BonusService.instance
    }

    /**
     * claim a bonus
     */
    public claimBonus(bonusId: string) {
        return this.bonusResource.claimBonus(bonusId)
    }

    /**
     * redeem  bonus
     */
    public async redeemBonus(bonus_ids: number[]) {
        return this.bonusResource.redeemBonus(bonus_ids)
    }

    /**
     * get all information about Bonus
     */
    public async getBonusesOverview() {
        return this.bonusResource.getBonusesOverview()
    }

    /**
     * get all information about Bonus details
     */
    public getBonusDetails(idBonusChunk: number) {
        return this.bonusResource.getBonusDetails(idBonusChunk)
    }
}
