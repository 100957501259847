import { defineStore } from 'pinia'

import { userCookieService } from '@obr-core/services/UserCookieService'
import { UserWebSocketsService } from '@obr-core/services/websockets/UserWebSocketsService'
import { WS_JOIN_BALANCE_CHANNEL } from '@obr-core/config/web-sockets'
import { postMessageService } from '@obr-core/services/PostMessageService'
import {
    POST_MESSAGE_ID_REQUEST_LOGIN,
    POST_MESSAGE_ID_SHOW_DEPOSIT,
    POST_MESSAGE_ID_REQUEST_REGISTRATION,
} from '@obr-core/config/post-messages'
import { isStandalone } from '@obr-core/helpers/app.helpers'
import { AuthService, UserService } from '@obr-core/services/api'
import {
    raceStoreService,
    betslipStoreService,
    bonusStoreService,
    userStoreService,
} from '@obr-core/services/store'
import { ErrorService } from '@obr-core/services/ErrorService'
import { mapLocaleToLanguage } from '@obr-core/helpers/i18n.helpers'
import { useBonuses } from '@obr-core/store/bonuses'
import useLoginDialog from '@obr-ui/components/LoginDialog/useLoginDialog'
import { useI18n } from '@obr-core/store/i18n'
import { AcceptBetsChanges } from '@obr-ui/components/Settings/config'
import { UserSettings } from '@obr-core/config/user'
import { initialState } from './state'
import { emitter } from '@obr-core/services/EmitterService'
import {
    EMITTER_WEBCOMPONENT_LOGIN,
    EMITTER_WEBCOMPONENT_REGISTER,
    EMITTER_WEBCOMPONENT_SESSION_EXPIRED,
    EMITTER_WEBCOMPONENT_SHOW_DEPOSIT,
} from '@obr-core/config/emitter'

const authService = AuthService.getInstance()
const userService = UserService.getInstance()

export const useUser = defineStore('obr-store/user', {
    state: initialState,
    actions: {
        onResetState() {
            this.$reset()
        },
        /**
         * initialize user module
         */
        async onInit() {
            /**
             *  If user from SSR is logged out, remove data from state
             *
             * i.e. exipred session
             */
            if (!this.logged_in) {
                await this.onLogout()
                return
            }

            this.onSetLoading(true)

            try {
                await this.onGetMe()
            } catch (error) {
                ErrorService.getInstance().preventDefault(error as Error)
                // maybe session is expired
            }
            this.onSetLoading(false)
        },

        /**
         * User login
         */
        async onLoggedIn(payload: OBR.User.LoginUserInput) {
            const user: OBR.User.LoginResponse = await authService.login(
                payload
            )
            userStoreService.setToken(user.auth_token)
            this.onSetUser(user)
            this.onSetLoggedIn(!!user)
            useI18n().loadBaseModules()

            await this.onGetMe()
        },

        /**
         * User logout action, the call remove session and after we reinit user module
         */
        async onLogout() {
            userCookieService.removeSession()
            // reset user store state
            this.onResetState()
            // reset user favourites map
            raceStoreService.resetFavourites()
            // remove freebets ids from bets
            betslipStoreService.removeAllFreebetsIdsAndRms()
            // remove all bonuses from store
            bonusStoreService.resetBonuses()

            await authService.logout()

            UserWebSocketsService.getInstance().logoutSuccess()
        },

        /**
         * User is only logged out on frontend. The logout request is not sent.
         */
        onSoftLogout(): void {
            if (isStandalone()) return

            // First reset state
            this.onResetState()
            // Explicitly set logout to false as initial state could be coming from SSR and be loggedin in
            this.onSetLoggedIn(false)

            // if it's a b2b, send post message to trigger redirection
            // to home page of the host website
            userStoreService.authRequired(true)
        },

        /**
         * called from ErrorService when status code is 401
         */
        async onAuthSessionError() {
            await this.onLogout()
        },

        /**
         * call to retrieve user information
         */
        async onGetMe() {
            try {
                const user: OBR.User.MeResponse | null =
                    await userService.getMe()

                if (user) {
                    this.onSetLoggedIn(true)
                    this.onSetUser(user)
                    this.onStoreSettings(user.settings)

                    if (WS_JOIN_BALANCE_CHANNEL) {
                        // Join balance update / private channel
                        UserWebSocketsService.getInstance().joinBalanceChannel()
                    }

                    //update balance
                    await this.onGetBalance()
                } else {
                    this.onLogout()
                }
            } catch (e) {
                this.onSetLoggedIn(false)
                this.onLogout()
            }
        },

        /**
         * Login required
         */
        onAuthRequired(sessionExpired: boolean = false) {
            this.onSetLoggedIn(false)

            if (!isStandalone()) {
                emitter.emit(
                    sessionExpired
                        ? EMITTER_WEBCOMPONENT_SESSION_EXPIRED
                        : EMITTER_WEBCOMPONENT_LOGIN
                )
                // Request login dialog from parent Window (PostMessages API)
                postMessageService.send(POST_MESSAGE_ID_REQUEST_LOGIN, {})
            } else {
                useLoginDialog().openDialog()
            }
        },
        /**
         * Registration required, invoke registration
         */
        onRegistrationRequired() {
            this.onSetLoggedIn(false)

            if (!isStandalone()) {
                emitter.emit(EMITTER_WEBCOMPONENT_REGISTER)
                postMessageService.send(
                    POST_MESSAGE_ID_REQUEST_REGISTRATION,
                    {}
                )
            }
        },
        /**
         * Deposit required
         */
        onDepositRequired() {
            if (!isStandalone()) {
                // Request deposit page from parent window (PostMessages API)
                postMessageService.send(POST_MESSAGE_ID_SHOW_DEPOSIT, {})
                emitter.emit(EMITTER_WEBCOMPONENT_SHOW_DEPOSIT)
            } else {
                //TODO: Display deposit popup / page?
            }
        },

        /**
         * get balance info
         */
        async onGetBalance() {
            const balance = await userService.balance()
            this.onSetUserBalance(balance)
            // update freebet by balance
            useBonuses().onSetFreebetsFromBalance(balance)
        },

        onSetUser(user: Partial<OBR.Store.UserState>) {
            Object.assign(this, {
                ...user,
            })
        },
        setToken(token: string) {
            this.token = token
        },
        setLanguage(language: string) {
            this.locale = language
        },
        onStoreSettings(user: Partial<OBR.Store.UserState>) {
            for (const key in user) {
                if (Object.prototype.hasOwnProperty.call(user, key)) {
                    if (
                        Object.values(UserSettings).includes(
                            key as UserSettings
                        )
                    ) {
                        userService.storeSetting(
                            key as UserSettings,
                            user[key as keyof OBR.Store.UserState]
                        )
                    }
                }
            }
        },
        onSetLoading(status: boolean) {
            this.loading = status
        },
        onSetLoggedIn(status: boolean) {
            this.logged_in = status
        },
        onSetLoggedInNode(status: boolean) {
            this.logged_in_node = status
        },
        onSetUserBalance(
            balance: OBR.User.Balance | OBR.WebSockets.BalanceInfo
        ) {
            if (balance.balance) {
                this.balance = balance.balance.total
                this.withdrawable = balance.balance.withdrawable
            }

            if (balance.bonus) {
                this.bonus_balance = balance.bonus.balance
            }

            this.bets_open = balance.bets.open || 0
            this.next_race = balance.bets.next || null
            this.free_bets = balance.freebets?.length || 0

            useBonuses().onSetFreebetsFromBalance(balance)
        },
        onSetUserSettings(settings: OBR.Settings.Payload) {
            this.locale = settings.language
            this.hide_dogs = settings.hide_dogs
            this.timezone = settings.timezone
            this.open_betslip = settings.open_betslip
            this.accept_odds = settings.accept_odds as AcceptBetsChanges
            this.odds_format = settings.odds_format
            this.unit_system = settings.unit_system
            this.countries_order = settings.countries_order
            this.betslip_confirmation = settings.betslip_confirmation
            this.preferred_stakes_single = settings.preferred_stakes_single
            this.preferred_stakes_multiple = settings.preferred_stakes_multiple
            this.preferred_stakes_exotic = settings.preferred_stakes_exotic
            this.preferred_stakes_usd = settings.preferred_stakes_usd
        },
        onSetBootstrap(currencySettings: OBR.User.CurrencySettings) {
            this.currency_settings = currencySettings
        },
    },
    getters: {
        getLanguage(state) {
            return mapLocaleToLanguage(state.locale)
        },
        getFullname(state) {
            return `${state.first_name} ${state.last_name}`
        },
    },
})
