/**
 * Target Origin
 */
export const POST_MESSAGE_TARGET_ORIGIN: string = '*'

/**
 * Height Message ID
 */
export const POST_MESSAGE_ID_HEIGHT: string = 'height'

/**
 * Request login Message ID
 */
export const POST_MESSAGE_ID_REQUEST_LOGIN: string = 'requestLogin'

/**
 * Request login Message ID
 */
export const POST_MESSAGE_ID_REQUEST_REGISTRATION: string =
    'requestRegistration'

/**
 * Navigation Message ID
 * Used to comunicate between million and racebets or OBG
 */
export const POST_MESSAGE_ID_NAVIGATION: string = 'navigation'

/**
 * Navigation hash Message ID
 * Used to comunicate between b2b racing app and OBG
 */
export const POST_MESSAGE_ID_NAVIGATION_HASH: string = 'navigationHash'

/**
 * Popup Message ID
 * Used to open popup for OBG
 */
export const POST_MESSAGE_ID_POPUP: string = 'popup'

/**
 * Navigation to race card Message ID
 * Used to comunicate only between million and racebets legacy
 */
export const POST_MESSAGE_ID_GO_TO_RACE_CARD: string = 'goToRaceCard'

/**
 * Sizes Message ID
 */
export const POST_MESSAGE_ID_SIZES: string = 'sizes'

/**
 * Scroll to Position Message ID
 */
export const POST_MESSAGE_ID_SCROLL_TO_POSITION: string = 'scrollToPosition'

/**
 * Ready Message ID
 */
export const POST_MESSAGE_ID_READY: string = 'ready'

/**
 * Request sportsbook Message ID
 */
export const POST_MESSAGE_ID_REQUEST_SPORTSBOOK: string = 'requestSportsbook'

/**
 * Request deposit Message ID
 */
export const POST_MESSAGE_ID_SHOW_DEPOSIT: string = 'showDeposit'

/**
 * Open link by parent window Message ID
 */
export const POST_MESSAGE_ID_OPEN_LINK: string = 'open'

/**
 * Bet placed successfully Message ID
 */
export const POST_MESSAGE_ID_BET_SUCCESS: string = 'betSuccess'

/**
 * Scroll Message ID
 */
export const POST_MESSAGE_ID_SCROLL: string = 'scroll'

/**
 * Reload Message ID
 */
export const POST_MESSAGE_ID_RELOAD: string = 'reload'

/**
 * List of parts of urls that are allowed to comunicate with us by postMessages
 */
export const POST_MESSAGE_FILTER_LIST: string[] = [
    '.racebets.',
    '.betsafe.',
    '.betsson.',
    '.nordicbet.',
    '.racebetsint.',
    '.arcticbet.',
    '.betsmith.',
    '.betsolid.',
    '.europebet.',
    '.mobilbahis.',
]
