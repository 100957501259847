import { ref } from 'vue'

const isVisible = ref(false)

function closeDialog() {
    isVisible.value = false
}

function openDialog() {
    isVisible.value = true
}

export default function () {
    return {
        isVisible,
        closeDialog: closeDialog,
        openDialog: openDialog,
        onLoginComplete: closeDialog,
    }
}
