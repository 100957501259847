/**
 * Form tabs type
 */
export enum FormguideTabsType {
    OVERVIEW = 'overview',
    FORM = 'form',
    STATISTICS = 'statistics',
    DUELS = 'duels',
    ANTE_POST = 'antepost',
    // PEDIGREE = 'pedigree',
    NOTES = 'notes',
}
