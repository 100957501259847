/**
 * Debounce event - it limits the rate at which passed function gets invoked.
 */

let timer: null | OBR.Common.Timeout = null

export function debounce(callback: () => void, timeout: number = 500) {
    if (timer) {
        clearTimeout(timer)
    }

    timer = setTimeout(() => {
        callback()
    }, timeout)
}
