/**
 * Localised short date format
 * Example: 05/29/1453
 */
export const FD_LOCALIZED_DATE_SHORT: string = 'P'

/**
 * Localised short date format with dots
 * Example: 05.29.1453
 */
export const FD_LOCALIZED_DATE_SHORT_WITH_DOTS: string = 'dd.MM.yyyy'

/**
 * Localised medium date format
 * Example: Apr 29, 1453
 */
export const FD_LOCALIZED_DATE_MEDIUM: string = 'PP'

/**
 * Localised long date format
 * Example: April 29th, 1453
 */
export const FD_LOCALIZED_DATE_LONG: string = 'PPP'

/**
 * Localised long with day name date format
 * Example: Friday, April 29th, 1453
 */
export const FD_LOCALIZED_DATE_LONG_WITH_DAY_NAME: string = 'PPPP'

/**
 * Localised short time format
 * Example: 12:00 AM
 */
export const FD_LOCALIZED_TIME_SHORT: string = 'p'

/**
 * Localised short time format
 * Example: 12:00:00 AM
 */
export const FD_LOCALIZED_TIME_LONG: string = 'pp'

/**
 * Day name 2 letters abbreviation format
 * Example: Mo, Tu, We, Th, Fr, Su, Sa
 */
export const FD_DAY_OF_WEEK_TWO_LETTERS = 'EEEEEE'

/**
 * Day of week long format
 * Example: Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday
 */
export const FD_DAY_OF_WEEK_LONG = 'EEEE'

/**
 * Hours and minutes time format
 * Example: 22:25 or 01:04
 */
export const FD_HOURS_AND_MINUTES = 'HH:mm'

/**
 * Days and months format
 * Example: 06/02
 */
export const FD_DAYS_AND_MONTHS = 'dd/MM'

/**
 * Month name 3 letter abbreviation format
 * Example: Jan
 */
export const FD_MONTH_NAME_THREE_LETTERS = 'MMM'

/**
 * Day number and Month name 3 letter abbreviation format
 * Example: 20 Jan
 */
export const FD_DAY_NUMBER_AND_MONTH_THREE_LETTERS = 'd MMM'

/**
 * Localised date format with weekday
 * Example: Mo, May 29th, 1453
 */
export const FD_DAY_NAME_TWO_LETTER_AND_LOCALIZED_DATE_LONG: string =
    'cccccc, PPP'

/**
 * Localised only yeat
 * Example: 1453
 */
export const FD_LOCALIZED_YEAR: string = 'yyyy'
