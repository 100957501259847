import { createRouter, Router as VueRouter } from 'vue-router'

import {
    createOptions,
    getMode,
    setupRoutes,
} from '@obr-core/helpers/router.helpers'
import { Product, ENV_PRODUCT } from '@obr-core/config/env'
import { RouterMode, ROUTER_BASE_PATH } from '@obr-core/config/router'
import { isWebComponent } from '@obr-core/helpers/app.helpers'

class Router {
    private static instance: Router = new Router()
    private routesConfig: OBR.Router.RouteRecordRaw[] = []
    private mode: RouterMode = getMode(Product.MILLION_GAME === ENV_PRODUCT)
    private base: string = isWebComponent() ? '/' : ROUTER_BASE_PATH
    private router: VueRouter | null = null

    constructor() {
        if (Router.instance) {
            throw new Error(
                'Instantiation failed: Use `getInstance()` instead of `new`.'
            )
        }
        Router.instance = this
    }

    public static getInstance(): Router {
        return Router.instance
    }

    public createRouter(
        appRoutes: OBR.Router.RouteRecordRaw[],
        brandRoutes: OBR.Router.RouteRecordRaw[] = []
    ) {
        if (this.router) {
            throw new Error(
                'Create router failed: VueRouter already Instantiated'
            )
        }
        this.routesConfig = setupRoutes(...appRoutes, ...brandRoutes)

        return (this.router = createRouter(
            createOptions(this.mode, this.routesConfig, this.base)
        ))
    }

    public getRouter() {
        return this.router
    }
}

export const router = Router.getInstance()
