import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'

export class AuthResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/users/me`

    /**
     * API request login
     */
    public async login({
        username,
        password,
        tmSessionId,
        autoLogin = false,
        acceptTerms = false,
    }: OBR.User.LoginUserInput): Promise<OBR.User.LoginResponse> {
        try {
            const response = await http.post<OBR.User.LoginResponse>(
                `${this.apiPath}/login`,
                {
                    username: username,
                    password: password,
                    tm_session_id: tmSessionId,
                    auto_login: autoLogin,
                    accept_terms: acceptTerms,
                }
            )

            return response.data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * API request logout
     */
    public async logout() {
        try {
            const url = `${this.apiPath}/logout`
            const response = await http.post<OBR.User.LogoutResponse>(url)

            return response.data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }
}
