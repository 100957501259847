import {
    ROUTE_HOME,
    ROUTE_CALENDAR,
    ROUTE_EVENT_CARD,
    ROUTE_EVENT_CARD_PICKBETS_DETAILS,
    ROUTE_NEXT_H2H,
    ROUTE_HIGHLIGHTS,
    ROUTE_JACKPOTS,
    ROUTE_MARKET_MOVERS,
    ROUTE_MY_BETS,
    ROUTE_RACE_CARD,
    ROUTE_HORSES_ABROAD,
    ROUTE_BEST_BACKED,
    ROUTE_MY_BETS_RECENT,
    ROUTE_MY_BETS_OPEN,
    ROUTE_MY_BETS_SETTLED,
    ROUTE_MY_BETS_ANTE_POST,
    ROUTE_MY_BETS_ADVANCED,
    ROUTE_SETTINGS,
    ROUTE_SPECIALS,
    ROUTE_SPECIAL,
    QUERY_EVENT_CARD_TABS,
    ROUTE_FAVOURITES,
    ROUTE_BONUSES,
    ROUTE_LOGIN,
    QUERY_EVENT_CARD_TAB_KEY,
    QUERY_PICKBETS_CARD,
    ROUTE_BONUSES_FREEBETS,
    ROUTE_TOP_JOCKEYS,
    ROUTE_TOP_TRAINERS,
    ROUTE_SEARCH,
    ROUTE_TIPS,
    ROUTE_TODAYS_RUNNERS,
} from '@obr-core/config/route'
import { ENV_BRAND } from '@obr-core/config/env'
import { Brand } from '@obr-core/config/env'
import { getEventCardExtendedLink } from './link-extended.helpers'

/**
 * Get Home router link
 */
export const homeLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_HOME,
}

/**
 * Get Event Card router link only used in case if event data is missing
 *
 * i.e. if event data is available we should use "getEventCardExtendedLink" and in "my bets" page
 */
export function getEventCardLink(
    eventID: string,
    query?: OBR.Router.LocationQueryRaw
): OBR.Router.RouteLocationRaw | null {
    return {
        name: ROUTE_EVENT_CARD,
        params: { eventID },
        query,
    }
}

/**
 * Get Event Card H2H page link
 */
export function getEventCardH2hLink(
    payload: OBR.Events.ExtendedLinkPayload
): OBR.Router.RouteLocationRaw {
    return getEventCardExtendedLink(payload, {
        [QUERY_EVENT_CARD_TAB_KEY]: QUERY_EVENT_CARD_TABS.H2H,
    })
}

/**
 * Get Event Card Specials page link
 */
export function getEventCardSpecialsLink(
    event: OBR.Events.CalendarEvent | OBR.Events.Event
): OBR.Router.RouteLocationRaw | null {
    if (!event.id_event_specials) {
        return null
    }

    return getEventCardExtendedLink(event, {
        [QUERY_EVENT_CARD_TAB_KEY]: QUERY_EVENT_CARD_TABS.SPECIALS,
    })
}

/**
 * Get Event Card Pickbets page link
 */
export function getEventCardPickbetsLink(
    event: OBR.Events.ExtendedLinkPayload
): OBR.Router.RouteLocationRaw | null {
    if (!event.has_pick_bets) {
        return null
    }

    return getEventCardExtendedLink(event, {
        [QUERY_EVENT_CARD_TAB_KEY]: QUERY_EVENT_CARD_TABS.PICKBETS,
    })
}

/**
 * Get Special Event standalone router link
 */
export function getSpecialStandaloneLink(
    eventId: string
): OBR.Router.RouteLocationRaw {
    return {
        name: ROUTE_SPECIAL,
        params: {
            eventId: eventId,
        },
    }
}

/**
 * Get Race Card router link
 */
export function getRaceCardLink(
    raceID: string | number,
    queryString?: OBR.Router.LocationQueryRaw
): OBR.Router.RouteLocationRaw {
    return { name: ROUTE_RACE_CARD, params: { raceID }, query: queryString }
}

/**
 * Check if link to race card is available
 *
 * i.e. for The Million to access race card from anwser question or history
 */
export function displayLinkToRaceCard(raceId: string | null): boolean {
    return !!raceId && ENV_BRAND === Brand.RACEBETS
}

/**
 * Get Calendar router link
 *
 * @param date format 'YYYY-MM-DD' ISO 8601
 */
export function getCalendarLink(date?: string): OBR.Router.RouteLocationRaw {
    return {
        name: ROUTE_CALENDAR,
        params: { date },
    }
}

/**
 * Get PickBets detail
 */
export function getPickbetsDetail(
    pickbetsID: string,
    eventID: string,
    legID: string
): OBR.Router.RouteLocationRaw {
    return {
        name: ROUTE_EVENT_CARD_PICKBETS_DETAILS,
        params: { pickbetsID, eventID },
        query: { [QUERY_PICKBETS_CARD.LEG]: legID },
    }
}

/**
 * Get H2H today router link
 */
export const h2hTodayLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_NEXT_H2H,
}

/**
 * Get Highlights router link
 */
export const highlightsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_HIGHLIGHTS,
}

/**
 * Get Jackpots router link
 */
export const jackpotsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_JACKPOTS,
}

/**
 * Get My Bets router link
 */
export const myBetsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS,
}

/**
 * Get My Bets Recent router link
 */
export const myBetsRecentLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS_RECENT,
}

/**
 * Get My Bets Open router link
 */
export const myBetsOpenLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS_OPEN,
}

/**
 * Get My Bets Settled router link
 */
export const myBetsSettledLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS_SETTLED,
}

/**
 * Get My Bets Ante Post router link
 */
export const myBetsAntePostLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS_ANTE_POST,
}

/**
 * Get My Bets Advanced router link
 */
export const myBetsAdvancedLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MY_BETS_ADVANCED,
}

/**
 * Get Horses Abroad page router link
 */
export const horsesAbroadLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_HORSES_ABROAD,
}

/**
 * Get Best Backed page router link
 */
export const bestBackedLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_BEST_BACKED,
}

/**
 * Get Market Movers page router link
 */
export const marketMoversLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_MARKET_MOVERS,
}

/**
 * Get Settings page router link
 */
export const settingsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_SETTINGS,
}

/**
 * Get special page router link
 */
export const specialsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_SPECIALS,
}

/**
 * Get favourites page router link
 */
export const favouritesLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_FAVOURITES,
}

/**
 * Get bonuses page router link
 */
export const bonusesLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_BONUSES,
}

/**
 * Get free bets page router link
 */
export const freebetsLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_BONUSES_FREEBETS,
}

/**
 * Get login page router link
 */
export const loginLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_LOGIN,
}

/**
 * Get top trainers router link
 */
export const topTrainersLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_TOP_TRAINERS,
}

/**
 * Get top jockeys router link
 */
export const topJockeysLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_TOP_JOCKEYS,
}

/**
 * Get search router link
 */
export const searchLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_SEARCH,
}

/**
 * Get today's tips router link
 */
export const todaysTips: OBR.Router.RouteLocationRaw = {
    name: ROUTE_TIPS,
}

/**
 * Get today's runners page router link
 */
export const todaysRunnersLink: OBR.Router.RouteLocationRaw = {
    name: ROUTE_TODAYS_RUNNERS,
}
