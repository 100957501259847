/**
 * Message types
 */
export const enum MessageType {
    COMMON = 'common',
    USER = 'user',
    LOGOUT = 'logout',
    REALITY_CHECK = 'realityCheck',
}

/**
 * Channel Types
 */
export const enum RoomType {
    SIDEBAR_ANTEPOST = 'node_sidebarAntepost', //legacy
    SIDEBAR_EVENTS = 'node_sidebarEvents', //legacy
    EVENT_BROWSER_ANTEPOST = 'eventBrowser_antepost',
    EVENT_BROWSER = 'eventBrowser',
    USER_PRIVATE = 'user_private_',
    RUNNERS_LIST = 'node_runner_',
    VERSION_UPDATE = 'versionUpdate',
    DATA_UPDATE = 'dataUpdate',
    RACE_CARD = 'node_raceCard',
    EVENT_CARD = 'node_eventCard',
}

/**
 * Njs Query types
 */
export const enum QueryType {
    GET_USER_STATUS = 'getUserStatus',
    GET_LOGGED_IN_USERS_LIST = 'getLoggedUsersList',
}

/**
 * The different socket events
 */
export const enum SocketEventType {
    ON_CONNECTION = 'connection',
    ON_EXTERNAL_UPDATE = 'externalUpdate',
    ON_VERSION_UPDATE = 'versionUpdate',
    ON_JOIN = 'join',
    ON_LOGIN = 'login',
    ON_LEAVE = 'leave',
    ON_DISCONNECT = 'disconnect',
}

/**
 * Query Response Type
 */
export const enum QueryResponseType {
    USER_OFFLINE = 'offline',
    USER_ONLINE = 'online',
    INVALID_REQUEST = 'invalid request',
    UNKNOWN_ACTION = 'unknown action',
    ACCESS_DENIED = 'access denied',
}

/**
 * Redis streams
 */
export const enum RedisStreamType {
    PUSH_SERVER_STREAM = 'push_server:njs2',
}

/**
 *
 */
export const enum RedisKeys {
    PS_LAST_MSG_ID = 'ps:last_msg_id',
}
