import { SettingsResource } from '@obr-core/resources/SettingsResource'

/**
 * Settings Service
 * Singleton
 */
export class SettingsService {
    private static instance: SettingsService
    private settingsResource: SettingsResource

    private constructor() {
        this.settingsResource = new SettingsResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): SettingsService {
        if (SettingsService.instance === undefined) {
            SettingsService.instance = new SettingsService()
        }
        return SettingsService.instance
    }

    /**
     * Save user settings
     */
    public async saveSettings(payload: OBR.Settings.Payload): Promise<any> {
        return await this.settingsResource.saveSettings(payload)
    }
}
