import { useBonuses } from '@obr-core/store/bonuses'
import { BetType, BetCategory } from '@obr-core/config/betting'
import { betslipStoreService } from '@obr-core/services/store'
import { FixedOddsStatus, RaceStatus } from '@obr-core/config/race'
import { getBetRaceId } from '@obr-core/helpers/betslip.helpers'

class BonusStoreService {
    isLoadingOverview(): boolean {
        return useBonuses().loadingOverview
    }
    isLoadedOverview(): boolean {
        return useBonuses().loadedOverview
    }
    freebets(): OBR.Bonuses.Freebet[] {
        return useBonuses().freebets
    }
    availableFreebets(
        race: OBR.Race.Race,
        programNumber?: number,
        idFreebet?: string | null
    ): OBR.Bonuses.Freebet[] {
        const racesWithFreebets = useBonuses().racesWithFreebets
        return bonusStoreService.freebets().filter((freebet) => {
            if (race.status !== RaceStatus.OPEN) {
                return false
            }

            if (
                freebet.countries?.length &&
                !freebet.countries.includes(race.country)
            ) {
                return false
            }

            if (freebet.antepost !== race.is_ante_post) {
                return false
            }

            if (freebet.id_race && `${freebet.id_race}` !== race.id) {
                return false
            }

            if (freebet.id_event && `${freebet.id_event}` !== race.id_event) {
                return false
            }

            if (
                freebet.program_number &&
                freebet.program_number !== programNumber
            ) {
                return false
            }

            const winBetType = race.bet_types.find((betType) => {
                return betType.bet_type === BetType.WIN
            })

            if (!winBetType) {
                return false
            }

            if (
                winBetType.categories.length === 1 &&
                winBetType.categories.includes(BetCategory.TOTE)
            ) {
                return false
            }

            if (
                winBetType.categories.includes(BetCategory.FIXED) &&
                winBetType.categories.includes(BetCategory.TOTE) &&
                !winBetType.categories.includes(BetCategory.BOOKIE)
            ) {
                return race.fixed_odds_status === FixedOddsStatus.ON
            }

            if (
                race.id &&
                racesWithFreebets.includes(+race.id) &&
                !freebet.multi_betting
            ) {
                return false
            }
            // Show free bets that are already in betslip only for bet with this freebet
            if (idFreebet) {
                return true
            }

            const betOnThisRaceWithFreebets = betslipStoreService
                .bets()
                .filter((bet) => {
                    return (
                        bet.market === BetType.WIN &&
                        getBetRaceId(bet) === race.id &&
                        bet.id_freebet
                    )
                })
            // Don't show free bets that are already in betslip
            if (betOnThisRaceWithFreebets.length && !freebet.multi_betting) {
                return false
            }

            // Don't show free bets that are already in betslip
            if (
                betslipStoreService.bets().some((bet) => {
                    return bet.id_freebet === freebet.id_freebet
                })
            ) {
                return false
            }

            return true
        })
    }
    isFreebetsBannerShown(): boolean {
        return useBonuses().isFreebetsBannerShown
    }
    pendingBonuses(): OBR.Bonuses.PendingBonus[] {
        return useBonuses().pending_bonuses
    }
    histories(): OBR.Bonuses.History[] {
        return useBonuses().histories
    }
    currentBonus(): {
        bonuses: OBR.Bonuses.Bonuses[]
        chunk: OBR.Bonuses.BonusChunk | null
    } {
        return useBonuses().current_bonus
    }

    /* Actions */
    loadOverview(): Promise<OBR.Bonuses.BonusesOverview | null> {
        return useBonuses().onLoadBonusOverview()
    }
    loadDetails(id: number): Promise<OBR.Bonuses.BonusDetails[]> {
        return useBonuses().onLoadBonusDetails(id)
    }
    doClaimBonus(id: string): Promise<OBR.Bonuses.ClaimBonusPayload> {
        return useBonuses().onDoClaimBonus(id)
    }
    doRedeem(bonus_ids: number[]): Promise<OBR.Bonuses.BonusRedeemPayload> {
        return useBonuses().onDoRedeemBonus(bonus_ids)
    }
    hideFreebetsBanner() {
        useBonuses().onHideFreebetsBanner()
    }
    resetBonuses() {
        useBonuses().onResetBonuses()
    }
}

export const bonusStoreService = new BonusStoreService()
