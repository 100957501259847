/**
 * Router Modes Enum
 */
export enum RouterMode {
    Abstract = 'abstract',
    Hash = 'hash',
    History = 'history',
}

/**
 * Router is silent, routing is not reflected in URL
 */
export const ROUTER_MODE_SILENT = RouterMode.Abstract

/**
 * Default routing with History API
 */
export const ROUTER_MODE_DEFAULT = RouterMode.History

/**
 * Name of global variable that is used to pass path between router and store watcher
 */
export const ROUTER_CURRENT_PATH_KEY: string = 'current_path'

/**
 * Name of global variable that is used to detect time traveling
 */
export const ROUTER_TIME_TRAVELING_KEY: string = 'is_time_traveling'

/**
 * Original key name form the router changed to avoid camel case pattern
 */
export const ROUTER_FULL_PATH_KEY = 'fullPath'

/**
 * Current localised routes
 */
export const ROUTER_LOCALISED_ROUTES: string = import.meta.env
    .VUE_APP_LOCALISED_ROUTES

/**
 * Current base path of the app
 */
export const ROUTER_BASE_PATH = import.meta.env.VUE_APP_BASE_PATH

/**
 * Event location (country) for virtuals in extended link format
 */
export const ROUTER_EVENT_LOCATION_VIRTUALS = 'virtuals'
