/**
 * RaceBets environments
 */
export const enum RBEnv {
    LOCAL_DEVELOP = 'local-develop',
    DEVELOP = 'develop',
    PRE_PROD = 'pre-prod',
    PRODUCTION = 'production',
}

/**
 * Brands
 */
export const enum Brand {
    BETSAFE = 'betsafe',
    MOBILBAHIS = 'mobilbahis',
    BETS10 = 'bets10',
    BETSSON = 'betsson',
    NORDICBET = 'nordicbet',
    RACEBETS = 'racebets',
    B10 = 'b10',
    EUROPEBET = 'europebet',
    SUAPOSTA = 'suaposta',
    BETSSONGR = 'betssongr',
    BETSSONARBACITY = 'betssonarbacity',
    BETSSONARBAPROVINCE = 'betssonarbaprovince',
    BETSMITH = 'betsmith',
    ARCTICBET = 'arcticbet',
    BETSOLID = 'betsolid',
    BETSSONPE = 'betssonpe',
    BETSAFEPE = 'betsafepe',
    BETSSONDK = 'betssondk',
    NORDICBETDK = 'nordicbetdk',
}

export const enum BrandAlias {
    BETSSON_PE = 'betssonpe',
    BETSSON_CL = 'betssoncl',
}
/**
 * Build clients
 */
export const enum BuildClient {
    WC = 'wc',
    STANDALONE = 'standalone',
    IFRAME = 'iframe',
    THIRD_PARTY = 'thirdparty',
}

/**
 * Build Target
 */
export const enum BuildTarget {
    WC = 'wc',
    APP = 'app',
}

/**
 * Product: horse racing or million game
 */
export const enum Product {
    HORSE_RACING = 'horse-racing',
    MILLION_GAME = 'million-game',
}

// TODO: env variables to be implemented
export interface BaseProcessEnv {
    OBR_RB_ENV: string
    OBR_PRODUCTION: string
    OBR_DEBUG_MODE: string
    OBR_BASE_PATH: string
    OBR_BRAND: string
    OBR_TARGET: string
    OBR_CLIENT: string
    OBR_SENTRY_DSN: string
    OBR_RELEASE_VERSION: string
    OBR_AJAX_HOST: string
    OBR_WEBSOCKETS_ENABLED: string
    OBR_WEBSOCKETS_HOST: string
    OBR_WEBSOCKETS_PORT: string
    OBR_GDPR_ENABLED: string
    OBR_CURRENCY_SETTINGS: string
    OBR_LOCALISED_ROUTES: string
    OBR_THREAT_METRIX_ENABLED: string
    OBR_THREAT_METRIX_TAG_URL: string
    OBR_THREAT_METRIX_ORG_ID: string
    OBR_LANGUAGES: string
    OBR_WEBCOMPONENT_TAG_NAME: string
}

export interface UIProcessEnv extends BaseProcessEnv {}

export interface AppProcessEnv extends BaseProcessEnv {
    OBR_JOIN_BALANCE_CHANNEL: string
    OBR_FORCE_CLIENT_RESOLVERS: string
    OBR_BRAND_SETTINGS: string
}

export interface MillionProcessEnv extends BaseProcessEnv {
    OBR_JOIN_BALANCE_CHANNEL: string
}

/**
 * Build NodeJS.ProcessEnv object with whitelisted .env variables.
 * Strigify objects values.
 */
export function mapSettings(
    processEnv: NodeJS.ProcessEnv,
    prefix: string,
    settings: { [key: string]: any }
): NodeJS.ProcessEnv {
    const pickedSettings: { [key: string]: string } = {}

    for (const key in processEnv) {
        const keyWithoutPrefix = key.replace(prefix, '')
        const settingValue = settings[keyWithoutPrefix]

        if (
            key.startsWith(prefix) &&
            Object.prototype.hasOwnProperty.call(settings, keyWithoutPrefix)
        ) {
            let value = `${settingValue}`

            if (typeof settingValue === 'object') {
                value = JSON.stringify(settingValue)
            }

            pickedSettings[key] = value
        }
    }

    return pickedSettings as NodeJS.ProcessEnv
}

/**
 * OBR global environment variables for build and runtime access
 */
export function setEnv({
    client,
    target,
    brand,
    childBrand,
    buildBrandName,
    env,
    webcomponentTagName,
}: {
    client?: BuildClient
    target?: BuildTarget
    brand: string
    childBrand?: string
    buildBrandName: string
    env: RBEnv
    webcomponentTagName?: string
}) {
    env = `${env}`.toLowerCase() as RBEnv

    return {
        ENV: RBEnv.PRODUCTION === env ? env : 'development',
        NODE_ENV: RBEnv.PRODUCTION === env ? env : 'development',
        RB_ENV: env,
        DEBUG_MODE: RBEnv.PRODUCTION !== brand,
        PRODUCTION: RBEnv.PRODUCTION === brand,
        BRAND: brand.toLowerCase(),
        CHILD_BRAND: childBrand?.toLowerCase(),
        BUILD_BRAND_NAME: buildBrandName.toLowerCase(),
        CLIENT: client,
        TARGET: target,
        WEBCOMPONENT_TAG_NAME: webcomponentTagName,
    }
}
