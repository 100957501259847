const url = new URL(import.meta.url)

/**
 * ORIGIN - used to point to correct origin in case of webcomponent
 */
export const ORIGIN: string = url.origin

/**
 * XSRF cookie
 */
export const HTTP_XSRF_COOKIE: string = 'XSRF-TOKEN'

/**
 * XSRF request header
 */
export const HTTP_XSRF_HEADER: string = 'X-XSRF-TOKEN'

/**
 * REST endpoint ajax
 */
export const HTTP_AJAX: string = `${ORIGIN}/ajax`

/**
 * REST endpoint v2
 */
export const HTTP_REST_V2: string = `${ORIGIN}/rest/v2`
/**
 *
 */
export const HTTP_AUTH_RESPONSE_STATUS_CODES: number[] = [401]
