import { appStoreService } from '@obr-core/services/store'

export function exchange(
    amount: number,
    baseCurrency: OBR.User.Currency,
    targetCurrency: OBR.User.Currency,
    exchangeRates: OBR.Settings.ExchangeRates = appStoreService.exchangeRates(),
    addOnePercent = false
) {
    if (!baseCurrency || !targetCurrency) {
        throw new Error('currency not defined')
    }
    if (baseCurrency === targetCurrency) {
        return amount
    }

    if (!addOnePercent) {
        return (
            Math.round(
                (amount / exchangeRates[targetCurrency][baseCurrency]) * 100
            ) / 100
        )
    }
    // ... and return result with 1% more expensive exchange rate
    return (
        Math.round(
            (amount / (exchangeRates[targetCurrency][baseCurrency] * 0.99)) *
                100
        ) / 100
    )
}
