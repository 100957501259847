import { useI18n } from '@obr-core/store/i18n'

class I18nStoreService {
    /* Actions */
    loadModules(payload: { modules: OBR.I18n.Module[] }) {
        return useI18n().loadModules(payload)
    }
    getCounties() {
        return useI18n().countries
    }
}

export const i18nStoreService = new I18nStoreService()
