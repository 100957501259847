import { i18n } from '@obr-core/i18n/i18n'
import { emitter } from '@obr-core/services/EmitterService'
import {
    EMITTER_STANDARD_MODAL,
    EMITTER_CONFIRMATION_MODAL,
    EMITTER_CLOSE_CONFIRMATION_MODAL,
} from '@obr-core/config/emitter'
import { ModalSimpleStatus } from '@obr-core/config/modal'

/**
 * Modal Service
 * Singleton
 */
export class ModalService {
    private static instance: ModalService

    /**
     * Return class instance
     */
    public static getInstance(): ModalService {
        if (ModalService.instance === undefined) {
            ModalService.instance = new ModalService()
        }
        return ModalService.instance
    }

    /**
     * Show confirmation modal to the user, it can be closed
     */
    public confirmationModal(
        payload: OBR.UI.Emitters.ConfirmationModalPayload
    ) {
        emitter.emit(EMITTER_CONFIRMATION_MODAL, payload)
    }

    /**
     * Close confirmation modal
     */
    public closeConfirmationModal() {
        emitter.emit(EMITTER_CLOSE_CONFIRMATION_MODAL)
    }

    /**
     * Show error modal to the user, it can be closed
     *
     * e.g. should be shown only if it's relevant to user
     */
    public displayStandardModal(
        message?: string,
        title?: string,
        status?: OBR.Modal.ModalSimpleStatus,
        onClose?: () => void,
        onClick?: () => void
    ) {
        // default status is ERROR
        if (!status) {
            status = ModalSimpleStatus.ERROR
        }

        if (!message) {
            message = i18n.global.t('generic.h_generic_error')
        }

        if (!title) {
            // if title is not defined, use generic title for this popup type (status)
            if (status === ModalSimpleStatus.WARNING) {
                title = i18n.global.t('generic.h_attention')
            } else {
                title = i18n.global.t('generic.error_occurred')
            }
        }

        emitter.emit(EMITTER_STANDARD_MODAL, {
            message,
            title,
            status,
            onClose,
            onClick,
        })
    }

    /**
     * Handle confirmation modal event
     *
     * e.g. used in "ModalConfirmationHandler" to display relevant messages to user
     */
    public onConfirmationModal(
        handler: (payload: OBR.UI.Emitters.ConfirmationModalPayload) => void
    ) {
        emitter.on(EMITTER_CONFIRMATION_MODAL, handler)
    }

    /**
     * Handle on close confirmation modal event
     *
     * e.g. used in "ModalConfirmationHandler" to hide modal
     */
    public onCloseConfirmationModal(handler: () => void) {
        emitter.on(EMITTER_CLOSE_CONFIRMATION_MODAL, handler)
    }

    /**
     * Handle show standard modal event, accepts handler function
     *
     * e.g. used in "ModalStandardHandler" to display relevant errors to user
     */
    public onStandardModal(
        handler: (payload: OBR.UI.Emitters.ShowStandardModalPayload) => void
    ) {
        emitter.on(EMITTER_STANDARD_MODAL, handler)
    }
}
