import { RoomType } from '@obr-core/config/push-server'
import { parseRunnerFromBetslipSocket } from '@obr-core/helpers/race.helpers'
import { raceStoreService } from '@obr-core/services/store/RaceStoreService'
import { WebSocketsService } from '@obr-core/services/websockets/WebSocketsService'

/**
 * Runner Web Sockets Service
 *
 * Singleton
 */
export class RunnerWebSocketsService {
    private static instance: RunnerWebSocketsService
    private webSocketService: WebSocketsService

    // channels
    private betslipRunnersChannel: OBR.Common.Object<number> = {}

    private constructor() {
        this.webSocketService = WebSocketsService.getInstance()
    }

    /**
     * Return class instance
     */
    public static getInstance(): RunnerWebSocketsService {
        if (RunnerWebSocketsService.instance === undefined) {
            RunnerWebSocketsService.instance = new RunnerWebSocketsService()
        }

        return RunnerWebSocketsService.instance
    }

    /**
     * Connects to the given runner channel
     *
     * e.g. used when adding runner in betslip
     */
    public joinBetslipRunnerChannel(id: string, timestamp: number): void {
        const channelName = `${RoomType.RUNNERS_LIST}${id}`

        if (this.betslipRunnersChannel[id]) {
            this.betslipRunnersChannel[id] += 1
            return
        }

        this.webSocketService.join({
            channel: channelName,
            timestamp: timestamp,
            idEvent: id,
        })

        this.betslipRunnersChannel[id] = 1

        // add listener
        this.webSocketService.addChannelEventListener(
            'update',
            channelName,
            (payload) => this.parseRunnerInStore(id, payload)
        )
    }

    /**
     * Leave Runner Channel
     *
     * e.g. used when removing runner from betslip
     */
    public leaveBetslipRunnerChannel(id: string) {
        const channelName = `${RoomType.RUNNERS_LIST}${id}`

        if (this.betslipRunnersChannel[id] === 1) {
            this.webSocketService.leaveChannel(channelName)

            delete this.betslipRunnersChannel[id]
        } else if (this.betslipRunnersChannel[id] > 1) {
            this.betslipRunnersChannel[id] -= 1
        }
    }

    /**
     * Leave all Runners Channel
     */
    public leaveBetslipRunnersChannel() {
        for (const id in this.betslipRunnersChannel) {
            this.leaveBetslipRunnerChannel(id)
        }
    }

    /**
     * Parse Race and update Store
     */
    private parseRunnerInStore(id: string, payload?: OBR.Common.Object<any>) {
        // load runner in cache
        const idRace = payload?.data?.race?.idRace

        raceStoreService.updateRaceCardBySocket(idRace, payload?.data)

        payload?.data?.runners?.forEach(
            (runner: OBR.Betting.BetslipSocketRunner) => {
                raceStoreService.addRunnerToStore(
                    parseRunnerFromBetslipSocket(
                        runner,
                        raceStoreService.runnerByCache(`${runner.idRunner}`)
                    ),
                    `${idRace}`
                )
            }
        )
    }
}
