// import { STORAGE_EXPIRE_MAX_TIMESTAMP } from '@obr-core/config/storage'
// import { getKeyWithPrefix } from '@obr-core/helpers/storage.helpers'

import { getKeyWithPrefix } from '../helpers/storage.helpers'

/**
 * Set an item to the local storage
 * Converts JavaSript value to a JSON string
 * @param key Storage Key
 * @param data Data
 * @param expires Unix timestamp of expiry date
 */
export function setItem(
    key: OBR.Storage.Key,
    value: any,
    expires: number | null = null
): void {
    const keyWithPrefix = getKeyWithPrefix(key)
    const data: OBR.Storage.Data = {
        expires: expires,
        key: key,
        value: value,
    }

    try {
        const item = JSON.stringify(data)
        localStorage.setItem(keyWithPrefix, item)
    } catch (err) {
        // no-op
    }
}

/**
 * Remove the instance of the key from the local storage
 * @param key Storage Key
 */
export function removeItem(key: OBR.Storage.Key): null {
    const keyWithPrefix = getKeyWithPrefix(key)

    try {
        localStorage.removeItem(keyWithPrefix)
    } catch (e) {
        // no-op
    }

    return null
}

/**
 * Get an item from the local storage
 * Converts JSON string to a JavaSript value
 * @param key Storage Key
 */
export function getItem(key: OBR.Storage.Key): any | null {
    const keyWithPrefix = getKeyWithPrefix(key)
    let data: any = null

    try {
        const item = localStorage.getItem(keyWithPrefix)

        if (item !== null) {
            data = JSON.parse(item)
        }
    } catch (err) {
        return null
    }

    if (data) {
        if (
            data.expires === null ||
            (data.expires && new Date(data.expires) > new Date())
        ) {
            return data.value
        }
        return removeItem(key)
    }

    return null
}
