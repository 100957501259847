import { DT_ONE_DAY } from '../config/date-time'

/**
 * Set cookie value
 * @param key Cookie name
 * @param value Cookie value
 * @param attributes Cookie attributes
 */
export function set(
    key: OBR.Cookie.Key,
    value: OBR.Cookie.Value,
    attributes?: OBR.Cookie.Attributes
) {
    if (typeof document === 'undefined') {
        return
    }

    attributes = {
        path: '/',
        ...attributes,
    }

    if (typeof attributes.expires === 'number') {
        attributes.expires = new Date(
            new Date().getTime() * 1 + attributes.expires * DT_ONE_DAY
        )
    }

    // We're using "expires" because "max-age" is not supported by IE
    attributes.expires = attributes.expires
        ? attributes.expires.toUTCString()
        : ''

    // Stringify objects and arrays
    try {
        const result = JSON.stringify(value)
        if (/^[{[]/.test(result)) {
            value = result
        }
    } catch (e) {
        // no-op
    }

    key = encodeURIComponent(key)
        .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
        .replace(/[()]/g, escape)

    value = encodeURIComponent(String(value)).replace(
        /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
        decodeURIComponent
    )

    let stringifiedAttributes = ''
    for (const attributeName in attributes) {
        if (!attributes[attributeName]) {
            continue
        }
        stringifiedAttributes += '; ' + attributeName
        if (attributes[attributeName] === true) {
            continue
        }

        // Considers RFC 6265 section 5.2:
        // ...
        // 3.  If the remaining unparsed-attributes contains a %x3B (";")
        //     character:
        // Consume the characters of the unparsed-attributes up to,
        // not including, the first %x3B (";") character.
        // ...
        stringifiedAttributes += '=' + attributes[attributeName].split(';')[0]
    }

    return (document.cookie = key + '=' + value + stringifiedAttributes)
}

/**
 * Get cookie value
 * @param key - Cookie name
 */
export function get(key?: OBR.Cookie.Key) {
    if (typeof document === 'undefined') {
        return
    }

    const acc = {} as OBR.Common.Object<any>
    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    const cookies = document.cookie ? document.cookie.split('; ') : []

    for (let i = 0; i < cookies.length; i++) {
        const parts = cookies[i].split('=')
        let cookie = parts.slice(1).join('=')

        if (cookie.charAt(0) === '"') {
            cookie = cookie.slice(1, -1)
        }

        try {
            const name = decode(parts[0])
            cookie = decode(cookie)

            try {
                cookie = JSON.parse(cookie)
            } catch (e) {
                // no-op
            }

            acc[name] = cookie

            if (key === name) {
                break
            }
        } catch (e) {
            // no-op
        }
    }

    return key ? acc[key] : acc
}

/**
 * Remove a cookie
 * @param key Cookie name
 * @param attributes Cookie attributes
 */
export function remove(
    key: OBR.Cookie.Key,
    attributes?: OBR.Cookie.Attributes
) {
    set(key, '', { ...attributes, expires: -1 })
}

/**
 * Decode a string
 * @param s String to be decoded
 */
function decode(s: string) {
    return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)
}
