import { defineStore } from 'pinia'

import { FormguideTabsType } from 'components/Formguide/config'

import { initialState } from './state'
import { FavouritesService, FormguideService } from '@obr-core/services/api'

const formguideService = FormguideService.getInstance()

type LoadFormguideByTabParams = {
    subjectId: string
    raceId: string
    fields: FormguideTabsType
    noCache?: boolean
}

export const useFormguide = defineStore('obr-store/formguide', {
    state: initialState,
    actions: {
        onInit() {
            useFormguide()
        },
        //FORM GUIDE
        formguideByCache(
            subjectId: string
        ): OBR.Formguide.Formguide | undefined {
            return this.formguideCache[subjectId] as
                | OBR.Formguide.Formguide
                | undefined
        },
        formguideRaceByCache(raceId: string) {
            return this.racesCache[raceId] as OBR.Formguide.FormRace | undefined
        },
        async onFormguideLoadByFields({
            subjectId,
            raceId,
            fields,
        }: LoadFormguideByTabParams) {
            const cachedFormguide = this.formguideByCache(subjectId)

            if (cachedFormguide && cachedFormguide[fields]) {
                return cachedFormguide[fields]
            }

            return await formguideService
                .getBySubject(subjectId, raceId, fields)
                .then((response) => {
                    this.onSetFormguideByFields(response, subjectId, fields)

                    return this.formguideCache[subjectId][fields]
                })
        },
        async formguideLoadRace(subjectId: string, raceId: string) {
            const cachedRace = this.formguideRaceByCache(raceId)

            if (cachedRace) {
                return cachedRace
            }

            return await formguideService
                .getRaceBySubject(subjectId, raceId)
                .then((response) => {
                    this.onSetRaceBySubject(response, raceId)

                    return this.racesCache[raceId]
                })
        },
        async formguideGetDuelOpponents(subjectId: string, searchTerm: string) {
            return await formguideService
                .getDuelOpponents(subjectId, searchTerm)
                .then((response) => {
                    return response
                })
        },
        async formguideGetDuelOpponent(
            subjectId: string,
            opponentSubjectId: string,
            raceId: string | number
        ) {
            return await formguideService
                .getDuelOpponent(subjectId, opponentSubjectId, raceId)
                .then((response) => {
                    return response
                })
        },
        async setFavourite(
            idSubject: string,
            payload: OBR.Formguide.FavouriteDto
        ) {
            const overviewObject: OBR.Formguide.Overview | null =
                this.formguideCache[idSubject]?.overview

            if (overviewObject) {
                // optimistic data, update frontend instantly
                const optimistic: OBR.Formguide.SubjectFavourite = {
                    email_alert: overviewObject.favourite.email_alert,
                    note: overviewObject.favourite.note,
                }
                if (payload.note !== undefined) {
                    optimistic.note = payload.note
                }
                if (payload.email_alert !== undefined) {
                    optimistic.email_alert = payload.email_alert
                }

                this.formguideCache[idSubject].overview.favourite = optimistic
            }

            return FavouritesService.getInstance().setFavourite({
                id_subject: idSubject,
                ...payload,
            })
        },
        onSetFormguideByFields(
            response: OBR.Formguide.Formguide,
            subjectId: string,
            fields: string
        ) {
            // TODO: refactor all code that is not typed properly
            // i.e. to handle set undefined formguide
            if (!response) {
                return
            }

            this.formguideCache[subjectId] = {
                ...this.formguideCache[subjectId],
                [fields]: response,
            }
        },
        onSetRaceBySubject(response: OBR.Formguide.FormRace, raceId: string) {
            if (!response) {
                return
            }

            this.racesCache[raceId] = response
        },
    },
})
