// import { store } from '@obr-core/store/store'
// import { store } from '../store/store'
// import { RaceResource } from '@obr-core/resources/RaceResource'
import { RaceResource } from '@obr-core/resources/RaceResource'
// import { RACE_CARD_UPDATE_SELECTED_MARKET } from '@obr-core/store/modules/raceCard/actions'
// import { RACE_CARD_GET_SELECTED_MARKET } from '@obr-core/store/modules/raceCard/getters'

// import { BetType } from '@obr-core/config/betting'
import { BetType } from '@obr-core/config/betting'

import { errorLogger } from '@obr-core/services/ErrorLogger'

/**
 * Race Service
 * Singleton
 */
export class RaceService {
    private static instance: RaceService
    private racesResource: RaceResource

    private constructor() {
        this.racesResource = new RaceResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): RaceService {
        if (RaceService.instance === undefined) {
            RaceService.instance = new RaceService()
        }
        return RaceService.instance
    }

    /**
     * Method returns race by id
     * @param id - string
     */
    public async getRaceById(id: string): Promise<OBR.Race.Race | null> {
        return this.racesResource.getRaceById(id)
    }

    /**
     * Upcoming races for upcoming races widget
     */
    public getUpcomingRaces() {
        return this.racesResource.getUpcomingRaces()
    }
    /**
     * Jackpots for "Jackpots Races" page
     */
    public getJackpots() {
        return this.racesResource.getJackpots()
    }
    /**
     * Upcoming H2H for upcoming H2H widget
     */
    public getRacesH2HByRaceId(idRace: string) {
        return this.racesResource.getRacesH2HPerRace(idRace)
    }
    /**
     * Upcoming H2H for upcoming H2H widget
     */
    public getRacesH2HUpcoming(openOnly: boolean) {
        try {
            return this.racesResource.getRacesH2HRaces(openOnly)
        } catch (e) {
            throw errorLogger.createException(e)
        }
    }
    /**
     * Best backed for "Best Backed" page
     */
    public getBetsBacked() {
        return this.racesResource.getBestBacked()
    }

    /**
     * Ante-post Market Movers widget|page
     */
    public getMarketMovers() {
        return this.racesResource.getMarketMovers()
    }
}
