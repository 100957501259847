/**
 * Modal Simple Status
 */
export enum ModalSimpleStatus {
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success',
    HIDE = 'hide',
}

export type ModalFatureTourLabels = {
    title: OBR.I18n.TranslateResult
    message: OBR.I18n.TranslateResult
    button: OBR.I18n.TranslateResult
}

export enum ModalBettingFinalType {
    DEFAULT_ERROR = 'default_error',
    RMS_ERROR = 'rms_error',
    SUCCESS = 'success',
}
