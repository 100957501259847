import { getItem, setItem } from '../lib/storage.manager'
import { UserSettings } from '../config/user'

/**
 * Read user setting from local storage
 * @param settingName
 */
export function get(settingName: UserSettings): any {
    return getItem(settingName)
}

/**
 * Write a user setting to local storage
 * @param settingName
 * @param value
 */
export function set(settingName: UserSettings, value: OBR.Common.Object<any>) {
    setItem(settingName, value)
}
