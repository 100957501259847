/**
 * Cookie consent expiry years for visitors
 */
export const COOKIE_CONSENT_EXPIRY_PROSPECT = 2

/**
 * Cookie consent expiry years for customers
 */
export const COOKIE_CONSENT_EXPIRY_CUSTOMER = 5

/**
 * Cookie name/key for 'sockie'
 */
export const COOKIE_KEY_SOCKIE: string = 'sockie'

/**
 * 'sockie' cookie keys
 */
export const COOKIE_SOCKIE_KEYS: string[] = [
    'userId',
    'sessionId',
    'timestamp',
    'hash',
]
