import { RaceType } from '@obr-core/config/race'

/**
 * Currency
 */
export enum Currency {
    EUR = 'EUR',
    GBP = 'GBP',
    SEK = 'SEK',
    USD = 'USD',
    CAD = 'CAD',
    PLN = 'PLN',
    TRY = 'TRY',
    BGN = 'BGN',
    PEN = 'PEN',
    CHF = 'CHF',
    BRL = 'BRL',
    GEL = 'GEL',
    ARS = 'ARS',
    CLP = 'CLP',
    MXN = 'MXN',
    NOK = 'NOK',
}

/**
 * Odds Format
 */
export enum OddsFormat {
    DECIMAL = 'base1',
    BASE10 = 'base10',
    FRACTIONAL = 'fractional',
}

/**
 * Unit system
 */
export enum UnitSystem {
    METRIC = 'metric',
    IMPERIAL = 'imperial',
}

/**
 * License jurisdictions
 */
export const enum Jurisdiction {
    MALTA = 'MT',
    GERMANY = 'DE',
    UK = 'GB',
    IRELAND = 'IE',
}

/**
 * Default top level domain
 */
export const SETTINGS_DEFAULT_TLD: OBR.Settings.TLD = 'com'

/**
 * Default currency
 */
export const SETTINGS_DEFAULT_CURRENCY: Currency = Currency.EUR

/**
 * Default licence jurisdiction
 */
export const SETTINGS_DEFAULT_JURISDICTION: Jurisdiction = Jurisdiction.MALTA

/**
 * Default Code dial
 */
export const SETTINGS_DEFAULT_DIAL: string = Jurisdiction.GERMANY

/**
 * Default Odds format
 */
export const SETTINGS_DEFAULT_ODDS_FORMAT: OddsFormat = OddsFormat.DECIMAL

/**
 * Default Odds format for english countries
 */
export const SETTINGS_DEFAULT_ODDS_FORMAT_BRITISH: OddsFormat =
    OddsFormat.FRACTIONAL

/**
 * Available license jurisdictions
 */
export const SETTINGS_JURISDICTIONS: Jurisdiction[] = [
    Jurisdiction.MALTA,
    Jurisdiction.GERMANY,
    Jurisdiction.UK,
    Jurisdiction.IRELAND,
]

/**
 * British Isles
 */
export const SETTINGS_BRITISH_ISLES: OBR.Settings.Country[] = [
    'GB',
    'GG',
    'GI',
    'IE',
    'IM',
    'JE',
]

/**
 * Ajax host
 */
export const SETTINGS_AJAX_HOST: string = import.meta.env.VUE_APP_AJAX_HOST

/**
 * GDPR enabled
 */
export const SETTINGS_GDPR_ENABLED: boolean =
    import.meta.env.VUE_APP_GDPR_ENABLED === 'true'

/**
 * Default limited access enabled
 */
export const SETTINGS_LIMITED_ACCESS_ENABLED: boolean = false

/**
 * Countries where Trustly registration is enabled
 */
export const SETTINGS_TRUSTLY_COUNTRIES: string[] = ['SE', 'FI']

/**
 * Key for default active raceTypes
 */
export const SETTINGS_DEFAULT_ACTIVE_RACE_TYPES: RaceType[] = []

/**
 * List of race type filters
 */
export const SETTINGS_RACE_TYPE_FILTERS: RaceType[] = [
    RaceType.GALLOP,
    RaceType.JUMP,
    RaceType.TROT,
    RaceType.GREYHOUND,
]

/**
 *  ThreatMetrix enabled
 */
export const SETTINGS_THREAT_METRIX_ENABLED: boolean =
    import.meta.env.VUE_APP_THREAT_METRIX_ENABLED === 'true'

/**
 *  ThreatMetrix script tag URL
 */
export const SETTINGS_THREAT_METRIX_TAG_URL: string = import.meta.env
    .VUE_APP_THREAT_METRIX_TAG_URL

/**
 *  ThreatMetrix Organization Id
 */
export const SETTINGS_THREAT_METRIX_ORG_ID: string = import.meta.env
    .VUE_APP_THREAT_METRIX_ORG_ID

/**
 * DOMPurify config - all safe elements
 */
export const SETTINGS_DOMPURIFY_DEFAULT_CONFIG: DOMPurify.Config = {}

/**
 * DOMPurify config - allow all safe SVG elements and SVG Filters
 */
export const SETTINGS_DOMPURIFY_SVG_CONFIG: DOMPurify.Config = {
    USE_PROFILES: { svg: true, svgFilters: true },
}

/**
 * DOMPurify config - all safe HTML elements but neither SVG nor MathML
 */
export const SETTINGS_DOMPURIFY_HTML_CONFIG: DOMPurify.Config = {
    USE_PROFILES: { html: true },
}

/**
 * Default `betslip_confirmation` setting
 */
export const SETTINGS_DEFAULT_BETSLIP_CONFIRMATION: boolean = false

/**
 * Default `open_betslip` setting
 */
export const SETTINGS_DEFAULT_OPEN_BETSLIP: boolean = true

/**
 * Default `countries_order` setting
 */
export const SETTINGS_DEFAULT_COUNTRIES_ORDER: string[] = []

/**
 * Default `hide_dogs` setting
 */
export const SETTINGS_DEFAULT_HIDE_DOGS: boolean = false

/**
 * Default `hide_balance` setting
 */
export const SETTINGS_DEFAULT_HIDE_BALANCE: boolean = false

/**
 * Default `unit_system` setting
 */
export const SETTINGS_DEFAULT_UNIT_SYSTEM: UnitSystem = UnitSystem.METRIC

/**
 * Default `unit_system` setting for british isles
 */
export const SETTINGS_DEFAULT_UNIT_SYSTEM_BRITISH: UnitSystem =
    UnitSystem.IMPERIAL

/**
 * Default `eneble_virtual` setting
 */
export const SETTINGS_DEFAULT_ENABLED_VIRTUAL: boolean = true

/**
 * Default `diseble_virtual_countreis` setting
 */
export const SETTINGS_DEFAULT_DISABLED_VIRTUAL_COUNTRIES: OBR.Settings.Country[] =
    ['BR', 'DE', 'GE', 'SE']

export const ODDS_FRACTIONAL: { [index: number]: string } = {
    1.03: '1/33', // aprox of: 3/100
    1.06: '1/16', // aprox of: 3/50
    1.07: '1/14', // aprox of: 7/100
    1.08: '1/12', // aprox of: 2/25
    1.09: '1/11', // aprox of: 9/100
    1.11: '1/9', // aprox of: 11/100
    1.12: '1/8', // aprox of: 3/25
    1.13: '2/15', // aprox of: 13/100
    1.14: '1/7', // aprox of: 7/50
    1.15: '2/13', // aprox of: 3/20
    1.16: '1/6', // aprox of: 4/25
    1.18: '2/11', // aprox of: 9/50
    1.22: '2/9', // aprox of: 11/50
    1.26: '20/75', // WRONG: 13/50
    1.28: '2/7', // aprox of: 7/25
    1.33: '1/3', //aprox of: 33/100
    1.36: '4/11', // aprox of: 9/25
    1.42: '40/95', // WRONG: 21/50
    1.44: '4/9', // aprox of: 11/25
    1.47: '40/85', // aprox of: 47/100
    1.53: '8/15', // aprox of: 53/100
    1.57: '4/7', // aprox of: 57/100
    1.61: '8/13', // aprox of: 61/100
    1.62: '5/8', // aprox of: 31/50
    1.66: '4/6', // aprox of: 33/50
    1.72: '8/11', // aprox of: 18/25
    1.75: '15/20', // WRONG: 3/4
    1.83: '5/6', // aprox of: 83/100
    1.9: '10/11', // WRONG: 9/10
    1.95: '20/21', //WRONG: 19/20
    2.37: '11/8', // aprox of: 137/100
    2.5: '6/4', //WRONG: 3/2
    2.62: '13/8', // aprox of:81/50
    2.87: '15/8', // aprox of:187/100
    3.12: '85/40', //WRONG: 53/25
    3.37: '95/40', // aprox of:237/100
    4.33: '10/3', // aprox of:333/100
    4.75: '75/20', //WRONG: 15/4
    99.9: '99/1', // aprox of:989/10
    99.99: '99/1', // aprox of:9899/100
}

/**
 * dial code used for phone prefix
 */
export const DIAL_CODES_OBJECT: OBR.Common.Object<number> = {
    GB: 44,
    IM: 44,
    JE: 44,
    GG: 44,
    US: 1,
    DZ: 213,
    AD: 376,
    AO: 244,
    AI: 1264,
    AG: 1268,
    AR: 54,
    AM: 374,
    AW: 297,
    AU: 61,
    AT: 43,
    AZ: 994,
    BS: 1242,
    BH: 973,
    BD: 880,
    BB: 1246,
    BY: 375,
    BE: 32,
    BZ: 501,
    BJ: 229,
    BM: 1441,
    BT: 975,
    BO: 591,
    BA: 387,
    BW: 267,
    BR: 55,
    BN: 673,
    BG: 359,
    BF: 226,
    BI: 257,
    KH: 855,
    CM: 237,
    CA: 1,
    CV: 238,
    KY: 1345,
    CF: 236,
    CL: 56,
    CN: 86,
    CO: 57,
    KM: 269,
    CG: 242,
    CK: 682,
    CR: 506,
    HR: 385,
    CU: 53,
    CY: 357,
    CZ: 42,
    DK: 45,
    DJ: 253,
    DM: 1809,
    DO: 1809,
    EC: 593,
    EG: 20,
    SV: 503,
    GQ: 240,
    ER: 291,
    EE: 372,
    ET: 251,
    FK: 500,
    FO: 298,
    FJ: 679,
    FI: 358,
    FR: 33,
    GF: 594,
    PF: 689,
    GA: 241,
    GM: 220,
    GE: 7880,
    DE: 49,
    GH: 233,
    GI: 350,
    GR: 30,
    GL: 299,
    GD: 1473,
    GP: 590,
    GU: 671,
    GT: 502,
    GN: 224,
    GW: 245,
    GY: 592,
    HT: 509,
    HN: 504,
    HK: 852,
    HU: 36,
    IS: 354,
    IN: 91,
    ID: 62,
    IR: 98,
    IQ: 964,
    IE: 353,
    IL: 972,
    IT: 39,
    JM: 1876,
    JP: 81,
    JO: 962,
    KZ: 7,
    KE: 254,
    KI: 686,
    KP: 850,
    KR: 82,
    KW: 965,
    KG: 996,
    LA: 856,
    LV: 371,
    LB: 961,
    LS: 266,
    LR: 231,
    LY: 218,
    LI: 417,
    LT: 370,
    LU: 352,
    MU: 230,
    MO: 853,
    MK: 389,
    MG: 261,
    MW: 265,
    MY: 60,
    MV: 960,
    ML: 223,
    MT: 356,
    MH: 692,
    MQ: 596,
    MR: 222,
    YT: 269,
    MX: 52,
    FM: 691,
    MD: 373,
    MC: 377,
    MN: 976,
    MS: 1664,
    MA: 212,
    MZ: 258,
    NA: 264,
    NR: 674,
    NP: 977,
    NL: 31,
    NC: 687,
    NZ: 64,
    NI: 505,
    NE: 227,
    NG: 234,
    NU: 683,
    NF: 672,
    NO: 47,
    OM: 968,
    PK: 92,
    PW: 680,
    PA: 507,
    PG: 675,
    PY: 595,
    PE: 51,
    PH: 63,
    PL: 48,
    PT: 351,
    PR: 1787,
    QA: 974,
    RE: 262,
    RO: 40,
    RU: 7,
    RW: 250,
    SM: 378,
    ST: 239,
    SA: 966,
    SN: 221,
    RS: 381,
    SC: 248,
    SL: 232,
    SG: 65,
    SK: 421,
    SI: 386,
    SB: 677,
    SO: 252,
    ZA: 27,
    ES: 34,
    LK: 94,
    SH: 290,
    KN: 1869,
    SD: 249,
    SR: 597,
    SZ: 268,
    SE: 46,
    CH: 41,
    SY: 963,
    TW: 886,
    TJ: 7,
    TH: 66,
    TG: 228,
    TO: 676,
    TT: 1868,
    TN: 216,
    TR: 90,
    TM: 993,
    TC: 1649,
    TV: 688,
    UG: 256,
    UA: 380,
    AE: 971,
    UY: 598,
    UZ: 7,
    VU: 678,
    VA: 379,
    VE: 58,
    VN: 84,
    VG: 84,
    VI: 84,
    WF: 681,
    YE: 967,
    ZM: 260,
    ZW: 263,
}

export const TIME_RESEND_SMS = 59

/**
 * Default stakes for betting
 * e.g. used in preset amounts component
 */
export const SETTINGS_DEFAULT_PRESET_STAKES_SINGLE = {
    [Currency.EUR]: [0.5, 2, 10, 50, 100],
    [Currency.USD]: [0.5, 2, 10, 50, 100],
    [Currency.GBP]: [0.5, 2, 10, 50, 100],
    [Currency.SEK]: [5, 20, 100, 500, 1000],
    [Currency.NOK]: [5, 20, 100, 500, 1000],
    [Currency.CAD]: [0.75, 3, 15, 80, 100],
    [Currency.PLN]: [2, 8, 20, 100, 200],
    [Currency.TRY]: [10, 40, 200, 1000, 2000],
    [Currency.BGN]: [1, 4, 20, 100, 200],
    [Currency.PEN]: [2, 8, 20, 100, 200],
    [Currency.CHF]: [0.5, 2, 10, 50, 100],
    [Currency.BRL]: [4, 20, 100, 600, 800],
    [Currency.GEL]: [0.5, 3, 10, 25, 50],
    [Currency.ARS]: [100, 300, 2000, 7500, 10000],
    [Currency.CLP]: [500, 3000, 10000, 50000, 75000],
    [Currency.MXN]: [10, 40, 200, 1000, 2000],
} as {
    [key in Currency]: number[]
}

/**
 * Default stakes for multiple betting
 * e.g. used in preset amounts component
 */
export const SETTINGS_DEFAULT_PRESET_STAKES_MULTIPLE = {
    [Currency.EUR]: [0.5, 2, 10, 50, 100],
    [Currency.USD]: [0.5, 2, 10, 50, 100],
    [Currency.GBP]: [0.5, 2, 10, 50, 100],
    [Currency.SEK]: [5, 20, 100, 500, 1000],
    [Currency.NOK]: [5, 20, 100, 500, 1000],
    [Currency.CAD]: [0.75, 3, 15, 80, 100],
    [Currency.PLN]: [2, 8, 20, 100, 200],
    [Currency.TRY]: [10, 40, 200, 1000, 2000],
    [Currency.BGN]: [1, 4, 20, 100, 200],
    [Currency.PEN]: [2, 8, 20, 100, 200],
    [Currency.CHF]: [0.5, 2, 10, 50, 100],
    [Currency.BRL]: [4, 20, 100, 600, 800],
    [Currency.GEL]: [0.5, 3, 10, 25, 50],
    [Currency.ARS]: [100, 300, 2000, 7500, 10000],
    [Currency.CLP]: [500, 3000, 10000, 50000, 75000],
    [Currency.MXN]: [10, 40, 200, 1000, 2000],
} as {
    [key in Currency]: number[]
}

/**
 * Default stakes for exotic betting
 * e.g. used in preset amounts component
 */
export const SETTINGS_DEFAULT_PRESET_STAKES_EXOTIC = {
    [Currency.EUR]: [0.5, 1, 2, 5, 10],
    [Currency.USD]: [0.5, 1, 2, 5, 10],
    [Currency.GBP]: [0.5, 1, 2, 5, 10],
    [Currency.SEK]: [5, 10, 15, 50, 100],
    [Currency.NOK]: [5, 10, 15, 50, 100],
    [Currency.CAD]: [0.75, 1.5, 3, 7, 15],
    [Currency.PLN]: [2, 4, 8, 15, 20],
    [Currency.TRY]: [10, 20, 40, 100, 200],
    [Currency.BGN]: [1, 2, 4, 10, 20],
    [Currency.PEN]: [2, 4, 8, 15, 20],
    [Currency.CHF]: [0.5, 1, 2, 5, 10],
    [Currency.BRL]: [4, 8, 20, 80, 100],
    [Currency.GEL]: [0.5, 1, 3, 7, 10],
    [Currency.ARS]: [100, 150, 300, 1000, 2000],
    [Currency.CLP]: [500, 1000, 3000, 7500, 10000],
    [Currency.MXN]: [10, 20, 40, 100, 200],
} as {
    [key in Currency]: number[]
}

/**
 * Default stakes for us (dollar) betting
 * e.g. used in preset amounts component
 */
export const SETTINGS_DEFAULT_PRESET_STAKES_US = {
    [Currency.USD]: [0.5, 5, 10, 50, 100],
} as {
    [key in Currency]: number[]
}
