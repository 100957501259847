import { http } from '@obr-core/lib/http'
import { HTTP_REST_V2 } from '@obr-core/config/http'
import { ModalService } from '@obr-core/services/ModalService'
import { i18n } from '@obr-core/i18n/i18n'

export function getReleaseInfo(): void {
    http.get(`${HTTP_REST_V2}/release_info`).then((result) => {
        const newReleaseVersion = result.data.current_release_version
        const currentReleaseVersion = import.meta.env.VUE_APP_RELEASE_VERSION

        if (currentReleaseVersion !== newReleaseVersion) {
            displayReleaseInfoModal()
        }
    })
}

export function displayReleaseInfoModal(): void {
    ModalService.getInstance().confirmationModal({
        status: 'action',
        title: i18n.global.t('generic.label_app_updated'),
        message: i18n.global.t('generic.label_app_updated_please_reload'),
        buttonText: i18n.global.t('generic.label_reload'),
        onClickButton: () => window.location.reload(),
        onClose: () => window.location.reload(),
    })
}
