import { errorLogger } from '@obr-core/services/ErrorLogger'
import { registerResponseInterceptors } from '@obr-core/lib/http'

import {
    onAuthenticationError,
    onAuthenticationBeforeError,
    onGenericError,
    onCSRFTokenExpired,
    onCanceledRequestError,
} from '@obr-core/errors/app.errors'

/**
 * Error Service
 * Singleton
 */
export class ErrorService {
    private static instance: ErrorService
    private errorList: OBR.Errors.ErrorList[] = []

    /**
     * Return class instance
     */
    public static getInstance(): ErrorService {
        if (ErrorService.instance === undefined) {
            ErrorService.instance = new ErrorService()
        }
        return ErrorService.instance
    }

    /**
     * Initialize using ErrorLogger (Sentry) and Vue global error handler
     */
    public initialize(options: OBR.ErrorLogger.InitOptions) {
        // Init error logger
        errorLogger.initialize(options)

        // Register global response interceptors
        registerResponseInterceptors(this.interceptAjaxCall.bind(this))

        // Vue global error handler
        options.app.config.errorHandler = (error: any) =>
            this.fallbackErrorHandler(error as Error)
    }

    /**
     * On Before hook is triggered before error
     *
     * e.g. do an action before error
     */
    public onBeforeError(error: OBR.Errors.SimpleError) {
        switch (error.code) {
            case '401':
                onAuthenticationBeforeError()
                break
        }
    }

    /**
     * Handle all errors
     *
     * e.g. handle error by status code or message key from API
     */
    public onError(error: OBR.Errors.SimpleError) {
        switch (error.code) {
            case 'ERR_CANCELED':
                onCanceledRequestError()
                break
            case '422':
                onCSRFTokenExpired()
                break
            case '401':
                onAuthenticationError()
                break
            default:
                onGenericError(error)
        }
    }

    /**
     * Ajax intercept handler
     *
     * e.g. handle all http errors
     */
    public interceptAjaxCall(error: OBR.Errors.AjaxError) {
        const message =
            // @ts-ignore
            error.response?.data?.error?.message ||
            // @ts-ignore
            error.response?.data?.errorMsg ||
            error.message
        const code = `${error.response?.status || error.code || ''}`

        /**
         * Handle error before adding to the error list
         */
        this.onBeforeError({ code, message })

        /**
         * Define error as timeout so we are able to call "preventDefault",
         * in case we want to handle error outside ErrorService
         *
         * i.e. add error to error list and execute after 100ms
         */
        const timeout = setTimeout(() => {
            this.onError({ code, message })

            this.removeError(error) // remove error from the list
        }, 100)

        this.errorList.push({ error, timeout })
    }

    /**
     * Prevent error from being triggered
     *
     * i.e. remove an error from the list and clear timeout
     */
    public preventDefault(error: Error) {
        const errorIndex = this.findErrorIndex(error)

        if (this.errorList[errorIndex]) {
            clearTimeout(this.errorList[errorIndex].timeout)

            this.removeError(error)
        }
    }

    /**
     * A global handler for uncaught errors propagating from within the application
     *
     * e.g. hanlde Vue errors
     */
    public fallbackErrorHandler(error: Error) {
        onGenericError(error)
    }

    /**
     * Rrmove error from "errorList"
     *
     * e.g. after error is handled remove it from error list
     */
    private removeError(error: Error) {
        const index = this.findErrorIndex(error)

        this.errorList.splice(index, 1)
    }

    /**
     * Find error index in errorList
     */
    private findErrorIndex(error: Error): number {
        return this.errorList.findIndex(
            (item: OBR.Errors.ErrorList) => item.error === error
        )
    }
}
