import { parseJSON } from '@obr-core/utils/utils'

/**
 * Server rendered i18n messages key
 */
export const I18N_WINDOW_KEY: string = '__I18N_MESSAGES__'

/**
 * i18n load modules error state
 */
export const I18N_LOAD_MODULES_ERROR: string = 'I18N_LOAD_MODULES_ERROR'

/**
 * URL param key to switch language
 * Example: loading in an iframe with search param `?language=de`
 */
export const I18N_SEARCH_PARAM_KEY: string = 'language'

/**
 * Extended list of base module for logged out customers
 */
export const I18N_INIT_MODULE_PROSPECT: OBR.I18n.Module = 'init_prospect'

/**
 * Init prospect modules
 */
export const I18N_INIT_MODULES_PROSPECT: OBR.I18n.Module[] = [
    'generic',
    'racing',
    'betting',
]

/**
 * Extended list of base module for logged in users
 */
export const I18N_INIT_MODULE_CUSTOMER: OBR.I18n.Module = 'init_customer'

/**
 * Init customer modules
 */
export const I18N_INIT_MODULES_CUSTOMER: OBR.I18n.Module[] = [
    'generic',
    'racing',
    'account',
    'betting',
]

/**
 * Default locale
 */
export const I18N_DEFAULT_LOCALE: OBR.I18n.Locale = 'en-GB'

/**
 * Project and brand specific languages available
 */
export const I18N_AVAILABLE_LANGUAGES: OBR.I18n.Locale[] = parseJSON(
    import.meta.env.VUE_APP_LANGUAGES
)

/**
 * Key to get access to locale
 */
export const I18N_STORAGE_KEY_LOCALE: OBR.Storage.Key = 'I18n/locale'

/**
 * Key to get access to locale messages
 */
export const I18N_STORAGE_KEY_MODULES: OBR.Storage.Key = 'I18n/modules'

/**
 * Key to get access to hashmap with modules hashes
 */
export const I18N_STORAGE_KEY_HASHMAP: OBR.Storage.Key = 'I18n/hashmap'

/**
 * Path to manifest.json containing translation modules hashes.
 */
export const I18N_MANIFEST_URL: OBR.Storage.Key = '/i18n/manifest.json'
