import { raceStoreService } from '@obr-core/services/store'
import {
    getDisplayValueForJackpotLabel,
    getDisplayValueForPurse,
} from '@obr-core/helpers/display-value.helpers'
import { i18n } from '@obr-core/i18n/i18n'

export function formatPickBetSelection(
    pickBet: OBR.Events.Pickbet
): OBR.Betting.PickBetSelection {
    const pickBetSelection: OBR.Betting.PickBetSelection = {}

    pickBet.races.forEach((race, i) => {
        //add race info to pickBetSelection
        const raceInfo = raceStoreService.raceByCache(race.id_race)

        if (!raceInfo) {
            return
        }

        pickBetSelection[race.id_race] = {
            leg: i + 1,
            country: raceInfo.country,
            event_title: raceInfo.event_title,
            post_time: raceInfo.post_time,
            race_id: raceInfo.id,
            race_number: raceInfo.number,
            runners: {},
        }
    })

    return pickBetSelection
}

export function formatPickBetLeg({
    id_pickbet,
    runner,
    race,
}: OBR.UI.Components.PickBets.UpdateBetslipPickBetPayload): OBR.UI.Components.PickBets.UpdateBetslipPickBetLegPayload {
    return {
        idPickBet: id_pickbet,
        idLeg: race.id,
        runner: {
            id_runner: runner.id,
            name: runner.name,
            program_number: runner.program_number,
            silk_path: runner.silk_path,
            odds_fxw: runner.odds_fxw,
            excluded: false,
            scratched: false,
        },
    }
}

export function formatPickBetsTags(
    jackpot: OBR.Race.RaceJackpot | undefined,
    pool: {
        amount: number
        currency: string
        carryover: number
    }
) {
    const tags = []
    if (jackpot) {
        tags.push({
            text: getDisplayValueForJackpotLabel(jackpot, true, false),
        })
    } else if (pool && (pool.amount || pool.carryover)) {
        tags.push({
            pool: pool.amount
                ? getDisplayValueForPurse(pool.amount, pool.currency)
                : undefined,
            carryover: pool.carryover
                ? getDisplayValueForPurse(pool.carryover, pool.currency)
                : undefined,
            text: i18n.global.t('racing.label_current_pool', {
                value: getDisplayValueForPurse(
                    pool.amount + pool.carryover,
                    pool.currency
                ),
            }),
        })
    }

    return tags
}

export function formatPickBetDetails(
    pickbet: OBR.Events.Pickbet,
    eventId: string
) {
    const details = []

    if (pickbet.single_event) {
        details.push(
            `${i18n.global.t('racing.label_races')}: ${pickbet.races
                .map((race) => race.race_number)
                .join(', ')}`
        )
    } else {
        const eventsRacesMap = new Map()

        pickbet.races.forEach((race) => {
            const racesNumber = eventsRacesMap.get(race.id_event) || []
            eventsRacesMap.set(race.id_event, [
                ...racesNumber,
                race.race_number,
            ])
        })

        Object.entries(Object.fromEntries(eventsRacesMap)).forEach(
            ([key, value]) => {
                if (String(key) === eventId) {
                    details.unshift(
                        `${i18n.global.t(
                            'racing.label_races_from_this_event'
                        )}: ${(value as number[]).join(', ')}`
                    )
                } else {
                    const eventTitle = pickbet.titles?.[key]
                    details.push(
                        `${i18n.global.t('racing.label_races_from', {
                            data: eventTitle,
                        })}: ${(value as number[]).join(', ')}`
                    )
                }
            }
        )
    }

    return details
}
