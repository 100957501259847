import { http } from '@obr-core/lib/http'
import {
    formatLocale,
    mapLocaleToLanguage,
    getCachedHashmap,
    removeDoubleBracket,
} from '@obr-core/helpers/i18n.helpers'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { ORIGIN } from '@obr-core/config/http'

export class I18nResource {
    private readonly apiPath: string = `${ORIGIN}/i18n`

    /**
     * Complete all requests. Ignore failed ones
     */
    public async loadModules(
        locale: OBR.I18n.Locale,
        modules: OBR.I18n.Module[] = []
    ) {
        const response = await Promise.all<OBR.Http.AjaxResponse>(
            this.importModules(locale, modules).map((p) => p.catch((e) => e))
        ).then((res) => res)

        return this.mapModules(response, modules)
    }

    /**
     * Load date-fns locale flie
     */
    public async loadDateLocaleFile(locale: OBR.I18n.Locale) {
        try {
            const dateLocale = await this.mapLocaleToFile(locale)

            return dateLocale
        } catch (err) {
            errorLogger.createException(err)

            throw err
        }
    }

    /**
     * Get matching locale file
     */
    private mapLocaleToFile(locale: OBR.I18n.Locale) {
        const lang = mapLocaleToLanguage(locale)
        const localeFormatted = formatLocale(locale)

        switch (lang) {
            case 'cs':
                return import(
                    /* webpackChunkName: "i18n.locale.cs" */
                    'date-fns/locale/cs'
                )
            case 'da':
                return import(
                    /* webpackChunkName: "i18n.locale.da" */
                    'date-fns/locale/da'
                )
            case 'de':
                return import(
                    /* webpackChunkName: "i18n.locale.de" */
                    'date-fns/locale/de'
                )
            case 'es':
            case 'ag':
            case 'cl':
            case 'co':
            case 'mx':
            case 'pe':
                return import(
                    /* webpackChunkName: "i18n.locale.es" */
                    'date-fns/locale/es'
                )
            case 'el':
                return import(
                    /* webpackChunkName: "i18n.locale.el" */
                    'date-fns/locale/el'
                )
            case 'et':
                return import(
                    /* webpackChunkName: "i18n.locale.et" */
                    'date-fns/locale/et'
                )
            case 'fi':
                return import(
                    /* webpackChunkName: "i18n.locale.fi" */
                    'date-fns/locale/fi'
                )
            case 'fr':
                return import(
                    /* webpackChunkName: "i18n.locale.fr" */
                    'date-fns/locale/fr'
                )
            case 'hu':
                return import(
                    /* webpackChunkName: "i18n.locale.hu" */
                    'date-fns/locale/hu'
                )
            case 'is':
                return import(
                    /* webpackChunkName: "i18n.locale.is" */
                    'date-fns/locale/is'
                )
            case 'it':
                return import(
                    /* webpackChunkName: "i18n.locale.it" */
                    'date-fns/locale/it'
                )
            case 'ja':
                return import(
                    /* webpackChunkName: "i18n.locale.ja" */
                    'date-fns/locale/ja'
                )
            case 'ka':
                return import(
                    /* webpackChunkName: "i18n.locale.ka" */
                    'date-fns/locale/ka'
                )
            case 'lv':
                return import(
                    /* webpackChunkName: "i18n.locale.lv" */
                    'date-fns/locale/lv'
                )
            case 'mt':
                return import(
                    /* webpackChunkName: "i18n.locale.mt" */
                    'date-fns/locale/mt'
                )
            case 'nb':
            case 'no':
                return import(
                    /* webpackChunkName: "i18n.locale.nb" */
                    'date-fns/locale/nb'
                )
            case 'nl':
                return import(
                    /* webpackChunkName: "i18n.locale.nl" */
                    'date-fns/locale/nl'
                )
            case 'nn':
                return import(
                    /* webpackChunkName: "i18n.locale.nn" */
                    'date-fns/locale/nn'
                )
            case 'pl':
                return import(
                    /* webpackChunkName: "i18n.locale.pl" */
                    'date-fns/locale/pl'
                )
            case 'br':
            case 'pt':
                return import(
                    /* webpackChunkName: "i18n.locale.pt" */
                    'date-fns/locale/pt'
                )
            case 'ru':
                return import(
                    /* webpackChunkName: "i18n.locale.ru" */
                    'date-fns/locale/ru'
                )
            case 'sk':
                return import(
                    /* webpackChunkName: "i18n.locale.sk" */
                    'date-fns/locale/sk'
                )
            case 'sv':
                return import(
                    /* webpackChunkName: "i18n.locale.sv" */
                    'date-fns/locale/sv'
                )
            case 'tr':
                return import(
                    /* webpackChunkName: "i18n.locale.tr" */
                    'date-fns/locale/tr'
                )
            case 'zh':
                return import(
                    /* webpackChunkName: "i18n.locale.zh" */
                    'date-fns/locale/zh-CN'
                )

            default:
                if (localeFormatted === 'en-AU') {
                    return import(
                        /* webpackChunkName: "i18n.locale.en" */
                        'date-fns/locale/en-AU'
                    )
                }
                if (localeFormatted === 'en-CA') {
                    return import(
                        /* webpackChunkName: "i18n.locale.en" */
                        'date-fns/locale/en-CA'
                    )
                }
                return import(
                    /* webpackChunkName: "i18n.locale.en" */
                    'date-fns/locale/en-GB'
                )
        }
    }

    /**
     * Return an array of request Promises to be completed
     */
    private importModules(locale: OBR.I18n.Locale, modules: OBR.I18n.Module[]) {
        const hashmap = getCachedHashmap(locale)

        return modules.reduce((arr, module) => {
            const hash = (hashmap && hashmap[module]) || ''
            arr.push(
                http.get(
                    `${this.apiPath}/${mapLocaleToLanguage(
                        locale
                    )}/i18n_${module}.json?${hash}`
                )
            )
            return arr
        }, [] as OBR.Http.AjaxPromise[])
    }

    /**
     * Map loaded translations to their module names to be used by vue-i18n
     * @param responses Array of server responses in order
     * @param moduleList List of modules were loaded in order
     */
    private mapModules(
        responses: OBR.Http.AjaxResponse[],
        moduleList: OBR.I18n.Module[]
    ) {
        return removeDoubleBracket(
            responses.reduce((obj, elem, i) => {
                if (elem.data) {
                    obj[moduleList[i]] = elem.data
                }
                return obj
            }, {} as OBR.I18n.LocaleMessages<any>)
        )
    }
}
