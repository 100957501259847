/**
 * Form tabs type
 */
export enum FormTabsType {
    SPOTLIGHT = 'spotlight',
    HORSE = 'horse',
    FORM = 'form',
    STATISTICS = 'statistics',
    JOCKEY = 'jockey',
    TRAINER = 'trainer',
    ANTE_POST = 'ante_post',
    NOTES = 'notes',
}
