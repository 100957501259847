/**
 * Safe JSON parse with try/catch wrap
 * @param json string to parse
 */
export function parseJSON(json: string): any | undefined {
    let res
    if (json) {
        try {
            res = JSON.parse(json)
        } catch (e) {
            window.console.error(e)
        }
    }
    return res
}

/**
 * Returns if passed argument is an Object
 * @param obj Any
 */
export function isObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object'
}

/**
 * Returns if passed argument is an empty object
 * @param obj Any
 */
export function isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * Deep copy an object. Make sure you are using this correctly!
 * You will lose any property that has no equivalent type in JSON, like Function
 * or Infinity, Map, Set or properties like undefined. Also, some objects are
 * converted to strings, like Date objects for example
 * @param obj Object to clone
 */
export function deepCopy(obj: { [key: string]: any }): { [key: string]: any } {
    let clone = {}
    try {
        clone = JSON.parse(JSON.stringify(obj))
    } catch (e) {
        window.console.error(e)
    }
    return clone
}

/**
 * Get hostname domain with "isLocalhost" boolean value
 * @param hostname Hostname
 */
export function getDomain(hostname: string): {
    domain: string
    isLocalhost: boolean
} {
    const parts = hostname.split('.')

    return {
        domain: parts.slice(parts.length - 2).join('.'),
        isLocalhost: parts.length === 1,
    }
}

/**
 * Check if host is IP
 * @param hostname Hostname
 */
export function isIP(hostname: string): boolean {
    return !!hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)
}

/**
 * Get cookie domain
 * @param hostname Hostname
 * @param domainOnly Get only domain, if false subdomains will be included
 */
export function getCookieDomain(
    hostname: string,
    domainOnly?: boolean
): string {
    if (isIP(hostname)) {
        return hostname
    } else {
        const { domain, isLocalhost } = getDomain(hostname)

        if (domainOnly) {
            return isLocalhost ? domain : `.${domain}`
        }

        return isLocalhost ? hostname : `.${hostname}`
    }
}

/**
 * Get TLD from hostname
 * Returns null if it is localhost or IP
 * @param hostname Hostname
 */
export function getTld(hostname: string): OBR.Settings.TLD | null {
    const parts = hostname.split('.')
    if (isIP(hostname) || parts.length === 1) {
        return null
    }
    const tld = parts[parts.length - 1] as OBR.Settings.TLD

    return tld
}

/**
 * Loads a script from given url and injects it into <head>
 * @param url
 */
export function loadScript(url: string, id?: string): void {
    if (id && document.getElementById(id)) {
        return
    }

    const head: HTMLElement = document.getElementsByTagName('head')[0]
    const script: HTMLScriptElement = document.createElement('script')
    script.src = url
    if (id) {
        script.id = id
    }
    head.appendChild(script)
}

/**
 * convert standard object to form url encoded
 * @param data common Object
 * @returns URLSearchParams
 */
export function json2FormUrlEncoded(
    data: Record<string, any>
): URLSearchParams {
    const params = new URLSearchParams()
    for (const key in data) {
        params.append(key, data[key])
    }
    return params
}

/**
 * Compare if 2 objects are equals
 */
export function areObjectsEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b)
}
