import {
    SETTINGS_THREAT_METRIX_ENABLED,
    SETTINGS_THREAT_METRIX_TAG_URL,
    SETTINGS_THREAT_METRIX_ORG_ID,
} from '@obr-core/config/settings'
import { loadScript } from '@obr-core/utils/utils'

/**
 * initialization of threatMetrix
 * @param tmSessionId Session ID
 */
export function initThreatMetrix(tmSessionId: string) {
    if (SETTINGS_THREAT_METRIX_ENABLED) {
        const tmProfilingTagURL =
            SETTINGS_THREAT_METRIX_TAG_URL +
            `?org_id=${SETTINGS_THREAT_METRIX_ORG_ID}` +
            `&session_id=${tmSessionId}`
        loadScript(tmProfilingTagURL, 'idThreatMetrix')
    }
}
