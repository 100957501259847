import {
    format,
    isToday as isTodayHelper,
    isYesterday as isYesterdayHelper,
    isTomorrow as iisTomorrowHelper,
} from 'date-fns'
import { tz } from '@date-fns/tz'

import { I18nService } from '@obr-core/services/I18nService'
import { DT_ONE_DAY } from '@obr-core/config/date-time'
import { userStoreService } from '@obr-core/services/store'

const i18nService = I18nService.getInstance()

/**
 * Compare if year, month and day of two dates are the same
 */
export function compareDates(date1: Date, date2: Date): boolean {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    )
}

/**
 * Check is post time in past
 *
 * @param timestamp in seconds like: 1671485401
 */
export function isTimestampInPast(timestamp: number) {
    const diff = new Date().getTime() - timestamp * 1000

    return diff < 0
}

/**
 * Wrapper function for the date-fns format function
 * @param date
 * @param formatStr
 */
export function formatDate(date: number | Date, formatStr: string): string {
    const timeZone = tz(userStoreService.timezone())
    const locale = i18nService.getDateLocale()

    try {
        return format(timeZone(date), formatStr, {
            locale,
        })
    } catch (e: unknown) {
        return ''
    }
}

/**
 * Format date to the 'YYYY-MM-DD' ISO 8601 format
 * @param date - JavaScript Date,
 */
export function formatDateISO(date: Date | number): string {
    const timeZone = tz(userStoreService.timezone())
    const dateZoned = timeZone(date)
    const year = dateZoned.getFullYear()
    const month = `0${dateZoned.getMonth() + 1}`.slice(-2)
    const day = `0${dateZoned.getDate()}`.slice(-2)

    return `${year}-${month}-${day}`
}

/**
 * Get number of days from provided day to now
 */
export function getDaysFromNow(date: string | Date): number {
    return Math.ceil(
        (new Date().getTime() - new Date(date).getTime()) / DT_ONE_DAY
    )
}

export function getUserDate(date?: Date | number | string) {
    const dateTZ = tz(userStoreService.timezone())

    if (date) {
        return dateTZ(date)
    }

    return dateTZ(new Date())
}

export function isToday(date: string | number | Date) {
    const dateTZ = tz(userStoreService.timezone())
    return isTodayHelper(dateTZ(date))
}

export function isTomorrow(date: string | number | Date) {
    const dateTZ = tz(userStoreService.timezone())
    return iisTomorrowHelper(dateTZ(date))
}
export function isYesterday(date: string | number | Date) {
    const dateTZ = tz(userStoreService.timezone())
    return isYesterdayHelper(dateTZ(date))
}
