export function initialState(): OBR.Store.Ui {
    return {
        tabletPortrait: false,
        tabletLandscape: false,
        laptop: false,
        desktop: false,
        headerHeight: 0,
        subHeaderHeight: 0,
        iframeHeight: 0,
        iframeTop: 0,
        iframeScroll: 0,
        lastDrawerOpened: 'main',
        scrollMap: {
            left: 0,
            main: 0,
            right: 0,
        },
        isUIResizing: false,
        isRouteChanging: false,
    }
}
