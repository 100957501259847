import { ROUTER_LOCALISED_ROUTES } from '@obr-core/config/router'
import { getPreferredLocale } from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'
import {
    createLocalisedRoutes,
    withDefaultLocalisedRoutes,
} from '../../../scripts/routes.helpers'

/**
 * Get locale routes
 * @param locale
 */
export function getLocaleRoutes(locale: string): OBR.Common.Object<string> {
    const routesLocale: OBR.Common.Object<string> = {}

    try {
        let routes: OBR.Common.Object<OBR.Common.Object<string>> = {}

        if (document.obrConfig && document.obrConfig.localised_routes) {
            routes = withDefaultLocalisedRoutes(
                // signatures do not match currently, they did not match before but
                // we used @ts-nocheck in top of file which we should not use here
                // @ts-expect-error
                createLocalisedRoutes(document.obrConfig.localised_routes),
                Object.keys(document.obrConfig.languages)
            )
        } else {
            routes = JSON.parse(ROUTER_LOCALISED_ROUTES)
        }

        for (const key in routes) {
            routesLocale[key] = routes[key][locale.substr(0, 2)] || key
        }
    } catch {
        // no-op
    }

    return routesLocale
}

/**
 * Routes locale
 */
export const routesLocale = getLocaleRoutes(
    I18nService.getInstance(getPreferredLocale()).getLanguage()
)
