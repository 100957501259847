import { EMITTER_WEBSOCKET_BET_STATUS } from '@obr-core/config/emitter'
import { emitter } from '@obr-core/services/EmitterService'
import { RoomType } from '@obr-core/config/push-server'
import { UserPrivateChannel } from '@obr-core/config/web-sockets'
import { userStoreService } from '@obr-core/services/store'
import { userCookieService } from '@obr-core/services/UserCookieService'
import { WebSocketsService } from '@obr-core/services/websockets/WebSocketsService'

/**
 * UserWebSocketsService
 *
 * Singleton
 */
export class UserWebSocketsService {
    private static instance: UserWebSocketsService
    private webSocketService: WebSocketsService

    /**
     *  Store the channelNames for this user
     */
    private channelNames: { defaults: string; balance: string } = {
        defaults: RoomType.USER_PRIVATE,
        balance: RoomType.USER_PRIVATE,
    }

    private constructor() {
        this.webSocketService = WebSocketsService.getInstance()
    }

    /**
     * Return class instance
     */
    public static getInstance(): UserWebSocketsService {
        if (UserWebSocketsService.instance === undefined) {
            UserWebSocketsService.instance = new UserWebSocketsService()
        }

        return UserWebSocketsService.instance
    }

    /**
     * @method joinBalanceChannel
     *
     * @description
     *  Notifies node.js server about user login action and connects to user private channel
     *
     */
    public joinBalanceChannel(): void {
        const userId = userStoreService.getUserId()
        const token = userStoreService.getToken()

        if (!token) {
            throw new Error('Cannot join balance channel. Token is missing!')
        }

        this.channelNames.balance = this.channelNames.defaults + userId
        this.registerUserListeners()

        //Emit the authorise socket event, so user is logged in to Node
        this.webSocketService.emitSocketEvent('authorise', { token })
    }

    /**
     * Triger logout success event
     */
    public logoutSuccess() {
        this.webSocketService.triggerEvent('user:logout:success')
    }

    /**
     * Handle bet status update websocket
     *
     * i.e. user_private_ data channel "node_betStatus"
     */
    public addBetStatusListener(
        callback: (message: OBR.WebSockets.BetStatus) => void
    ) {
        emitter.on(EMITTER_WEBSOCKET_BET_STATUS, callback)
    }

    /**
     * Remove bet status update websocket listener
     *
     * i.e. user_private_ data channel "node_betStatus"
     */
    public removeBetStatusListener() {
        emitter.off(EMITTER_WEBSOCKET_BET_STATUS)
    }

    /**
     * Registers listeners:
     *
     * - onUpdateChannel
     * - onNodeLoggedIn
     */
    private registerUserListeners(): void {
        this.webSocketService.addEventListener(
            `update:channel:${this.channelNames.balance}`,
            this.onUpdateCallback()
        )

        //listen to 'node:loggedIn'
        this.webSocketService.addEventListener(
            'node:loggedIn',
            this.onNodeLoggedInCallback()
        )
    }

    /**
     * onNodeLoggedIn Callback:
     *
     * - join the balance channel, once login to NODE is successful
     */
    private onNodeLoggedInCallback(): OBR.WebSockets.Callback {
        return () => {
            this.webSocketService.join({
                channel: this.channelNames.balance,
            })
        }
    }

    /**
     * onUpdate Callback function
     */
    private onUpdateCallback(): OBR.WebSockets.Callback {
        return (payload?: OBR.Common.Object<any>) => {
            if (!payload) return

            if (payload.data && payload.data.message) {
                const message = payload.data.message

                if (message === 'logout') {
                    userStoreService.softLogout()
                }

                switch (payload.data.channel) {
                    case UserPrivateChannel.BALANCE_INFO:
                        this.balanceInfoUpdate(message)
                        break
                    case UserPrivateChannel.BET_STATUS:
                        emitter.emit(EMITTER_WEBSOCKET_BET_STATUS, message)
                        break
                    default:
                        this.webSocketService.triggerEvent(
                            'user:data:update',
                            message
                        )
                }
            }
        }
    }

    /**
     * Handle balance info update websocket
     *
     * i.e. user_private_ data channel "node_balanceInfo"
     */
    private balanceInfoUpdate(message: OBR.WebSockets.BalanceInfo) {
        if (message?.freebets?.length) {
            message.freebets = this.parseFreebets(message.freebets)
        }

        userStoreService.setUserBalance(message)
    }
    // TODO: refactor socket response to snake case
    private parseFreebets(
        freebets: OBR.Bonuses.Freebet[]
    ): OBR.Bonuses.Freebet[] {
        if (!freebets) return []

        return freebets.map((freebet) => {
            return {
                amount: freebet.amount,
                antepost: freebet.antepost,
                countries: freebet.countries,
                country: freebet.country,
                description: freebet.description,
                expiration_stamp: freebet.expirationStamp,
                first_start: freebet.firstStart,
                id_event: freebet.idEvent,
                id_freebet: `${freebet.idFreebet}`,
                multi_betting: freebet.multiBetting,
                promotion_code: freebet.promotionCode,
                race_type: freebet.raceType,
                title: freebet.title,
            }
        })
    }
}
