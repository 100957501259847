// @ts-nocheck
type Route = Record<string, string>

/**
 * Add en locale as default route for all languages provided
 *
 * e.g. when routes are { race: { en: "race-card" } }
 * e.g. result for provided languages ["en", "de"] is { race: { en: "race-card", de: "race-card" } }
 */
export function withDefaultLocalisedRoutes(
    routes: Record<string, Route>,
    locales: string[]
): Record<string, Route> {
    return Object.keys(routes).reduce((total, key) => {
        total[key] = { ...routes[key] }

        for (let i = 0; i < locales.length; i++) {
            const locale = locales[i]

            if (!total[key][locale]) {
                total[key][locale] = total[key]['en']
            }
        }

        return total
    }, {})
}

/**
 * Recursive function to map routes to an object
 *
 * Check for '_' property and map all the nested properties
 *
 * Join parents with '_'
 *
 * e.g. { offers: {_: 'offers', million: 'million' }}
 * will result in { offers: 'offers', offers_million: 'million'}
 */
export function createLocalisedRoutes(
    routes: Route,
    parents: string[] = []
): Record<string, string> {
    let final: Record<string, string> = {}

    for (const key in routes) {
        if (routes[key]['_']) {
            final = {
                ...final,
                ...createLocalisedRoutes(routes[key], [...parents, key]),
            }
        } else {
            if (parents.length > 0) {
                if (key === '_') {
                    final[parents.join('_')] = routes[key]
                } else {
                    final[`${parents.join('_')}_${key}`] = routes[key]
                }
            } else if (routes[key]['en']) {
                final[key] = routes[key]
            } else {
                throw new Error(
                    `Argument "routes" has an invalid object structure - Property "${key}" is missing required property "_"`
                )
            }
        }
    }

    return final
}
