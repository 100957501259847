import { DT_ONE_MINUTE } from '@obr-core/config/date-time'

/**
 * Betslip Tab
 */
export enum BetslipTab {
    OPEN_BETS = 'open_bets',
    BETSLIP = 'betslip',
    BET_BUILDER = 'bet_builder',
}

/**
 * Betslip Tab
 */
export enum BetslipSubtab {
    SINGLES = 'singles',
    MULTIPLES = 'multiples',
    PICK = 'pick',
}

/**
 * Betslip betslip sections
 */
export enum BetslipSections {
    SINGLES = 'singles',
    MULTIPLES = 'multiples',
    PICK = 'pick',
}

export const PROVIDER_BET_ERROR_MESSAGE = 'PROVIDER_BET_ERROR_MESSAGE'

/**
 * Max number of races in betslip
 */
export const BETSLIP_FOOTER_ID = 'obr-betslip-footer'

/**
 * Max number of races in betslip
 */
export const BETSLIP_DRAWER_ID = 'obr-betslip-drawer'

/**
 * Multi bet error message prefix
 */
export const BETSLIP_MULTI_ERR_PREFIX = 'multi'

/**
 * Time after which betslip is permanentlly
 */
export const BETSLIP_CLEAR_TIMEOUT = 2 * DT_ONE_MINUTE
