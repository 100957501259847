import { getItem } from '@obr-core/lib/storage.manager'
import { STORAGE_KEY_FREEBETS_BANNER_SHOWN } from '@obr-core/config/storage'

export function initialState(): OBR.Store.BonusState {
    return {
        current_bonus: {
            bonuses: [],
            chunk: null,
        },
        pending_bonuses: [],
        freebets: [],
        histories: [],
        loadingOverview: false,
        loadedOverview: false,
        racesWithFreebets: [],
        isFreebetsBannerShown:
            getItem(STORAGE_KEY_FREEBETS_BANNER_SHOWN) || true,
    }
}
