import { AuthResource } from '@obr-core/resources/AuthResource'
import { isServer } from '@obr-core/helpers'
// import { store } from '@obr-core/store/store'
// import { AuthResource } from '@obr-core/resources/AuthResource'
// import { USER_IS_LOGGED_IN } from '@obr-core/store/modules/user/getters'
// import { USER_LOGOUT, USER_ME } from '@obr-core/store/modules/user/actions'
// import { APP_GET_TM_SESSION_ID } from '@obr-core/store/modules/app/getters'

/**
 * Auth Service
 * Singleton
 */
export class AuthService {
    /**
     * Auth service singleton instance
     */
    private static instance: AuthService

    private authResource: AuthResource

    /**
     * Constructor
     */
    private constructor() {
        this.authResource = new AuthResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): AuthService {
        if (AuthService.instance === undefined) {
            AuthService.instance = new AuthService()
        }
        return AuthService.instance
    }

    /**
     * User Login
     *
     * @param username
     * @param password
     * @param tmSessionId
     * @param accepter
     */
    public async login({
        username,
        password,
        autoLogin = false,
        acceptTerms = false,
    }: OBR.User.LoginUserInput) {
        const params = {
            username,
            password,
            // tmSessionId: '', //store.getters[APP_GET_TM_SESSION_ID],
            autoLogin,
            acceptTerms,
        }

        return this.authResource
            .login(params)
            .then((res) => {
                // store.dispatch(USER_ME)
                // TODO: store in localStorage
                return res
            })
            .catch((err) => {
                // The `NEW_TERMS` is broken. TODO: https://racebets.atlassian.net/browse/PS-1183
                if (err.response.data.error.message === 'NEW_TERMS') {
                    //TODO: show popup with new terms
                    //resubmit login with acceptTerms true
                    params.acceptTerms = true
                    this.authResource.login(params).then((res) => {
                        // store.dispatch(USER_ME)
                        // TODO: store in localStorage
                        return res
                    })
                } else if (
                    isServer() &&
                    err.response.data.error.message === 'WRONG_CREDENTIALS'
                ) {
                    throw new Error('You must be logged in')
                    // forward error to be processed in the resolver
                } else if (err.response.data.error.message === 'EXTERNAL_ACCESS_NOT_ALLOWED') {
                    throw new Error(' Please note login for test accounts is not allowed from outside the office or VPN.')
                }
                //return err
                throw new Error(err)
            })
    }

    /**
     * Logout user
     */
    public async logout() {
        return this.authResource.logout()
    }
}
