import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'

export class FavouritesResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/favourites`

    /**
     * Fetch all favourites by raceID
     */
    public async getAllByRaceId(
        raceID: string
    ): Promise<OBR.Favourites.RaceFavourite[]> {
        try {
            const url = `${this.apiPath}/${raceID}`
            const response = await http.get<OBR.Favourites.RaceFavourite[]>(url)

            return response.data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Get upcoming starts
     */
    public async getUpcomingStarts(): Promise<OBR.Favourites.Favourite[]> {
        try {
            const url = `${this.apiPath}/upcoming`
            const response = await http.get<OBR.Favourites.UpcomingResponse>(
                url
            )

            return response.data.upcoming
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Search horses by name
     */
    public async searchByHorseName(
        name: string
    ): Promise<OBR.Favourites.SearchResult[]> {
        try {
            const url = `${this.apiPath}/search/${name}`
            const response = await http.get<OBR.Favourites.SearchResult[]>(url)

            return response.data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Get stables
     */
    public async getStables(
        page: number
    ): Promise<OBR.Favourites.StablesResponse> {
        try {
            const url = `${this.apiPath}?page=${page}`
            const response = await http.get<OBR.Favourites.StablesResponse>(url)

            return response.data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Update favourite data
     */
    public async setFavourite(
        payload: OBR.Favourites.FavouriteDto
    ): Promise<void> {
        try {
            await http.post(this.apiPath, payload)
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Delete favourite
     */
    public async deleteFavourite(idSubject: string): Promise<void> {
        try {
            await http.delete(this.apiPath, {
                data: { id_subject: idSubject },
            })
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }
}
