import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'

/**
 * Bonus resource class
 */
export class BonusResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/users/me/bonuses`
    private _pendingBonusOverview: Promise<OBR.Bonuses.BonusesOverview> | null =
        null

    /**
     * Claim a bonus
     */
    public async claimBonus(
        bonusId: string
    ): Promise<OBR.Bonuses.ClaimBonusPayload> {
        try {
            const { data } = await http.post(
                `${this.apiPath}/claim/${bonusId}`,
                {}
            )
            return data
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Bonuses Overview
     */
    public async getBonusesOverview(): Promise<OBR.Bonuses.BonusesOverview> {
        if (this._pendingBonusOverview !== null) {
            return this._pendingBonusOverview
        }
        try {
            this._pendingBonusOverview = http
                .get(`${this.apiPath}`)
                .then((res) => res.data)

            const data = await this._pendingBonusOverview
            this._pendingBonusOverview = null
            return this.parseBonusesOverview(data)
        } catch (e) {
            this._pendingBonusOverview = null
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Retrieves bonuses for user
     */
    public async getBonusDetails(idBonusChunk: number) {
        try {
            const { data } = await http.get(
                `${this.apiPath}/details/${idBonusChunk}`
            )

            return this.parseBonusDetails(data)
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Redeem bonus
     */
    public async redeemBonus(bonus_ids: number[]) {
        try {
            const { data } = await http.post(`${this.apiPath}/redeem`, {
                bonus_ids,
            })
            return this.parseBonusRedeem(data)
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Parse bonuses overview
     * @param data
     */
    private parseBonusesOverview(res: any): OBR.Bonuses.BonusesOverview {
        return {
            current_bonus:
                res.current_bonus && 'bonuses' in res.current_bonus
                    ? this.parseCurrentBonus(res.current_bonus)
                    : undefined,
            bonus_list: {
                pending_bonuses: res.pending_bonuses,
                freebets: res.freebets,
                history: res.history,
            },
        }
    }

    /**
     * Parse currentBonus
     * @param data
     */
    private parseCurrentBonus(res: any): OBR.Bonuses.CurrentBonus {
        return {
            bonuses: this.parseBonusesCurrentBonus(res?.bonuses),
            chunk: this.parseBonusChunk(res?.chunk),
        }
    }

    /**
     * Parse bonus details
     * @param data
     */
    private parseBonusDetails(data: any): OBR.Bonuses.BonusDetails[] {
        return [...data]
    }

    /**
     * Parse bonuses redeem
     * @param data
     */
    private parseBonusRedeem(data: any): OBR.Bonuses.BonusRedeemPayload {
        return {
            success: data && data.length === 0,
        }
    }

    /**
     * Parse bonuses chunk
     * @param data
     */
    private parseBonusChunk(data: any): OBR.Bonuses.BonusChunk {
        if (data) {
            return {
                ...data,
                act_turnover: parseFloat(data.act_turnover),
                amount: parseFloat(data.amount),
                csh_turnover: parseFloat(data.csh_turnover),
                expiration_stamp: parseInt(data.expiration_stamp),
                id_bonus_chunk: parseInt(data.id_bonus_chunk),
                remaining_act_turnover: parseFloat(data.remaining_act_turnover),
                remaining_csh_turnover: parseFloat(data.remaining_csh_turnover),
            }
        }
        return data
    }

    /**
     * Parse bonuses from current bonus
     * @param data
     */
    private parseBonusesCurrentBonus(bonuses: any): OBR.Bonuses.Bonuses[] {
        if (bonuses) {
            return bonuses.map((bonus: any) => ({
                ...bonus,
                amount: parseFloat(bonus.amount),
                id_bonus: parseInt(bonus.id_bonus),
            }))
        }
        return []
    }
}
