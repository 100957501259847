import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { FormTabsType } from '../../../ui/src/components/RunnerForm/config'

export class RunnerFormResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/races/runner`

    public async getById(
        subjectID: string,
        formType: FormTabsType = FormTabsType.SPOTLIGHT
    ) {
        try {
            const resource = this.convertRequestResource(formType)
            const { data } = await http.get(
                `${this.apiPath}/${subjectID}/${resource}`
            )

            return this.parseFomGuide(data, formType)
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    private parseFomGuide(
        data: any,
        formType: FormTabsType
    ): OBR.RunnerForm.RunnerForm {
        return {
            [formType]: data,
        }
    }

    private convertRequestResource(formType: FormTabsType): string {
        if (formType === FormTabsType.HORSE) {
            return 'subject'
        }

        if (formType === FormTabsType.ANTE_POST) {
            return 'antepost'
        }

        return formType
    }
}
