import { FavouritesResource } from '@obr-core/resources/FavouritesResource'

/**
 * Favourites Service
 * Singleton
 */
export class FavouritesService {
    private static instance: FavouritesService
    private favouritesResource: FavouritesResource

    private constructor() {
        this.favouritesResource = new FavouritesResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): FavouritesService {
        if (FavouritesService.instance === undefined) {
            FavouritesService.instance = new FavouritesService()
        }
        return FavouritesService.instance
    }

    /**
     * Add subject ID from favourites
     */
    public setFavourite(payload: OBR.Favourites.FavouriteDto) {
        return this.favouritesResource.setFavourite(payload)
    }

    /**
     * Remove subject ID from favourites
     */
    public deleteFavourite(idSubject: string) {
        return this.favouritesResource.deleteFavourite(idSubject)
    }

    /**
     * Fetch all favourites by race id
     */
    public async getAllByRaceId(idRace: string) {
        return this.favouritesResource.getAllByRaceId(idRace)
    }

    /**
     * Get upcoming starts
     *
     * e.g. used in favourites page for "Upcoming Starts" table
     */
    public async getUpcomingStarts(): Promise<OBR.Favourites.Favourite[]> {
        return this.favouritesResource.getUpcomingStarts()
    }

    /**
     * Search horses by name
     *
     * e.g. used in favourites page for "Search Horse Name"
     */
    public async searchByHorseName(name: string) {
        return this.favouritesResource.searchByHorseName(name)
    }

    /**
     * Get your stables starts
     *
     * e.g. used in favourites page for "Your Stables" table
     */
    public async getStables(
        page: number
    ): Promise<OBR.Favourites.StablesResponse> {
        return this.favouritesResource.getStables(page)
    }
}
