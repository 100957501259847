import { LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS } from '@obr-core/config/error-logger'
import { WS_ENABLED } from '@obr-core/config/web-sockets'
import { init, on, emit } from '@obr-core/lib/web-sockets.manager'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { userStoreService } from '@obr-core/services/store'

/**
 * WebSockets Service
 * Singleton
 */
export class WebSocketsService {
    /**
     * Web Socket service singleton instance
     */
    private static instance: WebSocketsService

    /**
     * Connected flag
     */
    protected connected: boolean = false

    /**
     * EventsMap: Maps event names to callbacks
     */
    protected eventsMap: OBR.WebSockets.EventsMap = {}

    /**
     *  Stores channel names for reconnection purposes
     */
    protected channels: { channel: string; timestamp?: number }[] = []

    /**
     *  Stores channel event listeners to be cleared on leave
     */
    protected channelEventListeners: OBR.WebSockets.ChannelEventListenerMap = {}

    /**
     * Constructor
     */
    private constructor() {
        if (WS_ENABLED) {
            // Init manager
            init()
            this.registerListeners()
        }
    }

    /**
     * Return class instance
     */
    public static getInstance(): WebSocketsService {
        if (WebSocketsService.instance === undefined) {
            WebSocketsService.instance = new WebSocketsService()
        }
        return WebSocketsService.instance
    }

    /**
     * Returns true if socket is connected
     */
    public isConnected(): boolean {
        return this.connected
    }

    /**
     * Join channel and store chanel data for reconnection purposes
     *
     * @param channel
     * @param timestamp
     * @param idEvent
     */
    public join({
        channel,
        timestamp,
        idEvent,
    }: {
        channel: string
        timestamp?: number
        idEvent?: string
    }) {
        const connectionData = { channel, timestamp: timestamp, idEvent }
        this.channels.push(connectionData)
        errorLogger.addBreadcrumb(
            LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
            `Joining channel: ${channel}`
        )
        emit('join', connectionData)
    }

    /**
     * @method leaveChannel
     *
     * @description
     *  Leave channel
     *
     *  @param channel
     */
    public leaveChannel(channel: string) {
        if (!channel) return
        emit('leave', channel)
        this.channels = this.channels.filter(
            (item: OBR.Common.Object<any>) => item.channel !== channel
        )
        if (this.channelEventListeners[channel]) {
            this.channelEventListeners[channel].forEach(
                (el: OBR.WebSockets.EventListener) => {
                    this.eventsMap[el.eventName] = this.eventsMap[
                        el.eventName
                    ].filter((cb: OBR.WebSockets.Callback) => cb !== el.cb)

                    if (this.eventsMap[el.eventName].length === 0) {
                        delete this.eventsMap[el.eventName]
                    }
                }
            )
            delete this.channelEventListeners[channel]
        }
    }

    /**
     * Adds a socket listener
     *
     * @param socketEventName
     * @param cb
     */
    public addSocketListener(
        socketEventName: string,
        cb: OBR.WebSockets.Callback
    ) {
        on(socketEventName, cb)
    }

    /**
     * Emits an event on the socket
     *
     * @param socketEventName
     * @param payload
     */
    public emitSocketEvent(
        socketEventName: string,
        payload: OBR.Common.Object<any>
    ) {
        emit(socketEventName, payload)
    }

    /**
     * Add an Event Listener
     * @param messageId
     * @param cb
     */
    public addEventListener(eventName: string, cb: OBR.WebSockets.Callback) {
        if (!this.eventsMap[eventName]) {
            this.eventsMap[eventName] = []
        }

        if (!this.eventsMap[eventName].find(cb)) {
            this.eventsMap[eventName].push(cb)
        }
    }

    /**
     * Add an Event Listener on a particular channel
     * @param eventName
     * @param channelName
     * @param cb
     * @param removeOnLeave
     */
    public addChannelEventListener(
        eventName: string,
        channelName: string,
        cb: OBR.WebSockets.Callback,
        removeOnLeave: boolean = true
    ): void {
        const fullEventName = eventName + ':channel:' + channelName
        this.addEventListener(fullEventName, cb)
        if (removeOnLeave) {
            if (!this.channelEventListeners[channelName]) {
                this.channelEventListeners[channelName] = []
            }
            this.channelEventListeners[channelName].push({
                eventName: fullEventName,
                cb: cb,
            })
        }
    }

    /**
     * Publish a message event
     * @param event
     * @param messenger
     */
    public triggerEvent(eventName: string, eventData?: OBR.Common.Object<any>) {
        const ref = this.eventsMap[eventName]
        if (ref) {
            ref.forEach((cb: (data: any) => void) => {
                cb(eventData)
            })
        }
    }

    /**
     * @method reconnect
     * @private
     *
     * @description
     *  Rejoin channels iterating over an array
     */
    protected reconnect() {
        this.channels.forEach((channel) => {
            emit('join', channel)
        })
    }

    /**
     * Registers all the listeners
     */
    private registerListeners(): void {
        this.addSocketListener('connect', this.onConnectCallback())
        this.addSocketListener('error', this.onErrorCallback())
        this.addSocketListener('disconnect', this.onDisconnectCallback())
        this.addSocketListener('reconnect', this.onReconnectCallback())
        this.addSocketListener('connect_error', this.onConnectErrorCallback())
        this.addSocketListener('dataUpdate', this.onDataUpdateCallback())
        this.addSocketListener('session', this.onSessionCallback())
    }

    /**
     * OnConnect callback
     */
    private onConnectCallback(): OBR.WebSockets.Callback {
        return () => {
            errorLogger.addBreadcrumb(
                LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
                'Connection with node server established'
            )
            this.triggerEvent('socket:connected')
            this.connected = true
        }
    }

    /**
     * onDataUpdate callback
     */
    private onDataUpdateCallback(): OBR.WebSockets.Callback {
        return (payload?: OBR.Common.Object<any>) => {
            if (!payload) return
            this.triggerEvent(`update:channel:${payload.channel}`, payload)
        }
    }

    /**
     * OnError callback
     */
    private onErrorCallback(): OBR.WebSockets.Callback {
        return () => {
            this.connected = false
            userStoreService.setLoggedInNode(false)
            errorLogger.addBreadcrumb(
                LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
                'Socket error',
                'error' as OBR.ErrorLogger.SentrySeverity
            )
        }
    }

    /**
     * OnDisconnect callback
     */
    private onDisconnectCallback(): OBR.WebSockets.Callback {
        return () => {
            this.connected = false
            errorLogger.addBreadcrumb(
                LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
                'Socket disconnected'
            )
        }
    }

    /**
     * OnReconnect callback
     */
    private onReconnectCallback(): OBR.WebSockets.Callback {
        return () => {
            this.reconnect()
            errorLogger.addBreadcrumb(
                LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
                'Socket reconnected'
            )
        }
    }

    /**
     * OnConnectError callback
     */
    private onConnectErrorCallback(): OBR.WebSockets.Callback {
        return () => {
            errorLogger.addBreadcrumb(
                LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS,
                'Socket connect error',
                'error' as OBR.ErrorLogger.SentrySeverity
            )
        }
    }

    /**
     * onSession callback
     */
    private onSessionCallback(): OBR.WebSockets.Callback {
        return (payload?: OBR.Common.Object<any>) => {
            if (!payload) return
            switch (payload.event) {
                case 'loggedIn':
                    userStoreService.setLoggedInNode(true)
                    this.triggerEvent('node:loggedIn')
                    break
                case 'loggedOut':
                    userStoreService.setLoggedInNode(false)
                    this.triggerEvent('node:loggedOut')
                    break
                case 'relogIn':
                    this.triggerEvent('node:relogin')
                    userStoreService.setLoggedInNode(true)
                    break
            }
        }
    }
}
