/**
 * OBR CORE / Config / App
 *
 * Application constants
 */

/**
 * DOM node id for mount
 */
export const APP_MOUNT: string = '#app'

/**
 * Global key on Window to store server side rendered app state
 */
export const APP_STATE_KEY: string = '__INITIAL_STATE__'

/**
 * Title string replacement
 */
export const APP_TITLE_TEMPLATE_STRING: string = '%'

/**
 * Title string template
 */
export const APP_TITLE_TEMPLATE: string = `${APP_TITLE_TEMPLATE_STRING}`

/**
 * App init error state
 */
export const APP_ERROR_INIT_ERROR: string = 'APP_INIT_ERROR'

/**
 * App unknown error state
 */
export const APP_ERROR_UNKNOWN_ERROR: string = 'APP_UNKNOWN_ERROR'

/**
 * Global key on Window to store server side rendered app state
 */
export const APP_SSR_DATA: string = '__INITIAL_SSR_STATE__'

/**
 * Global key on Window to store composition states
 */
export const APP_COMP_STATES: string = '__APP_COMPOSIBLE__'
