import { createI18n } from 'vue-i18n'

import { I18nService } from '@obr-core/services/I18nService'
import {
    getPreferredLocale,
    setLangAttribute,
} from '@obr-core/helpers/i18n.helpers'

const locale = getPreferredLocale()

// Instantiate and set language on service
const i18nService = I18nService.getInstance(locale)
// Save preferred language to storage
i18nService.storeLocale(locale)

// Set lang attribute on html element
setLangAttribute(locale)

let messages: OBR.I18n.LocaleMessages<any> = {}

// Create options
export const options: OBR.I18n.I18nOptions = {
    locale,
    messages: { [locale]: messages },
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    legacy: false,
}

// Init
export const i18n = createI18n(options)
