import { I18nService } from '@obr-core/services/I18nService'

const i18nService = I18nService.getInstance()

/**
 * Wrapper function for the Number toLocaleString
 */
export function formatToLocaleNumber(
    value: number,
    decimal?: boolean | number
): string {
    const locale = i18nService.getLocale()
    const options: Intl.NumberFormatOptions = {}
    if (decimal !== undefined) {
        if (decimal) {
            options.minimumFractionDigits =
                typeof decimal === 'boolean' ? 2 : decimal
            options.maximumFractionDigits =
                typeof decimal === 'boolean' ? 2 : decimal
        } else {
            options.maximumFractionDigits = 0
        }
    }

    return value.toLocaleString(locale, options)
}

/**
 * Wrapper function for the Intl.NumberFormat with currency style
 */
export function formatToLocaleNumberWithCurrency(
    value: number,
    currency: string,
    withDecimals: boolean = false
): string {
    const locale = i18nService.getLocale()
    const options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency,
    }

    if (!withDecimals) {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    return Intl.NumberFormat(locale, options).format(value)
}

/**
 * return symbol associated to currency
 * @param currency
 */
export function symbolByCurrency(
    currency: string,
    onlySymbol: boolean = false
) {
    if (currency) {
        const locale = i18nService.getLocale()
        const options: Intl.NumberFormatOptions = {
            style: 'currency',
            currency,
        }

        if (onlySymbol) {
            options.currencyDisplay = 'narrowSymbol'
        }
        return (
            Intl.NumberFormat(locale, options)
                .formatToParts(0)
                .find((chunk) => chunk.type === 'currency')?.value || currency
        )
    }
    return ''
}

/**
 * Check if currency has space between value and symbol by locale
 */
export function hasSpaceBetweenValueAndSymbol(value: number, currency: string) {
    const locale = i18nService.getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    })
    const parts = formatter.formatToParts(value)

    for (const part of parts) {
        if (part.type === 'literal') {
            // Check if the literal type is a whitespace
            return /\s/.test(part.value)
        }
    }

    return false
}

/**
 * return generic string with correct currency
 * e.g. 12k -> 12k €
 */
export function formatStringWithCurrency(value: string, currency: string) {
    const currencySymbol = symbolByCurrency(currency)
    const zeroValue = formatToLocaleNumberWithCurrency(0, currency)
    const hasSpace = hasSpaceBetweenValueAndSymbol(0, currency)
    // check if print before or after value
    if (zeroValue.indexOf(currencySymbol) === 0) {
        return currencySymbol + (hasSpace ? ' ' : '') + value
    } else {
        return value + (hasSpace ? ' ' : '') + currencySymbol
    }
}

/**
 * return  abriviated number
 * e.g. 2000 -> 2K, 200 -> 200
 */
export function formatToLocaleShortNumber(
    value: number,
    isLowerCase: boolean = false
): string {
    const formattedValue = Intl.NumberFormat('en-GB', {
        notation: 'compact',
        maximumFractionDigits: 1,
    }).format(value)

    return isLowerCase ? formattedValue.toLowerCase() : formattedValue
}
