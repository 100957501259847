import { FormguideResource } from '@obr-core/resources/FormguideResource'
import { FormguideTabsType } from 'components/Formguide/config'

/**
 * RunnerForm Service
 * Singleton
 */
export class FormguideService {
    private static instance: FormguideService
    private formguideResource: FormguideResource

    private constructor() {
        this.formguideResource = new FormguideResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): FormguideService {
        if (FormguideService.instance === undefined) {
            FormguideService.instance = new FormguideService()
        }
        return FormguideService.instance
    }

    public async getBySubject(
        subjectId: string,
        raceId?: string,
        fields?: FormguideTabsType
    ): Promise<OBR.Formguide.Formguide> {
        return this.formguideResource.getBySubject(subjectId, raceId, fields)
    }

    /**
     * Method returns race by subjectid and racedid
     * @param subjectId - string
     * @param raceId - number
     */
    public async getRaceBySubject(
        subjectId: string,
        raceId: string
    ): Promise<OBR.Formguide.FormRace> {
        return this.formguideResource.getRaceBySubject(subjectId, raceId)
    }

    /**
     * Method returns duel opponents by subjectid and search term
     * @param subjectId - string
     * @param searchTerm - string
     */
    public async getDuelOpponents(
        subjectId: string,
        searchTerm: string
    ): Promise<OBR.Formguide.SearchHorseResult[]> {
        return this.formguideResource.getDuelOpponents(subjectId, searchTerm)
    }

    /**
     * Method returns duel opponent by subjectid, raceid and opponentSubjectId
     * @param subjectId - string
     * @param opponentSubjectId - string
     * @param raceId - number | string
     */
    public async getDuelOpponent(
        subjectId: string,
        opponentSubjectId: string,
        raceId: number | string
    ): Promise<OBR.Formguide.DuelsResponse> {
        return this.formguideResource.getDuelOpponent(
            subjectId,
            opponentSubjectId,
            raceId
        )
    }
}
