/*
 * One second in miliseconds
 */
export const DT_ONE_SECOND: number = 1000

/**
 * One minute in miliseconds
 */
export const DT_ONE_MINUTE: number = 60000

/**
 * One day in milliseconds
 */
export const DT_ONE_DAY: number = 86400000

/**
 * One hour in milliseconds
 */
export const DT_ONE_HOUR: number = 3600000

/**
 * One twelve hours in milliseconds
 */
export const DT_TWELVE_HOUR: number = 43200000

/**
 * Start of today in milliseconds
 */
export const DT_START_OF_TODAY: number = new Date().setHours(0, 0, 0, 0)

/**
 * End of today in milliseconds
 */
export const DT_END_OF_TODAY: number = new Date(DT_START_OF_TODAY).setHours(
    23,
    59,
    59,
    999
)
