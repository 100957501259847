import { FormTabsType } from '@obr-ui/components/RunnerForm/config'
import { useRaces } from '@obr-core/store/races'

class RaceStoreService {
    racesCache(): { [key: string]: OBR.Race.Race } {
        return useRaces().racesCache
    }
    raceByCache(id: string): OBR.Race.Race | undefined {
        return useRaces().racesCache[id]
    }
    runnerByCache(id: string): OBR.Race.Runner | undefined {
        return useRaces().runnerCache[id]
    }
    selectedMarket(): OBR.Betting.MarketBetType | '' {
        return useRaces().selectedMarket
    }
    favouritesMap(): { [key: string]: OBR.Favourites.RaceFavourite[] } {
        return useRaces().favouritesMap
    }
    /* Actions */
    loadRace(raceId: string) {
        return useRaces().onRaceCardLoadRace(raceId)
    }
    loadRunnerForm(payload: {
        id: string
        field: FormTabsType
    }): Promise<OBR.RunnerForm.RunnerForm> {
        return useRaces().onRaceCardLoadRunnerForm(payload)
    }
    loadFavouritesByRaceId(raceID: string) {
        return useRaces().loadFavouritesByRaceId(raceID)
    }
    setFavourite(idRace: string, payload: OBR.Favourites.FavouriteDto) {
        return useRaces().setFavourite(idRace, payload)
    }
    resetFavourites() {
        return useRaces().resetFavourites()
    }
    updateRaceCardBySocket(
        id: string,
        updateRaceCard: OBR.Race.SocketRaceCardResponse
    ) {
        if (updateRaceCard) {
            return useRaces().onRaceCardUpdateRaceByRaceCardSocket({
                id: id,
                race: updateRaceCard,
            })
        }
    }

    updateRaceStatus(id: string, status: OBR.Race.RaceStatus) {
        useRaces().onRaceCardUpdateRaceStatus(id, status)
    }

    addRaceToStore(race: OBR.Race.Race, existRace: boolean) {
        if (existRace) {
            useRaces().onSetRace(race)
        } else {
            useRaces().onSetRacesCache({ race })
        }
    }
    addRunnerToStore(runner: OBR.Race.Runner, raceID: string) {
        useRaces().onRaceCardAddRunnerToCache({ runner, raceID })
    }

    addSpecialRaceToCache(specialRace: OBR.Race.Race) {
        useRaces().onAddSpecialRaceToCache(specialRace)
    }

    /**
     * ---------------------------
     * H2H race and runner section
     * ---------------------------
     */
    // MIXED - get but also update store from API
    // all H2H races for parent (normal) race with raceId
    loadRacesH2H(
        raceId: string,
        options: { noCache: boolean } = { noCache: false }
    ) {
        return useRaces().onRacesH2HByRaceID(raceId, options.noCache)
    }
    // all upcoming H2H races - widget and upcoming H2H page
    loadRacesH2HUpcoming() {
        return useRaces().onRacesH2HUpcoming()
    }

    // MUTATIONS
    addRunnerH2HToStore(runner: OBR.Race.Runner, raceID: string) {
        useRaces().onAddRunnerH2HToStore(runner, raceID)
    }

    addRaceH2HToStore(race: OBR.Race.Race) {
        useRaces().onSetRacesH2HInStore([race])
    }

    addRaceSpecialToStore(race: OBR.Race.Race) {
        useRaces().onSetRacesSpecialInStore([race])
    }

    /* Mutations */
    updateSelectedMarket(selectedMarket: OBR.Betting.MarketBetType | '') {
        return useRaces().onSetSelectedMarket(selectedMarket)
    }
    updateFixedOddsForRunner(
        idRace: string,
        idRunner: string,
        fxw: number,
        fxp: number
    ) {
        return useRaces().onUpdateFixedOddsForRunner(idRace, idRunner, fxw, fxp)
    }
}

export const raceStoreService = new RaceStoreService()
