// import { ENV_BRAND } from '@obr-core/config/env'
// import { STORAGE_PREFIX } from '@obr-core/config/storage'

import { ENV_BRAND, Brand } from '../config/env'
import { STORAGE_PREFIX } from '../config/storage'

/**
 * Add the prefixKey to a storage key
 * @param key Storage Key
 */
export function getKeyWithPrefix(key: OBR.Storage.Key) {
    let brand = ENV_BRAND
    if (document.obrConfig && document.obrConfig.brand) {
        brand = document.obrConfig.brand as Brand
    }
    return `${STORAGE_PREFIX}${brand}.${key}`
}
