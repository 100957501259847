import { useI18n } from 'vue-i18n'

import {
    BetType,
    BetCategory,
    MarksType,
    BetSpecialType,
    BETTING_PAYOUT_ODDS,
    BETTING_MARKET_H2H,
    BETTING_MARKET_SPECIAL,
} from '@obr-core/config/betting'

import {
    System,
    SystemLabel,
    baseBetsCount,
    BetslipMultiplesGenerator,
    BetslipMultipleSingleErrors,
    RmsError,
    BETSLIP_STORAGE_KEY_BETS,
    BETSLIP_STORAGE_KEY_RACES,
    BETSLIP_STORAGE_KEY_PICKBETS,
    BETSLIP_STORAGE_KEY_PICKBETS_ID,
    BETSLIP_MAX_NUMBER_OF_RACES,
} from '@obr-core/config/betslip'
import { FixedOddsStatus } from '@obr-core/config/race'
import {
    BETSLIP_DRAWER_ID,
    BETSLIP_FOOTER_ID,
    BETSLIP_MULTI_ERR_PREFIX,
} from '@obr-ui/components/Betslip/config'
import { generateId } from '@obr-ui/utils/utils'
import {
    formatToLocaleNumber,
    formatToLocaleNumberWithCurrency,
} from '@obr-core/utils/number.format'
import { raceStoreService } from '@obr-core/services/store'
import { formatOdds } from '@obr-core/helpers/odds.helpers'

import {
    getStakeMultiplier,
    getWinnings,
} from '@obr-core/modules/BettingEngine/helpers/potential-winnings.helpers'
import {
    OBRDocument,
    convertArabicToRoman,
    getTimestamp,
} from '@obr-core/helpers/generic.helpers'
import { getAllCombinations } from '@obr-core/modules/BettingEngine/helpers/betting-permutations.helpers'
import { i18n } from '@obr-core/i18n/i18n'

import { getRaceCardLinkExtended } from '@obr-ui/helpers/link-extended.helpers'
import { getSpecialStandaloneLink } from '@obr-ui/helpers/link.helpers'
import { specialsLink, homeLink } from '@obr-ui/helpers/link.helpers'
import { calcOddsForMultiples } from '@obr-core/modules/BettingEngine/helpers/mulitiples-odds-calculation.helpers'
import { formatDate } from '@obr-core/helpers/date.helpers'
import {
    FD_HOURS_AND_MINUTES,
    FD_LOCALIZED_DATE_SHORT,
} from '@obr-core/config/format-date'
import { compareDates } from '@obr-core/helpers/date.helpers'
import {
    getDisplayValueForRaceNumber,
    getMarketLabel,
} from '@obr-core/helpers/display-value.helpers'
import { DT_ONE_HOUR } from '@obr-core/config/date-time'
import { initMarks } from '@obr-core/modules/BettingEngine/helpers/betting-marks-buttons.helpers'
import { calculateTax } from '@obr-core/modules/BettingEngine/helpers/tax.helpers'
import { exchange } from '@obr-core/modules/BettingEngine/helpers/exchange.helpers'
import { AcceptBetsChanges } from '@obr-ui/components/Settings/config'
import { removeItem } from '@obr-core/lib/storage.manager'
/**
 * Get race ID - only for simple bets that have only race in selections
 */
export function getBetRaceId(bet: OBR.Betting.Bet): string {
    return bet.selections[0].id_race
}

/**
 * Get races IDs
 */
export function getBetRacesId(bet: OBR.Betting.Bet): string[] {
    const uniqueRacesIds = new Set<string>()
    Object.values(bet.selections).forEach((selection) => {
        uniqueRacesIds.add(selection.id_race)
    })
    return [...uniqueRacesIds]
}

/**
 * Get runner ID - only for simple bets that have only one race and one runner in selections
 */
export function getBetRunnerId(bet: OBR.Betting.Bet): string {
    return Object.values(bet.selections[0].columns)[0].runners[0].id_runner
}
/**
 * Get runners IDs
 */
export function getBetRunnersId(bet: OBR.Betting.Bet): string[] {
    const uniqueRunnersIds = new Set<string>()
    Object.values(bet.selections).forEach((selection) => {
        return Object.values(selection.columns).map((column) =>
            column.runners.forEach((runner) => {
                uniqueRunnersIds.add(runner.id_runner)
            })
        )
    })
    return [...uniqueRunnersIds]
}

/**
 * Get Runners IDs (Pick bets)
 * @param pickBetSelection
 * @returns { string[] } array of runners ids
 */
export function getPickBetRunnersId(
    pickBetSelection: OBR.Betting.PickBetSelection
): string[] {
    const uniqueRunnersIds = new Set<string>()
    Object.values(pickBetSelection).forEach((selection) => {
        Object.values(selection.runners).forEach((runner) => {
            uniqueRunnersIds.add(runner.id_runner)
        })
    })
    return [...uniqueRunnersIds]
}

export function getUniqueRacesIdFromBets(bets: OBR.Betting.Bet[]): string[] {
    return Array.from(
        bets.reduce((set, bet) => {
            getBetRacesId(bet).forEach((IdRace: string) => {
                set.add(IdRace)
            })
            return set
        }, new Set<string>())
    )
}

/**
 * Get runner odds - only for simple bets that have only one race and one runner in selections
 */
export function getBetRunnerOdds(
    bet: OBR.Betting.Bet,
    placeOdds: boolean = false
): number | undefined {
    if (placeOdds) {
        return Object.values(bet.selections[0].columns)[0].runners[0].odds_fxp
    }
    return Object.values(bet.selections[0].columns)[0].runners[0].odds_fxw
}

export function isPMUBetType(betType: BetType) {
    return (
        [
            'TOF',
            'QRP',
            'QNP',
            'SF4',
            'TRC',
            'M4',
            'M5',
            'M6',
            'M7',
            'PK5',
        ].indexOf(betType) > -1
    )
}

export function isPickBetType(betType: string): boolean {
    return /^(P|V|PP)(\d+)$/.test(betType)
}

export function isPickPlace(betType: string): boolean {
    return /^PP/.test(betType)
}

export function getPickNumRaces(pickType: string) {
    return isPickPlace(pickType)
        ? parseInt(pickType.substring(2), 10)
        : /^V(64|65|75)$/.test(pickType)
        ? parseInt(pickType.substring(1, 2), 10)
        : parseInt(pickType.substring(1), 10)
}

export function getPickSelections(
    pickbetSelection: OBR.Betting.PickBetSelection
): OBR.Betting.BetSelection[] {
    const _marks = []
    let _arrayRunner

    for (const idRace in pickbetSelection) {
        _arrayRunner = Object.values(pickbetSelection[idRace].runners).filter(
            (runner) => !runner.excluded
        )
        _marks.push({
            id_race: idRace,
            columns: {
                [MarksType.FIRST]: {
                    runners: _arrayRunner,
                },
            },
        })
    }
    return _marks as OBR.Betting.BetSelection[]
}

/**
 *
 * @param pickbetSelection
 * @returns { number[] } array of selecetd races, with count runner selected inside
 */
export function getPickMarks(pickbetSelection: OBR.Betting.PickBetSelection) {
    const _marks = []
    let _numRunner
    for (const raceId in pickbetSelection) {
        _numRunner = Object.entries(pickbetSelection[raceId].runners).filter(
            ([_, runner]) => !runner.excluded
        ).length
        _numRunner && _marks.push(_numRunner)
    }
    return _marks
}

export function getPickBetCount(marks: number[] = []) {
    let bet_count = marks[0] || 0
    if (marks.length < 2) return bet_count

    for (let i = 1, l = marks.length; i < l; i++) {
        bet_count *= marks[i]
    }

    return bet_count
}

export function isEachWay(
    market: OBR.Betting.BetType,
    betTypes: OBR.Race.BetType[] | undefined,
    betCategory: OBR.Betting.BetCategory
): boolean {
    if (!isEachWayAvailable(betTypes, market, betCategory)) return false
    if (market === BetType.EACHWAY) {
        return true
    }
    return false
}

export function isEachWayAvailable(
    betTypes: OBR.Race.BetType[] | undefined,
    thisBetType: OBR.Betting.BetType,
    betCategory: OBR.Betting.BetCategory
): boolean {
    if (!betTypes) return false

    const eachWayType = betTypes.find((item) => {
        return item.bet_type === BetType.EACHWAY
    })
    if (
        [BetType.WIN, BetType.EACHWAY].includes(thisBetType) &&
        eachWayType?.categories.length &&
        eachWayType.categories.includes(betCategory)
    ) {
        return true
    }
    return false
}

export function generateArrayOfNumbOfBetsPerRace(
    bets: OBR.Betting.Bet[]
): number[] {
    return Object.values(
        bets.reduce((counts: any, bet) => {
            if (bet.in_multiples)
                counts[getBetRaceId(bet)] = (counts[getBetRaceId(bet)] || 0) + 1

            return counts
        }, {})
    ) as number[]
}

export function calculateTotalBetCount(
    marks: number[],
    system: OBR.Betting.System,
    baseBetsCount: number,
    isEachWay: boolean
): number {
    let totalBets = 0
    function getNumBetsSystem(marks: number[], system: number) {
        if (system === 0) return 1

        let numBets = 0

        for (let i = 0; i < marks.length - (system - 1); i++) {
            for (let j = 0; j < marks[i]; j++) {
                const copy = [...marks]
                for (let k = 0; k < i + 1; k++) {
                    copy.shift()
                }
                numBets = numBets + getNumBetsSystem(copy, system - 1)
            }
        }

        return numBets
    }

    function getNumBetsCombi(marks: number[], numRaces: number) {
        let numBets = 0
        for (let i = 2; i <= numRaces; i++) {
            numBets = numBets + getNumBetsSystem(marks, i)
        }
        return numBets
    }

    if (system === System.FOLD) {
        totalBets += getNumBetsSystem(marks, baseBetsCount)
    } else if (system === System.COMB) {
        totalBets += getNumBetsCombi(marks, marks.length)
    } else if (system === System.FULL) {
        totalBets += getNumBetsCombi(marks, baseBetsCount)
        for (let i = 0, l = marks.length; i < l; i++) {
            totalBets += marks[i]
        }
    }
    if (isEachWay) totalBets *= 2
    return totalBets
}

export function getUniqueMultiplesRacesNumber(bets: OBR.Betting.Bet[]): number {
    return bets.reduce((acc: any, bet) => {
        if (bet.in_multiples) {
            acc.add(getBetRaceId(bet))
        }
        return acc
    }, new Set()).size
}

export function isMaxNumberOfRacesReached(bets: OBR.Betting.Bet[]): boolean {
    return (
        bets.reduce((acc: any, bet) => acc.add(getBetRaceId(bet)), new Set())
            .size >= BETSLIP_MAX_NUMBER_OF_RACES
    )
}

export function isBetFromUniqueRace(
    bets: OBR.Betting.Bet[],
    bet: OBR.Betting.Bet
) {
    return !bets.some((item) => getBetRaceId(item) === getBetRaceId(bet))
}
/**
 * Returns system label
 * e.g.: 'FOLD' && 4 -> label_system_4X, label_system_3X
 */
export function getSystemLabel(n: number, system: System): SystemLabel {
    return SystemLabel[`${system}_${n}` as keyof typeof SystemLabel]
}

/**
 * Returns array with system objects that are use to generate list of multiples
 * betsNumber is a number of bets added to multiples from unique races -
 * (if there is more bets in multiples that are form the same race they are count as 1)
 */
export function generateMultiples(
    numRaces: number
): BetslipMultiplesGenerator[] {
    const multiples = []

    if (numRaces >= 2) {
        for (let i = 2; i <= numRaces; i++) {
            multiples.push({
                system: System.FOLD,
                value: i,
                label: getSystemLabel(i, System.FOLD),
                baseBetsCount: baseBetsCount[getSystemLabel(i, System.FOLD)],
                amount: 1,
            })
        }
    }
    if (numRaces >= 3) {
        multiples.push({
            system: System.COMB,
            value: numRaces,
            label: getSystemLabel(numRaces, System.COMB),
            baseBetsCount: baseBetsCount[getSystemLabel(numRaces, System.COMB)],
            amount: 1,
        })
        if (numRaces <= 6) {
            multiples.push({
                system: System.FULL,
                value: numRaces,
                label: getSystemLabel(numRaces, System.FULL),
                baseBetsCount:
                    baseBetsCount[getSystemLabel(numRaces, System.FULL)],
                amount: 1,
            })
        }
    }

    return multiples
}

/**
 * Group bets per race
 */
export function groupBetsByRace(bets: OBR.Betting.Bet[]) {
    return bets.reduce((groups, bet) => {
        if (!groups[getBetRaceId(bet)]) {
            groups[getBetRaceId(bet)] = []
        }
        groups[getBetRaceId(bet)].push(bet)
        return groups
    }, {} as { [k: string]: OBR.Betting.Bet[] })
}

/**
 * Extends generated multiples with amount of bets and odds (if available)
 */
export function extendedGeneratedMultiples(
    multiple: OBR.Betting.BetslipMultiplesGenerator,
    bets: OBR.Betting.Bet[],
    races: (undefined | OBR.Race.Race)[]
): OBR.Betting.BetslipMultiplesGenerator {
    // get WIN odds
    const oddsWin = calcOddsForMultiples(multiple, getOddsForMultiCalc(bets))

    // group bets per race
    const betsPerRace = groupBetsByRace(bets)

    // get place odds per race
    const oddsPerRace = Object.entries(betsPerRace).map((entry) => {
        const [raceId, bets] = entry
        const placesNum =
            races.find((race) => race?.id === raceId)?.places_num || 0
        const odds = bets.map((bet) => getBetRunnerOdds(bet, true) || 0)
        // count max number of places odds for race
        odds.slice(0, placesNum)
        return odds
    })

    // generate all combinations of palce odds, calculate each of them and sum them
    const oddsPlc = getAllCombinations(oddsPerRace)
        .map((comb) => calcOddsForMultiples(multiple, comb))
        .reduce((acc, val) => acc + val, 0)

    if (oddsWin) multiple.odds = oddsWin
    if (oddsPlc) multiple.oddsPlc = oddsPlc

    multiple.amount = calculateTotalBetCount(
        generateArrayOfNumbOfBetsPerRace(bets),
        multiple.system,
        multiple.baseBetsCount,
        false
    )
    return multiple
}

/**
 * @param parts
 * @returns Category options array
 */
export function getCategoryOptions(
    betTypes: OBR.Race.BetType[],
    runner: OBR.Race.Runner,
    market: OBR.Betting.BetType,
    fixedOddsStatus: OBR.Race.FixedOddsStatus,
    oddsFormat: OBR.User.OddsFormat
): {
    active: boolean
    category: OBR.Betting.BetCategory
    key: OBR.Betting.BetCategory
    label: string
    value: string | number
}[] {
    const t = i18n.global.t
    const options = []
    const betTypeObj = betTypes.find((obj) => obj.bet_type === market)

    const oddsPlc = parseFloat(`${runner.odds_fxp}`)
    const oddsPrice = parseFloat(`${runner.odds_prc}`)
    const oddsWin = parseFloat(`${runner.odds_fxw}`)

    if (betTypeObj && betTypeObj.categories.includes(BetCategory.BOOKIE)) {
        const val = oddsPrice ? formatOdds(oddsPrice, oddsFormat) : ''

        options.push({
            key: BetCategory.BOOKIE,
            value: val,
            label: t('generic.label_sp'),
            category: BetCategory.BOOKIE,
            active: true,
        })
    }

    if (betTypeObj && betTypeObj.categories.includes(BetCategory.TOTE)) {
        const val = oddsPrice ? formatOdds(oddsPrice, oddsFormat) : ''

        options.push({
            key: BetCategory.TOTE,
            value: val,
            label: t('generic.label_tote_short'),
            category: BetCategory.TOTE,
            active: true,
        })
    }

    if (
        fixedOddsStatus === FixedOddsStatus.ON &&
        betTypeObj &&
        betTypeObj.categories.includes(BetCategory.FIXED) &&
        [BetType.WIN, BetType.PLACE, BetType.EACHWAY].includes(market)
    ) {
        const odds = market === BetType.PLACE ? oddsPlc : oddsWin
        const val = odds ? formatOdds(odds, oddsFormat) : ''

        options.push({
            key: BetCategory.FIXED,
            value: val,
            label: `@${val}`,
            category: BetCategory.FIXED,
            active: false,
        })
    }

    return options
}

export function prepareMultiple(data: {
    isEachWay: boolean
    stake: number
    system: OBR.Betting.System
    baseBetsCount: number
    amount: number
    userCurrency: OBR.User.Currency
}): OBR.Betting.Multiple {
    return {
        base_bets_count: data.baseBetsCount,
        is_each_way: data.isEachWay,
        system: data.system,
        stamp: getTimestamp(),
        unit_stake: data.stake,
        num_bets: data.isEachWay ? data.amount * 2 : data.amount,
        currency: data.userCurrency,
        uid: generateId(),
    }
}

export function prepareMultiplesToRemove(uniqueRacesInBetslipNumber: number): {
    system: OBR.Betting.System
    baseBetsCount: number
}[] {
    const oldMultiples = generateMultiples(uniqueRacesInBetslipNumber)
    const newMultiples = generateMultiples(uniqueRacesInBetslipNumber - 1)

    return oldMultiples
        .filter((multiple) => {
            if (
                newMultiples.some((x) => {
                    return (
                        x.system === multiple.system &&
                        x.baseBetsCount === multiple.baseBetsCount
                    )
                })
            ) {
                return false
            }
            return true
        })
        .map((x) => {
            return {
                system: x.system,
                baseBetsCount: x.baseBetsCount,
            }
        })
}

export function getTaxFeesForCategory(
    bet: OBR.Betting.Bet,
    race: OBR.Race.Race | undefined
): number {
    if (!race || !bet) return 0
    if (bet.category === BetCategory.BOOKIE && race?.tax_fees_bok) {
        return race.tax_fees_bok
    } else if (bet.category === BetCategory.FIXED && race?.tax_fees_fxd) {
        return race.tax_fees_fxd
    } else if (bet.category === BetCategory.TOTE && race?.tax_fees_tot) {
        return race.tax_fees_tot
    }
    return 0
}

/**
 * Price breakbreak down for taxable users
 */
export function getPriceBreakdown(
    bet: OBR.Betting.Bet,
    race: OBR.Race.Race,
    totalMoneyForThisBet: number,
    userDeduction: OBR.Common.Object<number> | [],
    runner: OBR.Race.Runner,
    currency: OBR.User.Currency
): OBR.Betting.PriceBreakdown {
    const taxFeesForCategory = getTaxFeesForCategory(bet, race)

    const taxInfo = calculateTax(
        totalMoneyForThisBet,
        taxFeesForCategory,
        userDeduction,
        bet.category
    )

    const { type, firstRow, secondRow, thirdRow } = getBetTitle(
        bet,
        race,
        runner,
        bet.special_type,
        race.event_title
    )

    return Object.assign(taxInfo, {
        betTitle: [
            bet.is_ante_post || bet.special_type === BetSpecialType.SPECIAL
                ? thirdRow
                : bet.special_type === BetSpecialType.H2H
                ? `${firstRow} ${secondRow}`
                : firstRow,
            type,
        ],
        taxDueFormated: formatToLocaleNumberWithCurrency(
            taxInfo.taxDue,
            currency,
            true
        ),
        taxSubventionFormated: formatToLocaleNumberWithCurrency(
            taxInfo.taxSubvention,
            currency,
            true
        ),
        totalCostFormated: formatToLocaleNumberWithCurrency(
            taxInfo.totalCost,
            currency,
            true
        ),
        stake: formatToLocaleNumberWithCurrency(
            totalMoneyForThisBet,
            currency,
            true
        ),
        betCategory: bet.category,
    })
}

/**
 * @param bets
 * @returns array of hihgest odds of each race from bets that are in multiples
 */
export function getOddsForMultiCalc(bets: OBR.Betting.Bet[]): number[] {
    const oddsByRace: { [k: string]: number } = {}

    for (const bet of bets) {
        if (bet.in_multiples) {
            if (bet.category_multi !== BetCategory.FIXED) {
                return []
            }
            const raceId = getBetRaceId(bet)
            const odds = getBetRunnerOdds(bet, false)
            if (odds) {
                oddsByRace[raceId] = Math.max(oddsByRace[raceId] || 0, odds)
            }
        }
    }

    return Object.values(oddsByRace)
}

/**
 * Calculate partial total stake for single bet
 */
export function calcTotalForSingle(
    bet: OBR.Betting.Bet,
    userCurrency: OBR.User.Currency,
    exchangeRates: OBR.Settings.ExchangeRates
): number {
    let totalMoney = 0
    if (bet.unit_stake) {
        if (!bet.id_freebet) {
            totalMoney +=
                bet.num_bets *
                exchange(
                    bet.unit_stake,
                    bet.currency,
                    userCurrency,
                    exchangeRates,
                    true
                )
        }
    }
    return totalMoney
}

/**
 * Calculate partial total stake for multiple bet
 */
export function calcTotalForMultiple(
    multiple: OBR.Betting.Multiple,
    multiples: OBR.Betting.BetslipMultiplesGenerator[],
    showPotentialWinnings: boolean
) {
    const totalMoney = Number(multiple.unit_stake) * Number(multiple.num_bets)
    let potentialWinnings = 0
    if (showPotentialWinnings) {
        const odds = multiples.find(
            (item: OBR.Betting.BetslipMultiplesGenerator) => {
                return (
                    item.system === multiple.system &&
                    item.baseBetsCount === multiple.base_bets_count
                )
            }
        )?.odds

        const oddsPlc = multiples.find(
            (item: OBR.Betting.BetslipMultiplesGenerator) => {
                return (
                    item.system === multiple.system &&
                    item.baseBetsCount === multiple.base_bets_count
                )
            }
        )?.oddsPlc
        if (odds) {
            potentialWinnings += getPotentialWinningsForMultiBet(
                multiple.is_each_way,
                odds,
                Number(multiple.unit_stake),
                oddsPlc
            )
        }
    }
    return {
        totalMoney,
        potentialWinnings,
    }
}

/**
 * Calculate potential winnings for fixed odds bet
 */
export function getPotentialWinningsForSingleBet(
    bet: OBR.Betting.Bet,
    runner: OBR.Race.Runner,
    race: OBR.Race.Race
): number {
    if (bet.category !== BetCategory.FIXED) return 0

    const unitStake =
        bet.id_freebet && bet.is_each_way && bet.unit_stake
            ? bet.unit_stake / 2
            : bet.unit_stake

    let result = getWinnings(
        bet.market,
        runner.odds_fxw,
        unitStake || 0,
        getStakeMultiplier(
            runner?.odds_fxp,
            BETTING_PAYOUT_ODDS,
            runner.odds_fxw,
            race.place_odds_factor
        ),
        BETTING_PAYOUT_ODDS,
        bet.id_freebet ? bet.unit_stake : 0
    )

    if (bet.is_each_way) {
        result += getWinnings(
            BetType.PLACE,
            runner.odds_fxw,
            unitStake || 0,
            getStakeMultiplier(
                runner?.odds_fxp,
                BETTING_PAYOUT_ODDS,
                runner.odds_fxw,
                race.place_odds_factor
            ),
            BETTING_PAYOUT_ODDS,
            bet.id_freebet ? bet.unit_stake : 0
        )
    }

    return result
}

/**
 * Calculate potential winnings for fixed odds multiple bet
 */
export function getPotentialWinningsForMultiBet(
    isEachWay: boolean,
    odds: number | undefined,
    unit: number,
    oddsPlc: number | undefined
): number {
    if (odds && unit) {
        const result = Math.round(unit * odds * 100) / 100

        if (isEachWay && oddsPlc) {
            return result + Math.round(unit * oddsPlc * 100) / 100
        }

        if (!isEachWay) {
            return result
        }
    }
    return 0
}

export function areAllBetsInMultiplesFixed(bets: OBR.Betting.Bet[]): boolean {
    if (!bets.length) return false
    return !bets.some((bet) => {
        return bet.in_multiples && bet.category_multi !== BetCategory.FIXED
    })
}

// checks if all bets with set stake are fixed. Bets with no stake will not be sent to backend
export function areAllBetsFixed(bets: OBR.Betting.Bet[]): boolean {
    if (!bets.length) return false
    return !bets
        .filter((bet) => {
            return bet.unit_stake
        })
        .some((bet) => {
            return bet.category !== BetCategory.FIXED
        })
}

// Returns bet title for single bet header component depending on special type
export function getBetTitle(
    bet: OBR.Betting.Bet,
    race: OBR.Race.Race,
    runner: OBR.Race.Runner,
    specialType: BetSpecialType,
    eventTitle: string,
    isEgtTot?: boolean
): { type: string; firstRow: string; secondRow: string; thirdRow: string } {
    const { t } = i18n.global
    const betType = bet.is_each_way ? BetType.EACHWAY : bet.market
    const now = new Date()
    const raceTime = new Date(race.post_time * 1000)
    const date = formatDate(
        new Date(race.post_time * 1000),
        compareDates(raceTime, now)
            ? FD_HOURS_AND_MINUTES
            : FD_LOCALIZED_DATE_SHORT
    )

    let type: string = ''
    let firstRow: string = ''
    let secondRow: string = ''
    let thirdRow: string = `${date} ${eventTitle}`

    if (specialType === BetSpecialType.H2H) {
        type = t('generic.label_h2h_short')
        firstRow = `${runner.name} (${runner.program_number_parent})`

        secondRow = race.runners
            .map((item) => {
                return item
            })
            .filter((item) => {
                return item.id !== runner.id
            })
            .map((item) => {
                return `${item.name} (${item.program_number_parent})`
            })
            .join(' & ')

        secondRow = `${t(`generic.label_head_to_head_vs`)} ${secondRow}`
    } else if (specialType === BetSpecialType.SPECIAL) {
        type = t('generic.label_special')
        firstRow = race.specials_title
        secondRow = runner.name
    } else if (specialType === BetSpecialType.EXOTIC) {
        type = t(getMarketLabel(betType, race.country, race.bet_types))
        firstRow = `${date} ${eventTitle}`
        secondRow =
            '<div class="leading-4 truncate">' +
            Object.entries(bet.selections[0].columns)
                .map((entry) => {
                    return `${t(
                        entry[0] === MarksType.COMBINATION
                            ? 'betting.label_betslip_c'
                            : convertArabicToRoman(Number(entry[0]))
                    )}: ${
                        entry[1].runners.length === race.runners.length
                            ? t('betslip.label_all')
                            : entry[1].runners
                                  .map((runner) => {
                                      return `${runner.program_number}`
                                  })
                                  .join(', ')
                    }`
                })
                .join(' <br> ') +
            '</div>'
        thirdRow = ''
    } else if (specialType === BetSpecialType.NORMAL) {
        type = t(
            getMarketLabel(betType, race.country, race.bet_types, isEgtTot)
        )
        firstRow = `${runner.name} (${runner.program_number})`
    }

    return {
        type,
        firstRow,
        secondRow,
        thirdRow,
    }
}

export function stringifyStake(input: number | undefined): string {
    return input && input > 0 ? `${input}` : ''
}

export function getDisplayStake(
    stake: string,
    currency: OBR.User.Currency
): string {
    return Number(stake)
        ? formatToLocaleNumberWithCurrency(Number(stake), currency, true)
        : ''
}

/**
 * Check if any RMS acceptance is needed
 */
export function isRmsAcceptanceNeeded(
    bets: OBR.Betting.Bet[],
    multiples: OBR.Betting.Multiple[]
) {
    const inSingles = bets.reduce((res: boolean, bet: OBR.Betting.Bet) => {
        return bet.rms_response?.accepted === false ? true : res
    }, false)

    const inMultiples = multiples.reduce(
        (res: boolean, bet: OBR.Betting.Multiple) => {
            return bet.rms_response?.accepted === false ? true : res
        },
        false
    )

    return inSingles || inMultiples
}

/**
 * Returns bet part object extended with rms data
 */
export function getRmsMessage(rmsObj: OBR.Betting.RmsResponse | undefined) {
    const { t } = useI18n({ useScope: 'global' })

    switch (rmsObj?.type) {
        case RmsError.LIMIT:
            return t('betslip.msg_new_unit_stake', {
                newUnitStake: rmsObj.new_unit_stake,
            })
        case RmsError.SP_ONLY:
            return t('betslip.error_cannot_accept_FXD')
        case RmsError.WIN_ONLY:
            return t('betslip.error_accept_win_only')
        default:
            return ''
    }
}

/**
 * Get bet index in betslip
 * getBetIndexInBetslip is index of bet in array off all bets (single and multi)
 * that we sent to backend, we always send all multiples but single bets only with unit stake
 * getBetIndexInBetslip from response is not index in betslip
 */
export function getBetIndexInBetslip(
    singles: OBR.Betting.Bet[],
    betIndexFromError: number
) {
    let a = 0
    let newIndex = -1

    singles.forEach((bet, index) => {
        if (bet.unit_stake) {
            if (a === betIndexFromError) {
                newIndex = index
            }
            a++
        }
    })

    return newIndex
}

export function getBetAndIndexByUid(
    bets: OBR.Betting.Bet[],
    uid: string
): { bet: OBR.Betting.Bet | undefined; index: number } {
    let index = -1
    const bet = bets.find((bet, idx) => {
        if (bet.uid === uid) {
            index = idx
            return true
        }
        return false
    })

    return { bet, index }
}

/**
 * Returns single bet object extended with rms data
 */
export function getSingleBetWithRmsResponse(
    bet: OBR.Betting.Bet,
    idRms: number,
    type: OBR.Betting.RmsError,
    newUnitStake?: number
): OBR.Betting.Bet {
    switch (type) {
        case RmsError.LIMIT:
            return {
                ...bet,
                id_rms: idRms,
                rms_response: {
                    accepted: false,
                    type: type,
                    new_unit_stake: newUnitStake,
                    id_rms: idRms,
                },
            }

        case RmsError.WIN_ONLY:
            return {
                ...bet,
                num_bets: bet.num_bets / 2,
                is_each_way: false,
                id_rms: idRms,
                rms_response: {
                    accepted: false,
                    type: type,
                    id_rms: idRms,
                },
            }

        case RmsError.SP_ONLY:
            return {
                ...bet,
                category: BetCategory.BOOKIE,
                id_rms: idRms,
                rms_response: {
                    accepted: false,
                    type: type,
                    id_rms: idRms,
                },
            }
    }
}

/**
 * Returns multiple bet object extended with rms data
 */
export function getMultipleBetWithRmsResponse(
    bet: OBR.Betting.Multiple,
    idRms: number,
    type: OBR.Betting.RmsError,
    newUnitStake?: number
): OBR.Betting.Multiple {
    switch (type) {
        case RmsError.LIMIT:
            return {
                ...bet,
                ...(idRms !== 0 && { id_rms: idRms }),
                rms_response: {
                    accepted: false,
                    type: type,
                    new_unit_stake: newUnitStake,
                    id_rms: idRms,
                },
            }

        case RmsError.WIN_ONLY:
            return {
                ...bet,
                num_bets: bet.num_bets / 2,
                is_each_way: false,
                ...(idRms !== 0 && { id_rms: idRms }),
                rms_response: {
                    accepted: false,
                    type: type,
                    id_rms: idRms,
                },
            }
        case RmsError.SP_ONLY:
            return {
                ...bet,
                id_rms: idRms,
                rms_response: {
                    accepted: false,
                    type: type,
                    id_rms: idRms,
                },
            }

        default:
            return bet
    }
}

/**
 * Returns array with objects with default stake number and formated string label
 * with first number on the list being minimum stake
 * e.g. [{value: 1, label: '£1'}, {value: 2, label: '£2'}]
 */
export function getDefaultStakesForPresetButtons(
    minStake: number,
    stakes: number[]
): { value: number; label: string }[] {
    if (minStake !== 0) {
        stakes = stakes.filter(function (number) {
            return number > minStake
        })
        if (stakes.length !== 5) {
            stakes.unshift(minStake)
        }

        while (5 - stakes.length > 0) {
            stakes.push(stakes[stakes.length - 1] * 2)
        }
    }

    stakes.sort((a, b) => a - b)

    stakes = stakes.slice(0, 5)
    // format displayed value with currency
    return stakes.map((item: number) => {
        return {
            value: item,
            label: formatToLocaleNumber(item, item % 1 ? true : false),
        }
    })
}

/**
 * Retrns link to race card with extended market or to special page
 */
export function getBetslipLink(
    race?: OBR.Race.Race | null,
    bet?: OBR.Betting.Bet,
    parentRace?: OBR.Race.Race | null
): OBR.Router.RouteLocationRaw {
    if (!race || !bet) return homeLink

    let market: OBR.Betting.MarketBetType = bet.market

    if (bet.special_type === BetSpecialType.SPECIAL && !race.id_race_parent) {
        if (race.id_event) {
            return getSpecialStandaloneLink(race.id_event)
        }
        return specialsLink
    } else if (bet.special_type === BetSpecialType.SPECIAL) {
        market = BETTING_MARKET_SPECIAL

        if (parentRace) {
            return getRaceCardLinkExtended(
                parentRace.id,
                parentRace.country,
                parentRace.event_title,
                parentRace.race_type,
                parentRace.number,
                {
                    market,
                }
            )
        }
    }

    if (bet.special_type === BetSpecialType.H2H) {
        market = BETTING_MARKET_H2H
    }

    return getRaceCardLinkExtended(
        race.id_race_parent || race.id,
        race.country,
        race.event_title,
        race.race_type,
        race.number,
        {
            market,
        }
    )
}

export function generateSelectionsForExoticBetsFromMarks(
    marks: OBR.Betting.Marks,
    race: OBR.Race.Race
): OBR.Betting.BetSelection[] {
    const selections = [{ id_race: race.id, columns: {} }] as any

    for (const key in marks) {
        if (!marks[key as MarksType].length) continue
        selections[0].columns[key] = {
            runners: [],
        }
        marks[key as MarksType].forEach((runner) => {
            const a = race?.runners.find((horse) => {
                return horse.program_number === runner
            })

            selections[0].columns[key].runners.push({
                program_number: runner,
                id_runner: a?.id,
            })
        })
    }

    return selections
}

export function generateMarksFromSelections(
    selections: OBR.Betting.BetSelection[]
): OBR.Betting.Marks {
    const marks = initMarks()

    selections.forEach((selection) => {
        for (const key in selection.columns) {
            const column = selection.columns[key as MarksType]
            if (!column?.runners.length) continue

            column.runners.forEach((runner: OBR.Betting.BetRunner) =>
                marks[key as MarksType].push(runner.program_number)
            )
        }
    })
    return marks
}

export function getBetNotPlacedItem(race: OBR.Race.Race, bet: OBR.Betting.Bet) {
    const unitStake = bet.unit_stake || 1
    return `<div class="text-[--sta-error]">${
        race?.event_title
    } - ${getDisplayValueForRaceNumber(race?.number)} - ${
        bet.market
    } </div><div class="text-[--sta-error]"> ${formatToLocaleNumberWithCurrency(
        unitStake * bet.num_bets,
        bet.currency,
        true
    )} (${bet.num_bets} x ${formatToLocaleNumberWithCurrency(
        unitStake,
        bet.currency,
        true
    )})</div>`
}

/**
 * Filters out exotic and special bets from the provided array of bets and returns an array of bets with unique runners.
 */
export function getSingelBetsForMultiples(bets: OBR.Betting.Bet[]) {
    // first filter out exotic bets and special bets
    const filteredBets = bets.filter((bet) =>
        [BetType.WIN, BetType.PLACE, BetType.ITA, BetType.TRITA].includes(
            bet.market
        )
    )

    // remove duplicate runners from the same race - runner 1 WIN and same runner ITA
    // in single bets tab those are 2 differend bets, in multiples we want to merge them
    const seenIds = new Set() // To keep track of seen ids
    const betsWithUniqueRunners = []
    for (const bet of filteredBets) {
        if (!seenIds.has(getBetRunnerId(bet))) {
            seenIds.add(getBetRunnerId(bet))
            betsWithUniqueRunners.push(bet)
        }
    }
    return betsWithUniqueRunners
}

/**
 *  sort bets by race and add race object to generate singles in multiples list
 */
export function getSortedSingelBetsByRace(
    bets: OBR.Betting.Bet[],
    multiBetTypeSelected: OBR.Betting.BetType
): OBR.Common.Object<OBR.Betting.SinglesGroupForMultiple> {
    return bets.reduce((acc, single: OBR.Betting.Bet) => {
        const raceId: string = getBetRaceId(single)
        const race = raceStoreService.raceByCache(raceId)

        const isSPPossible =
            race?.bet_types
                .find((x) => multiBetTypeSelected === x.bet_type)
                ?.categories.includes(BetCategory.BOOKIE) && race.multiples_bok

        const isFixedPossible =
            race?.bet_types
                .find((x) => multiBetTypeSelected === x.bet_type)
                ?.categories.includes(BetCategory.FIXED) && race.multiples_fxd

        if (!acc[raceId]) {
            acc[raceId] = {
                race: race,
                singles: [],
                isFixedAndBookieAlowed: isFixedPossible && isSPPossible,
                categoryChanged: false,
            }
        }

        if (
            single.category !== single.category_multi &&
            !(isFixedPossible && isSPPossible)
        ) {
            if (single.category_multi === BetCategory.BOOKIE) {
                acc[raceId].categoryChanged = BetCategory.BOOKIE
            } else if (single.category_multi === BetCategory.FIXED) {
                acc[raceId].categoryChanged = BetCategory.FIXED
            }
        }
        acc[raceId].singles.push(single)
        return acc
    }, {} as OBR.Common.Object<OBR.Betting.SinglesGroupForMultiple>)
}

/**
 *  Generate errors object for each bet notmal or H2H bet in singles by bet UID
 * e.g. { 'ab34': ['TOT', 'BOOKIE-NO_MULTIPLES'], 'cd78': ['BOOKIE-NO_MULTIPLES']}
 */
export function getErrorsForSinglesInMultiples(
    normalBets: OBR.Betting.Bet[],
    multiGlobalBetType: OBR.Betting.BetType
): {
    [key in string]: BetslipMultipleSingleErrors[]
} {
    const err: {
        [key in string]: BetslipMultipleSingleErrors[]
    } = {}

    //first filter only bets that are in multiples
    const betsInMultiples = normalBets.filter((bet) => bet.in_multiples)

    betsInMultiples.forEach((bet, idx, arr) => {
        err[bet.uid] = []

        const race = raceStoreService.raceByCache(getBetRaceId(bet))
        if (!race) {
            return
        }

        // if only TOT then it is imposible to add to multiples - only one error
        if (bet.category_multi === BetCategory.TOTE) {
            err[bet.uid].push(BetslipMultipleSingleErrors.TOTE)
            return
        }

        // if only BOK then it is imposible to add to multiples - only one error
        if (bet.category_multi === BetCategory.BOOKIE && !race.multiples_bok) {
            err[bet.uid].push(BetslipMultipleSingleErrors.NO_MULTIPLES_BOK)
            return
        }

        // if only FXD then it is imposible to add to multiples - only one error
        if (bet.category_multi === BetCategory.FIXED && !race.multiples_fxd) {
            err[bet.uid].push(BetslipMultipleSingleErrors.NO_MULTIPLES_FIXED)
            return
        }

        // Next step is to check conflicts between bets in multiples
        // We filter out all bets that are impossible to add to multiples
        // because we do not want to calculate any conflict with them (those above)
        const betsThatCanBeInMultiples = arr.filter((x) => {
            const race = raceStoreService.raceByCache(getBetRaceId(x))
            if (!race) {
                return false
            }
            if (x.category_multi === BetCategory.TOTE) {
                return false
            }
            if (
                (x.category_multi === BetCategory.BOOKIE &&
                    !race.multiples_bok) ||
                (x.category_multi === BetCategory.FIXED && !race.multiples_fxd)
            ) {
                return false
            }
            return true
        })

        // if this bet is antepost check if there are not antepost bets in multiples
        // never mix antepost with non antepost bets
        if (
            bet.is_ante_post &&
            betsThatCanBeInMultiples.some((oBet) => !oBet.is_ante_post)
        ) {
            err[bet.uid].push(
                BetslipMultipleSingleErrors.CONFLICT_ANTEPOST_NORMAL
            )
        }

        // if bet is non antepost check if there are antepost
        if (
            !bet.is_ante_post &&
            betsThatCanBeInMultiples.some((oBet) => oBet.is_ante_post)
        ) {
            err[bet.uid].push(
                BetslipMultipleSingleErrors.CONFLICT_NORMAL_ANTEPOST
            )
        }

        // if bet is H2H check if there are normal bets from the same race
        // never mix H2H with normal bets from the same race (H2H parent race)
        if (
            bet.special_type === BetSpecialType.H2H &&
            betsThatCanBeInMultiples.some(
                (oBet) =>
                    oBet.special_type !== BetSpecialType.H2H &&
                    getBetRaceId(oBet) ===
                        raceStoreService.raceByCache(getBetRaceId(bet))
                            ?.id_race_parent
            )
        ) {
            err[bet.uid].push(BetslipMultipleSingleErrors.CONFLICT_H2H_NORMAL)
        }

        // if bet is normal check if there are H2H bets from the same race
        if (
            bet.special_type !== BetSpecialType.H2H &&
            betsThatCanBeInMultiples.some(
                (oBet) =>
                    oBet.special_type === BetSpecialType.H2H &&
                    raceStoreService.raceByCache(getBetRaceId(oBet))
                        ?.id_race_parent === getBetRaceId(bet)
            )
        ) {
            err[bet.uid].push(BetslipMultipleSingleErrors.CONFLICT_NORMAL_H2H)
        }

        // check if the race on which bet is on has this bet type available
        const objForThisBetType = race.bet_types.find((type) => {
            return type.bet_type === multiGlobalBetType
        })

        if (!objForThisBetType) {
            err[bet.uid].push(BetslipMultipleSingleErrors.BET_TYPE_MISMATCH)
        }
        const runner = raceStoreService.runnerByCache(getBetRunnerId(bet))
        if (
            arr.some((thisBet) => {
                const thisRunner = raceStoreService.runnerByCache(
                    getBetRunnerId(thisBet)
                )
                return (
                    runner?.id !== thisRunner?.id &&
                    runner?.id_subject === thisRunner?.id_subject &&
                    runner?.id_subject !== '0'
                )
            })
        ) {
            err[bet.uid].push(BetslipMultipleSingleErrors.CONFLICT_SAME_RUNNER)
        }
    })
    return err
}

export function validateBetsFromLocalStorage(payload: {
    betslip: OBR.Betting.BetslipPayload
    races: OBR.Race.Race[]
}) {
    function restartPickBet() {
        delete payload.betslip.pickBets
        delete payload.betslip.activePickBet
        removeItem(BETSLIP_STORAGE_KEY_PICKBETS)
        removeItem(BETSLIP_STORAGE_KEY_PICKBETS_ID)
    }
    function resetBetslip() {
        payload.betslip.multiples = []
        payload.betslip.bets = []
        payload.races = []
        removeItem(BETSLIP_STORAGE_KEY_BETS)
        removeItem(BETSLIP_STORAGE_KEY_RACES)
    }
    try {
        if (
            !(payload.betslip.pickBets && payload.betslip.activePickBet) &&
            !payload.betslip.bets.length
        ) {
            restartPickBet()
            resetBetslip()
            return payload
        }

        if (payload?.races?.length) {
            payload.races = payload.races.filter((race) => {
                if (
                    new Date().getTime() <
                    race.post_time * 1000 + 2 * DT_ONE_HOUR
                ) {
                    return true
                } else {
                    payload.betslip.bets = payload.betslip.bets.filter(
                        (bet) => getBetRaceId(bet) !== race.id
                    )
                    return false
                }
            })

            if (!payload.betslip.bets.length) {
                payload.betslip.multiples = []
            }
        } else {
            resetBetslip()
        }

        if (payload.betslip.pickBets && payload.betslip.activePickBet) {
            const pickbet = Object.values(payload.betslip.pickBets)[0]
            if (pickbet) {
                if (
                    new Date().getTime() >
                    Object.values(pickbet)[0].post_time * 1000 + 2 * DT_ONE_HOUR
                ) {
                    restartPickBet()
                }
            } else {
                restartPickBet()
            }
        } else {
            restartPickBet()
        }
    } catch (e) {
        restartPickBet()
        resetBetslip()
    }

    return payload
}
/**
 * Checks if a bet change should be accepted based on the bet category, user settings and comparison new and old odds.
 */
export function shouldBetChangeBeAccepted(
    betCategory: OBR.Betting.BetCategory,
    newValue: number,
    oldValue: number,
    userSetting: AcceptBetsChanges
) {
    if (betCategory === BetCategory.FIXED) {
        if (
            userSetting === AcceptBetsChanges.NO ||
            (userSetting === AcceptBetsChanges.BIGGER && newValue < oldValue)
        ) {
            return true
        }
    }
    return false
}

/**
 * Returns the column key for a given single bet type.
 *
 */
export function getColumnKeyForSingleBet(
    betType: OBR.Betting.BetType
): MarksType {
    switch (betType) {
        case BetType.WIN:
        case BetType.PLACE:
        case BetType.EACHWAY:
        case BetType.SHOW:
        case BetType.PLACE_SHOW:
        case BetType.WIN_PLACE_SHOW:
            return MarksType.FIRST
        case BetType.ITA:
            return MarksType.SECOND
        case BetType.TRITA:
            return MarksType.THIRD
        default:
            return MarksType.FIRST
    }
}

/**
 * Generates selections for a single bet (old marks).
 */
export function generateSelectionsForSingleBet(
    idRace: string,
    runner: OBR.Race.Runner,
    market: OBR.Betting.BetType
): OBR.Betting.BetSelection[] {
    const columnKey = getColumnKeyForSingleBet(market)
    return [
        {
            id_race: idRace,
            columns: {
                [columnKey]: {
                    runners: [
                        {
                            id_runner: runner.id,
                            program_number: runner.program_number,
                            odds_fxw: runner.odds_fxw || 0,
                            odds_fxp: runner.odds_fxp || 0,
                        },
                    ],
                },
            },
        },
    ]
}

/**
 * Returns predefined list of all posible bet types for multiple bets
 */
export function getMultipleBetTypes(multiBetTypeSelected: OBR.Betting.BetType) {
    const { t } = i18n.global
    return [BetType.WIN, BetType.PLACE, BetType.ITA, BetType.TRITA].map(
        (betType) => {
            return {
                value: betType,
                label: t(`betting.label_bet_type_${betType.toUpperCase()}`),
                isHidden: multiBetTypeSelected === betType,
            }
        }
    )
}

/**
 * It selects the DOM element with the given betID and the footer element. If both elements exist,
 * it waits for 500 milliseconds for keybord to be open and then checks if the bottom of the betElement is below the top of the footer.
 * If it is, it selects the BETSLIP_DRAWER_ID element and scrolls it so that the whote bet element is visible above the footer.
 */
export function positionBet(
    betID: string,
    isB2B: boolean,
    timeout: number = 500,
    scrolDown: boolean = false,
    isMobile: boolean = false
) {
    const betElement = OBRDocument().querySelector(`#${betID}`)
    const footer = OBRDocument().querySelector(`#${BETSLIP_FOOTER_ID}`)

    let drawer
    if (betElement && footer) {
        setTimeout(() => {
            const betBottom = betElement.getBoundingClientRect().bottom
            const betTop = betElement.getBoundingClientRect().top
            const footerTop = footer.getBoundingClientRect().top
            drawer = isMobile
                ? window
                : OBRDocument().querySelector(`#${BETSLIP_DRAWER_ID}`)
            if (!drawer) return
            if (betBottom > footerTop) {
                if (drawer) {
                    drawer.scrollBy({
                        top: betBottom - footerTop,
                        left: 0,
                        behavior: 'smooth',
                    })
                }
            } else if (betBottom > window.innerHeight) {
                window.scrollBy({
                    top: betBottom - window.innerHeight,
                    left: 0,
                    behavior: 'smooth',
                })
                // @ts-ignore
            } else if (betTop < drawer.scrollTop && scrolDown) {
                drawer.scrollBy({
                    top: betTop - 64,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        }, timeout)
    }
    return drawer
}

// Check if there are any error messages for singles and for multiples
export function hasErrorMessages(betErrorMessages: Record<string, string>) {
    return Object.keys(betErrorMessages).reduce(
        (acc, key) => {
            if (key.includes(BETSLIP_MULTI_ERR_PREFIX)) {
                acc.multiple = true
            } else {
                acc.single = true
            }
            return acc
        },
        {
            single: false,
            multiple: false,
        }
    )
}
