/**
 * Route Home
 */
export const ROUTE_HOME: string = 'home'

/**
 * Route Bonuses
 */
export const ROUTE_BONUSES: string = 'bonuses'

/**
 * Route Bonuses Pending
 */
export const ROUTE_BONUSES_PENDING: string = 'bonuses-pending'

/**
 * Route Bonuses Freebets
 */
export const ROUTE_BONUSES_FREEBETS: string = 'bonuses-freebets'

/**
 * Route Bonuses History
 */
export const ROUTE_BONUSES_HISTORY: string = 'bonuses-history'

/**
 * Route My Bets
 */
export const ROUTE_MY_BETS: string = 'my-bets'

/**
 * Route My Bets recent
 */
export const ROUTE_MY_BETS_RECENT: string = 'my-bets-recent'

/**
 * Route My Bets open
 */
export const ROUTE_MY_BETS_OPEN: string = 'my-bets-open'

/**
 * Route My Bets settled
 */
export const ROUTE_MY_BETS_SETTLED: string = 'my-bets-settled'

/**
 * Route My Bets ante post
 */
export const ROUTE_MY_BETS_ANTE_POST: string = 'my-bets-ante-post'

/**
 * Route My Bets advanced
 */
export const ROUTE_MY_BETS_ADVANCED: string = 'my-bets-advanced'

/**
 * Route My Bets search
 */
export const ROUTE_MY_BETS_SEARCH: string = 'my-bets-search'

/**
 * Formguide
 */
export const ROUTE_FORMGUIDE: string = 'formguide'

/**
 * Formguide Overview
 */
export const ROUTE_FORMGUIDE_OVERVIEW: string = 'overview'

/**
 * Formguide Form
 */
export const ROUTE_FORMGUIDE_FORM: string = 'form'

/**
 * Formguide Stats
 */
export const ROUTE_FORMGUIDE_STATISTICS: string = 'statistics'

/**
 * Formguide Duels
 */
export const ROUTE_FORMGUIDE_DUELS: string = 'duels'

/**
 * Formguide Antepost
 */
export const ROUTE_FORMGUIDE_ANTEPOST: string = 'antepost'

/**
 * Formguide Pedigree
 */
export const ROUTE_FORMGUIDE_PEDIGREE: string = 'pedigree'

/**
 * Formguide Note
 */
export const ROUTE_FORMGUIDE_NOTES: string = 'notes'

/**
 * Route Event Card
 */
export const ROUTE_EVENT_CARD: string = 'event-card'

/**
 * Route Event Card Extended
 */
export const ROUTE_EVENT_CARD_EXTENDED: string = 'event-card-extended'

/**
 * Route Query available for Race Card
 */
export enum QUERY_EVENT_CARD_TABS {
    H2H = 'h2h',
    PICKBETS = 'pickbets',
    RACES = 'races',
    SPECIALS = 'specials',
}

/**
 * Route Query key for Event Card
 */
export const QUERY_EVENT_CARD_TAB_KEY: string = 'tab'

/**
 * Route Specials in Event Card
 */
export const ROUTE_EVENT_CARD_SPECIALS: string = 'event-card-specials'

/**
 * Route Specials in Event Card
 */
export const ROUTE_EVENT_SPECIAL: string = 'special'

/**
 * Route Pickbets Detail
 */
export const ROUTE_EVENT_CARD_PICKBETS_DETAILS: string = 'pickbets-detail'

/**
 * Route Calendar
 */
export const ROUTE_CALENDAR: string = 'calendar'

/**
 * Route next H2H page
 */
export const ROUTE_NEXT_H2H: string = 'nextH2h'

/**
 * Route Highlights
 */
export const ROUTE_HIGHLIGHTS: string = 'highlights'

/**
 * Route horsesAbroad page
 */
export const ROUTE_HORSES_ABROAD: string = 'horses-abroad'

/**
 * Query string horsesAbroad page
 */
export const ROUTE_HORSES_ABROAD_COUNTRY: string = 'country'
export const ROUTE_HORSES_ABROAD_RACETYPE: string = 'race-type'
export const ROUTE_HORSES_ABROAD_DATE: string = 'date'

/**
 * Route Jackpots
 */
export const ROUTE_JACKPOTS: string = 'jackpots'

/**
 * Route Favourites
 */
export const ROUTE_FAVOURITES: string = 'favourites'

/**
 * Route Favourites
 */
export const ROUTE_LOGIN: string = 'login'

/**
 * Route Race Card
 */
export const ROUTE_RACE_CARD: string = 'race-card'
export const ROUTE_RACE_CARD_EXTENDED: string = 'race-card-extended'

/**
 * Route specials
 */
export const ROUTE_SPECIALS: string = 'specials'

/**
 * Route special
 */
export const ROUTE_SPECIAL: string = 'special'

/**
 * Route Query available for Race Card
 */
export const enum QUERY_RACE_CARD {
    MARKET = 'market',
}

/**
 * Route Query values for Drawers positions
 */
export enum QUERY_DRAWER {
    MEETINGS = 'meetings',
    BETSLIP = 'betslip',
    MENU = 'menu',
}

/**
 * Route Query available for Pickbets Card
 */
export const enum QUERY_PICKBETS_CARD {
    LEG = 'leg',
}

/**
 * Route Best backed
 */
export const ROUTE_BEST_BACKED: string = 'best-backed'

/**
 * Route Market movers
 */
export const ROUTE_MARKET_MOVERS: string = 'market-movers'

/**
 * Route default domain
 */
export const ROUTE_HORSE_RACING: string = 'horse-racing'

/**
 * Route Settings
 */
export const ROUTE_SETTINGS: string = 'settings'

/**
 * Route Setting Basic
 */
export const ROUTE_SETTINGS_GENERAL: string = 'settings-basic'

/**
 * Route Setting Marketing
 */
export const ROUTE_SETTINGS_MARKETING: string = 'settings-marketing'

/**
 * Route Setting Marketing
 */
export const ROUTE_SETTINGS_COOKIES: string = 'settings-cookies'

/**
 * Route Setting Betting
 */
export const ROUTE_SETTINGS_BETTING: string = 'settings-betting'

/**
 * Route Setting Country
 */
export const ROUTE_SETTINGS_COUNTRY: string = 'settings-country'

/**
 * Route Preferred Stakes
 */
export const ROUTE_SETTINGS_PREFERRED_STAKES: string = 'preferred-stakes'

/**
 * Route page top trainers
 */
export const ROUTE_TOP_TRAINERS: string = 'top-trainers'

/**
 * Route page top jockeys
 */
export const ROUTE_TOP_JOCKEYS: string = 'top-jockeys'

/**
 * Route page tips
 */
export const ROUTE_TIPS: string = 'tips'

/**
 * Route page tips gallop
 */
export const ROUTE_TIPS_GALLOP: string = 'tips-gallop'

/**
 * Route page tips trot
 */
export const ROUTE_TIPS_TROT: string = 'tips-trot'

/**
 * Route page search
 */
export const ROUTE_SEARCH: string = 'search'

/**
 * Route page not found
 */
export const ROUTE_NOT_FOUND: string = 'not-found'

/**
 * Route page today's runners
 */
export const ROUTE_TODAYS_RUNNERS: string = 'todays-runners'

/**
 *  Route page today's runners horsesß
 */
export const ROUTE_TODAYS_RUNNERS_HORSES: string = 'horses'

/**
 *  Route page today's runners jockeys
 */
export const ROUTE_TODAYS_RUNNERS_JOCKEYS: string = 'jockeys'

/**
 *  Route page today's runners trainsers
 */
export const ROUTE_TODAYS_RUNNERS_TRAINERS: string = 'trainers'

/**
 *  Route page today's runners post timers
 */
export const ROUTE_TODAYS_RUNNERS_POST_TIMES: string = 'post-times'

/**
 *  Route page today's runners non runners
 */
export const ROUTE_TODAYS_RUNNERS_NON_RUNNERS: string = 'non-runners'

/**
 *  Route page offers
 */
export const ROUTE_OFFERS: string = 'offers'

/**
 *  Media popup
 */
export const ROUTE_MEDIA_POPUP: string = 'media_popup'

/**
 *  Route page for opening livestreams from outsite of horse-racing
 */
export const ROUTE_LIVESTREAMS: string = 'livestreams'

/**
 * Used as a pseudo route to trigger redirect for legacy urls.
 */
export const ROUTE_LEGACY_REDIRECT: string = 'redirect'
