/**
 * Logs post messages details in console - used only in develop mode
 */
export function postMessageLogger(event: MessageEvent): void {
    const timestamp = new Date()
    const timestampFormatted = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}.${timestamp.getMilliseconds()}`
    window.console.groupCollapsed(
        `postmessage received @ ${timestampFormatted}`
    )
    window.console.log(
        '%c origin',
        'font-weight:bold;color:#9E9E9E',
        event.origin
    )
    window.console.log(
        '%c messageId',
        'font-weight:bold;color:#9E9E9E',
        event.data.messageId
    )
    window.console.log(
        '%c payload',
        'font-weight:bold;color:#9E9E9E',
        event.data.payload
    )
    window.console.groupEnd()
}
