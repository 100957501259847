import { errorLogger } from '@obr-core/services/ErrorLogger'
import { isWebComponent } from '@obr-core/helpers/app.helpers'

export function isServer() {
    return !(typeof window != 'undefined' && window.document)
}

/**
 * Loads a JavaScript file
 */
export function loadScript(
    src: string,
    attributes: OBR.Common.Object<string>[] = []
) {
    return new Promise((resolve, reject) => {
        const script: HTMLScriptElement = document.createElement('script')
        script.type = 'text/javascript'
        script.onload = resolve
        script.onerror = reject
        script.src = src

        attributes.forEach(({ key, value }) => {
            script.setAttribute(key, value)
        })

        // Debugging in prod - log only if the language is not 'PT'
        // Find if the 'data-lang' contains any other value than 'pt'
        const notPT = attributes.find(
            (item) =>
                item.key === 'data-lang' && item.value?.toUpperCase() !== 'PT'
        )
        if (notPT) {
            errorLogger.createMessage(
                `The data-lang attribute for the DFS widget is "${notPT?.value}" (inside loadScript)`,
                {
                    attributes,
                    script,
                }
            )
        }

        document.head.append(script)
    })
}

// Conditionally insert elements into array
export function insertInArrIf(condition: any, elements: any[]): any[] {
    return condition ? elements : []
}

// Conditionally insert elements into object
export function insertInObjIf(condition: any, elements: any): any {
    return condition ? elements : {}
}

// Convert arabic numbers to (min: 1, max: 10)
export function convertArabicToRoman(arabic: number): string {
    if (arabic < 0 || arabic > 10) return ''
    return ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'][
        arabic - 1
    ]
}

/**
 * returns the current timestamp
 */
export function getTimestamp(): number {
    return Math.floor(new Date().getTime() / 1000)
}

/**
 * return tuple right position for sticky column in table
 */
export function calculateStickyCol(
    colStickies: NodeListOf<Element>,
    stickyAttr = 'data-sticky'
): string[] {
    const stickyRight: string[] = []
    if (colStickies) {
        let _right, _indexSticky
        for (let i = colStickies.length - 1; i >= 0; i--) {
            const currentCol = colStickies[i] as HTMLElement
            // calculate right position
            _right = 0
            if (currentCol.nextElementSibling) {
                const nextElement = colStickies[i]
                    .nextElementSibling as HTMLElement
                _right += nextElement.offsetWidth
                if (nextElement.style.right) {
                    _right += parseInt(nextElement.style.right)
                }
            }
            currentCol.style.right = `${_right}px`
            _indexSticky = currentCol.getAttribute(stickyAttr)
            if (_indexSticky) {
                stickyRight[+_indexSticky] = currentCol.style.right
            }
        }
    }
    return stickyRight
}

/**
 * get percentage value
 * @param partialValue
 * @param totalValue
 * @returns
 */
export function getPercentage(partialValue: number = 0, totalValue: number) {
    if (totalValue > 0) {
        return (100 * partialValue) / totalValue
    }
    return -1
}

/**
 * Sleep should be used in async function when we want to delay next line execution
 *
 * e.g. delay stepTwo function for 1 sec
 * - await stepOne();
 * - await sleep(1000);
 * - await stepTwo();
 */
export function sleep(ms: number = 1000) {
    return new Promise((r) => setTimeout(r, ms))
}

/**
 * Get document or shadowRoot if the application is a webcomponent. Used instead of
 * document selector to query for elements.
 *
 * @returns Document or ShadowRoot
 */
export function OBRDocument(): Document | ShadowRoot {
    if (isWebComponent()) {
        const horseRacingTag = document.getElementsByTagName(
            import.meta.env.VUE_APP_WEBCOMPONENT_TAG_NAME
        )[0]

        if (horseRacingTag) {
            return horseRacingTag.shadowRoot
        }
        return document
    } else {
        return document
    }
}
