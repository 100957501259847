import { http } from '@obr-core/lib/http'
import { HTTP_REST_V2 } from '@obr-core/config/http'
import { RaceStatus } from '@obr-core/config/race'
import { extendRunnersH2HTags } from '@obr-core/helpers/race.helpers'
import { onGetRaceByIdErrors } from '@obr-core/errors/races.errors'
import { BetType, BetCategory, BetSpecialType } from '@obr-core/config/betting'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { AxiosRequestConfig } from 'axios'

export class RaceResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/races`

    /**
     * Get race by id for Race Card page
     */
    public async getRaceById(id: string): Promise<OBR.Race.Race | null> {
        try {
            const { data } = await http.get(`${this.apiPath}/${id}`)

            return parseRace(data)
        } catch (error: any) {
            onGetRaceByIdErrors(error)
            errorLogger.createException(error)
        }

        return null
    }

    /**
     * Upcoming races for upcoming races widget
     */
    public async getUpcomingRaces(): Promise<
        OBR.Race.UpcomingRacesResponse | undefined
    > {
        try {
            // Define the headers
            const url = `${this.apiPath}/next`
            const response = await http.get<OBR.Race.UpcomingRacesResponse>(
                url,
                this.getNoAuthConfig()
            )

            return response.data
        } catch (e) {
            errorLogger.createException(e)
        }
    }

    /**
     * Jackpots for "Jackpots Races" page
     */
    public async getJackpots(): Promise<OBR.Race.JackpotsResponse | undefined> {
        try {
            const url = `${this.apiPath}/jackpots`
            const response = await http.get<OBR.Race.JackpotsResponse>(
                url,
                this.getNoAuthConfig()
            )

            return response.data
        } catch (e) {
            errorLogger.createException(e)
        }
    }

    /**
     * Best backed for "Best backed" page
     */
    public async getBestBacked(): Promise<
        OBR.Race.BestBackedResponse | undefined
    > {
        try {
            const url = `${this.apiPath}/bestbacked`
            const response = await http.get<OBR.Race.BestBackedResponse>(
                url,
                this.getNoAuthConfig()
            )
            return response.data
        } catch (e) {
            errorLogger.createException(e)
        }
    }

    /**
     * "H2H races" per race - by parent race id
     */
    public getRacesH2HPerRace(idRace: string): Promise<OBR.Race.Race[]> {
        return http
            .get<{ races: OBR.Race.Race[]; event: OBR.Events.Event }>(
                `${this.apiPath}/h2h/${idRace}`,
                this.getNoAuthConfig()
            )
            .then((response) =>
                parseRacesH2H(response.data.races, response.data.event)
            )
    }

    /**
     * Upcoming for "H2H races" widget|page
     */
    public getRacesH2HRaces(openOnly: boolean): Promise<OBR.Race.Race[]> {
        return http
            .get<OBR.Race.H2hResponse>(
                `${this.apiPath}/h2h${openOnly ? '?filter_type=open' : ''}`,
                this.getNoAuthConfig()
            )
            .then((response) =>
                parseRacesH2H(response.data.races, response.data.event)
            )
    }

    /**
     * Ante-post Market Movers widget|page
     */
    public async getMarketMovers() {
        try {
            const url = `${this.apiPath}/marketmovers`
            const response = await http.get<OBR.Race.MarketMoversResponse>(
                url,
                this.getNoAuthConfig()
            )
            return response.data
        } catch (e) {
            errorLogger.createException(e)
        }
    }

    public async getHighlights(): Promise<
        OBR.Generic.HighlightsResponse | undefined
    > {
        try {
            const url = `${this.apiPath}/highlights`
            const response = await http.get<OBR.Generic.HighlightsResponse>(
                url,
                this.getNoAuthConfig()
            )

            return response.data
        } catch (e) {
            errorLogger.createException(e)
        }
    }

    private getNoAuthConfig(): AxiosRequestConfig {
        return {
            headers: {
                'x-no-auth': 'true',
            },
        }
    }
}

// This is not a place for parser
export function parseRace(data: any): OBR.Race.Race {
    if (data.race.stables) {
        data.race.stables.forEach((stable: string[]) => {
            let smallestProgramNumber = Infinity

            const set = data.runners.filter((runner: OBR.Race.Runner) => {
                if (stable.includes(runner.id)) {
                    smallestProgramNumber = Math.min(
                        runner.program_number,
                        smallestProgramNumber
                    )
                    return true
                }
                return false
            })

            set.forEach(
                (runner: OBR.Race.Runner) =>
                    (runner.stable_program_number = smallestProgramNumber)
            )
        })
    }

    const {
        race,
        runners,
        media,
        bet_types,
        final_odds,
        final_positions,
        pick_bet_types,
        sibling_races,
        banners,
        other_odds,
    } = data

    race.special_type = BetSpecialType.NORMAL

    return {
        ...race,
        bet_types,
        final_odds,
        final_positions,
        pick_bet_types,
        sibling_races,
        other_odds,
        media: Array.isArray(media) ? null : media,
        status: race.status as RaceStatus,
        runners: runners ? extendRunnersH2HTags(runners) : [],
        banner: banners?.[0],
    }
}

// This is not a place for parser
export function parseRacesH2H(
    races: OBR.Race.Race[],
    event: OBR.Events.Event
): OBR.Race.Race[] {
    return races.map((race: OBR.Race.Race) => {
        race.bet_types = [
            {
                bet_type: BetType.WIN,
                categories: [BetCategory.FIXED],
            },
        ]

        race.multiples_bok = false
        race.multiples_fxd = event?.multiples_fxd
        race.special_type = BetSpecialType.H2H

        race.runners = race.runners.map((runner: OBR.Race.Runner) => {
            runner.odds_fxp = 0
            runner.odds_prc = 0

            return runner
        })
        return race
    })
}
