import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import {
    LOGGER_IGNORE_ERRORS,
    LOGGER_IGNORE_URLS,
    LOGGER_SENTRY_DSN,
    LOGGER_SENTRY_RELEASE,
    LOGGER_SENTRY_IS_ENABLED,
    LOGGER_TAG_BRAND,
} from '@obr-core/config/error-logger'
import { Brand, ENV_BRAND, ENV_RB } from '@obr-core/config/env'

/**
 * Error Logger
 *
 */
class ErrorLogger {
    /**
     * Initialize (Sentry)
     */
    public initialize({ app, router }: OBR.ErrorLogger.InitOptions): void {
        Sentry.init({
            app,
            debug: !LOGGER_SENTRY_IS_ENABLED,
            enabled: LOGGER_SENTRY_IS_ENABLED,
            dsn: LOGGER_SENTRY_DSN,
            environment: ENV_RB,
            ignoreErrors: LOGGER_IGNORE_ERRORS,
            denyUrls: LOGGER_IGNORE_URLS,
            release: LOGGER_SENTRY_RELEASE,
            beforeSend(event) {
                const host = new URL(import.meta.url).hostname
                const filteredEvents: Sentry.Exception[] | undefined =
                    event.exception?.values?.reduce<Sentry.Exception[]>(
                        (sum, v) => {
                            if (
                                v.stacktrace?.frames?.filter((f) => {
                                    let fileHost = ''

                                    try {
                                        fileHost = new URL(f.filename!).hostname
                                    } catch (e) {
                                        return false
                                    }

                                    return fileHost === host
                                }).length
                            ) {
                                sum.push(v)
                            }
                            return sum
                        },
                        []
                    )

                if (filteredEvents?.length) {
                    return event
                }

                return null
            },
            integrations: [
                new BrowserTracing({
                    routingInstrumentation:
                        Sentry.vueRouterInstrumentation(router),
                }),
            ],
        })

        // Set brand tag
        let brand = ENV_BRAND
        if (document.obrConfig && document.obrConfig.brand) {
            brand = document.obrConfig.brand as Brand
        }
        Sentry.configureScope((scope) => scope.setTag(LOGGER_TAG_BRAND, brand))
    }

    /**
     * Captures an exception event and sends it to Sentry.
     */
    public createException(
        exception: unknown,
        extras?: OBR.ErrorLogger.Extras
    ) {
        if (extras) {
            Sentry.withScope((scope: OBR.ErrorLogger.Scope) => {
                scope.setExtras(extras)
                Sentry.captureException(exception)
            })
        } else {
            Sentry.captureException(exception)
        }
    }

    /**
     * Captures a message event and sends it to (Sentry)
     */
    public createMessage(message: string, extras?: OBR.ErrorLogger.Extras) {
        if (extras) {
            Sentry.withScope((scope: OBR.ErrorLogger.Scope) => {
                scope.setExtras(extras)
                Sentry.captureMessage(message)
            })
        } else {
            Sentry.captureMessage(message)
        }
    }

    /**
     * Breadcrumbs will be added to subsequent events to provide more context on user's actions prior to an error or crash
     */
    public addBreadcrumb(
        category: string,
        message: string,
        level: OBR.ErrorLogger.SentrySeverity = 'info'
    ) {
        Sentry.addBreadcrumb({ category, message, level })
    }
}

// Export only as instance
export const errorLogger = new ErrorLogger()
