import io from 'socket.io-client'
import {
    WS_MAX_RECONNECT_ATTEMPTS,
    WS_RECONNECT_DELAY,
    WS_SECURE_PROTOCOL,
    WS_TRANSPORTS,
    WS_HOST,
    WS_PORT,
} from '@obr-core/config/web-sockets'
import { ENV_BRAND } from '@obr-core/config/env'
import { isStandalone } from '@obr-core/helpers/app.helpers'
import { appStoreService } from '@obr-core/services/store/AppStoreService'

/**
 * Socket instance
 */
let socket: OBR.WebSockets.Socket

/**
 * Init
 *
 * i.e. initialize websockets
 */
export function init(): OBR.WebSockets.Socket {
    if (!isSocketInitialized()) {
        socket = io(getURL(), {
            transports: WS_TRANSPORTS,
            reconnection: true,
            reconnectionDelay: WS_RECONNECT_DELAY,
            reconnectionAttempts: WS_MAX_RECONNECT_ATTEMPTS,
        })
    }

    return getConnection()
}

/**
 * Bind a handle for given socket event
 *
 * i.e. add new event listener
 */
export function on(event: string, cb: OBR.WebSockets.Callback) {
    return socket.on(event, cb)
}

/**
 * Emit a socket event
 *
 * i.e. if socket are initialized emit event
 */
export function emit(event: string, args?: any) {
    if (isSocketInitialized()) {
        socket.emit(event, args)
    }
}

/**
 * Checks if socket is initialized
 *
 * i.e. returns true if socket has been initialized
 */
function isSocketInitialized() {
    return typeof socket !== 'undefined'
}

/**
 * Construct connection URL
 *
 * i.e. get websockets url string
 */
export function getURL(): string {
    const path = isStandalone()
        ? window.location.hostname
        : new URL(import.meta.url).hostname
    const HOST = WS_HOST === 'hostname' ? path : WS_HOST
    const BRAND = appStoreService.brandChild() || ENV_BRAND

    return `${WS_SECURE_PROTOCOL}${HOST}:${WS_PORT}/${BRAND}`
}

/**
 * Get websocket connection
 *
 * i.e. returns socket connection instance
 */
function getConnection(): OBR.WebSockets.Socket {
    return socket
}
