/**
 * Limited access status
 */
export const USER_DEFAULT_LIMITED_STATUS: OBR.User.LimitedStatus = 'inactive'

/**
 * Media country
 */
export const USER_DEFAULT_MEDIA_COUNTRY: OBR.Settings.Country = 'DE'

/**
 * Me request error state
 */
export const USER_GET_ME_ERROR: string = 'USER_GET_ME_ERROR'

/**
 * Enum of all the valid user settings
 */
export enum UserSettings {
    BETSLIP_CONFIRMATION = 'betslip_confirmation',
    CLOSE_COUNTRIES = 'close_countries',
    COUNTRIES_ORDER = 'countries_order',
    HIDE_BALANCE = 'hide_balance',
    HIDE_DOGS = 'hide_dogs',
    ODDS_FORMAT = 'odds_format',
    TIMEZONE = 'timezone',
    UNIT_SYSTEM = 'unit_system',
    ACCEPT_ODDS = 'accept_odds',
    OPEN_BETSLIP = 'open_betslip',
    LANGUAGE = 'language',
    BRAND_CONSENT = 'brand',
    GROUP_CONSENT = 'group',
    THIRD_PARTY_CONSENT = 'thirdparty',
    EMAIL_CONSENT = 'mailings',
    SMS_CONSENT = 'sms',
    PHONE_CONSENT = 'phone',
    COOKIE_CONSENT = 'cookie',
    PREFERRED_STAKES_US = 'preferred_stakes_usd',
    PREFERRED_STAKES_SINGLE = 'preferred_stakes_single',
    PREFERRED_STAKES_MULTIPLE = 'preferred_stakes_multiple',
    PREFERRED_STAKES_EXOTIC = 'preferred_stakes_exotic',
}

export enum LimitedAccessStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}
