import { ErrorService } from '@obr-core/services/ErrorService'
import { onNotFoundError } from '@obr-core/errors/app.errors'

/**
 * Handle fetch event by id errors
 *
 * i.e. for endpoint "france/caen/trot/123"
 */
export function onGetEventByIdErrors(error: OBR.Errors.AjaxError) {
    ErrorService.getInstance().preventDefault(error)

    switch (error.response?.status) {
        case 400:
        case 404:
            onNotFoundError()
    }
}
