import { transliterate } from 'transliteration'
import { ENV_PRODUCT, Product } from '@obr-core/config/env'
import {
    ROUTER_MODE_SILENT,
    ROUTER_MODE_DEFAULT,
    RouterMode,
} from '@obr-core/config/router'
import {
    createMemoryHistory,
    createWebHashHistory,
    createWebHistory,
} from 'vue-router'

/**
 * Create Vue Router options
 * @param mode Vue Router mode
 * @param routes Vue Router array of routes config objects
 * @param base Vue router base path
 */
export function createOptions(
    mode: RouterMode = RouterMode.History,
    routes: OBR.Router.RouteRecordRaw[] = [],
    base: string = ''
): OBR.Router.RouterOptions {
    let history = createWebHistory(base)

    if (mode === RouterMode.Hash) {
        history = createWebHashHistory(base)
    } else if (mode === RouterMode.Abstract) {
        const url =
            ENV_PRODUCT === Product.MILLION_GAME ? '/milliongame/' : base
        history = createMemoryHistory(url)
    }

    return {
        history,
        routes,
        // @ts-ignore
        scrollBehavior(to, from, savedPosition) {
            // do not scroll if path is the same
            // e.g. changing market
            if (to.path === from.path) {
                return
            }
            // Retain scroll to anchor behaviour
            if (to.hash) {
                return {
                    selector: to.hash,
                }
            }
            // Returning the savedPosition will result in a native-like
            // behavior when navigating with back/forward buttons
            // See: https://router.vuejs.org/guide/advanced/scroll-behavior.html
            if (savedPosition) {
                return savedPosition
            }

            return { left: 0, top: 0 }
        },
    }
}

/**
 * Returns router mode
 * @param silent If browser URL path should change when routnig
 */
export function getMode(silent: boolean = false): RouterMode {
    if (silent) {
        return ROUTER_MODE_SILENT
    }
    return ROUTER_MODE_DEFAULT
}

/**
 * Merge route configurations
 * @param routeConfig Route configurations as arguments
 */
export function setupRoutes(
    ...routeConfig: OBR.Router.RouteRecordRaw[]
): OBR.Router.RouteRecordRaw[] {
    return [...routeConfig]
}

/**
 * Convert normal string in url string ex: United Kingdom -> united-kingdom
 * Transliterate non-latin characters to latin.
 *
 * @param value
 * @returns
 */
export function slugify(value?: string) {
    if (value) {
        // Compatibly-decompose and remove combining characters.
        value = value.normalize('NFKD').replace(/[\u0300-\u036F]/g, '')

        // Transliterate non-latin characters
        value = transliterate(value)

        // Remove all non-word characters, leaving spaces and dashes. Trim and convert to lower case.
        value = value.trim().toLowerCase()

        // Replace every special caracter to a "-" character
        value = value.replace(/[^a-z0-9\s-]+/g, '-')

        // Replace groups of spaces and dashes with a single dash.
        return value.replace(/[-\s]+/g, '-')
    }
    return ''
}
