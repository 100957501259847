import { get, set, remove } from '@obr-core/lib/cookie.manager'
import { getCookieDomain } from '@obr-core/utils/utils'
import { COOKIE_KEY_SOCKIE, COOKIE_SOCKIE_KEYS } from '@obr-core/config/cookie'
import { isServer } from '@obr-core/helpers'

/**
 * User Cookie Service
 */
class UserCookieService {
    private readonly hostName = isServer() ? location.hostname : ''
    private attributes: OBR.Cookie.Attributes = {
        secure: true,
    }

    /**
     * Get cookie value
     * @param key Cookie name
     */
    public get(key: OBR.Cookie.Key) {
        return get(key)
    }

    /**
     * Set cookie value
     * @param key Cookie name
     * @param value Cookie value
     */
    public set(
        key: OBR.Cookie.Key,
        value: OBR.Cookie.Value,
        attributes?: OBR.Cookie.Attributes
    ) {
        const { includeSubdomains } = { ...attributes }
        return set(key, value, {
            ...this.attributes,
            ...attributes,
            domain: getCookieDomain(this.hostName, includeSubdomains),
        })
    }

    /**
     * Remove a cookie
     * @param key Cookie name
     * @param applyToSubdomains Boolean apply to all subdomains
     */
    public remove(key: OBR.Cookie.Key, applyToSubdomains?: boolean) {
        return remove(key, {
            ...this.attributes,
            domain: getCookieDomain(this.hostName, applyToSubdomains),
        })
    }

    /**
     * Returns the `sockie` cookie user id to check whether user is logged in
     */
    public getSession(): null | OBR.Cookie.Sockie {
        const cookie = get(COOKIE_KEY_SOCKIE)
        if (cookie === undefined) {
            return null
        }
        const validCookie = COOKIE_SOCKIE_KEYS.every((key) => {
            return cookie[key] !== undefined
        })
        if (validCookie) {
            return cookie
        }
        return null
    }

    /**
     * remove user session
     */
    public removeSession() {
        this.remove(COOKIE_KEY_SOCKIE, true)
    }

    /**
     * Get the user id from sockie cookie
     */
    public getSessionId(): null | string {
        const session = this.getSession()
        return session ? session.userId : null
    }
}

export const userCookieService = new UserCookieService()
