import { EventResource } from '@obr-core/resources/EventResource'

/**
 * Event Service
 * Singleton
 */
export class EventService {
    private static instance: EventService
    private eventsResource: EventResource

    private constructor() {
        this.eventsResource = new EventResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): EventService {
        if (EventService.instance === undefined) {
            EventService.instance = new EventService()
        }
        return EventService.instance
    }

    /**
     * Method returns event by id
     * @param id - string
     */
    public async getEventById(id: string): Promise<OBR.Events.Event | null> {
        return this.eventsResource.getEventById(id)
    }

    /**
     * Method returns event by id
     * @param id - string
     */
    public async getH2HEventById(id: string): Promise<OBR.Events.Event | null> {
        return this.eventsResource.getH2HEventById(id)
    }

    /**
     * Method returns special event by id
     * @param id - string
     */
    public async getSpecialEventById(
        id: string
    ): Promise<OBR.Events.Event | null> {
        return this.eventsResource.getSpecialEventById(id)
    }

    /**
     * Method returns calendar events
     */
    public async getCalendarEvents(
        date: string
    ): Promise<OBR.Events.CalendarEvent[] | null> {
        return this.eventsResource.getCalendarEvents(date)
    }

    /**
     * Method collects events
     * @param filter - {data: string format 'YYYY-MM-DD', antepost: "1"}
     */
    public async getAllEvents(
        filter: OBR.Events.EventFilter
    ): Promise<OBR.Events.Event[]> {
        return this.eventsResource.getAllEvents(filter)
    }

    /**
     * Get getPickbets from event
     * @param eventId
     */
    public async getPickbets(eventId: string): Promise<OBR.Events.Pickbet[]> {
        return this.eventsResource.getPickbets(eventId)
    }

    /**
     * Get getPickbets from race
     * @param eventId
     */
    public async getPickbetsByRaceId(
        raceId: string
    ): Promise<OBR.Events.Pickbet[]> {
        return this.eventsResource.getPickbetsByRaceId(raceId)
    }

    /**
     * Get getPickbet from event
     * @param pickbetId
     */
    public getPickbet(pickbetId: string): Promise<OBR.Events.Pickbet | null> {
        return this.eventsResource.getPickbet(pickbetId)
    }

    /**
     * Get Specials for specials page
     */
    public getSpecials() {
        return this.eventsResource.getSpecials()
    }
}
