import { i18n } from '@obr-core/i18n/i18n'
import { betslipStoreService } from '@obr-core/services/store'

export function useBetslipDialog() {
    function toggleBetslipDialog(
        type: 'pick' | 'single',
        callback?: () => void
    ) {
        const isDialogVisible = betslipStoreService.dialog().isVisible

        if (!isDialogVisible) {
            betslipStoreService.updatedDialog({
                isVisible: true,
                status: 'primary',
                title: i18n.global.t('generic.label_reset_betslip'),
                message: i18n.global.t('generic.label_confirm_reset_betslip'),
                confirm: i18n.global.t('generic.label_yes'),
                cancel: i18n.global.t('generic.label_cancel'),
                actionConfirm: () =>
                    handleConfirmPickBetsDiscard(type, callback),
                actionCancel: handleCancelDiscard,
            })
        } else {
            betslipStoreService.updatedDialog({
                isVisible: false,
            })
        }
    }

    function handleConfirmPickBetsDiscard(
        type: 'pick' | 'single',
        callback?: () => void
    ) {
        if (type === 'pick') {
            // clean up pick bets and close dialog
            const activePickBet = betslipStoreService.activePickBetID()
            betslipStoreService.resetPickBetLegs(activePickBet)
            betslipStoreService.setActivePickBet('')
        } else if (type === 'single') {
            betslipStoreService.removeAllBet()
        }
        // close dialog
        handleCancelDiscard()

        // execute callback
        if (callback) {
            setTimeout(() => {
                callback()
            }, 500)
        }
    }

    function handleCancelDiscard() {
        // close dialog
        betslipStoreService.updatedDialog({
            isVisible: false,
        })
    }

    return {
        toggleBetslipDialog,
    }
}
