import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'

export class SettingsResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/settings`

    /**
     * API save settings
     */
    public async saveSettings(settings: OBR.Settings.Payload): Promise<void> {
        try {
            await http.post(`${this.apiPath}`, settings)
        } catch (error) {
            errorLogger.createException(error)
            throw error
        }
    }
}
