/**
 * Bet Category
 */
export const enum BetCategory {
    BOOKIE = 'BOK',
    TOTE = 'TOT',
    FIXED = 'FXD',
    PMU = 'PMU',
}

/**
 * Odds type
 */
export const enum OddsType {
    FIXED_WIN = 'odds_fxw',
    FIXED_PLACE = 'odds_fxp',
    BOK_WIN = 'odds_prc',
}

/**
 * Bet Type
 */
export enum BetType {
    EACHWAY = 'WP',
    EXACTA = 'EXA',
    ITA = 'ITA',
    MULTI_4 = 'M4',
    MULTI_5 = 'M5',
    MULTI_6 = 'M6',
    MULTI_7 = 'M7',
    P02 = 'P02',
    P03 = 'P03',
    P04 = 'P04',
    P05 = 'P05',
    P06 = 'P06',
    P07 = 'P07',
    P08 = 'P08',
    P09 = 'P09',
    P10 = 'P10',
    PP2 = 'PP2',
    PP3 = 'PP3',
    PP4 = 'PP4',
    PP5 = 'PP5',
    PP6 = 'PP6',
    PP7 = 'PP7',
    PP8 = 'PP8',
    PP9 = 'PP9',
    PICK_5 = 'PK5',
    PLACE = 'PLC',
    PLACE_SHOW = 'PS',
    QUARTE_PLUS = 'QRP',
    QUINELLA = 'QNL',
    QUINTE_PLUS = 'QNP',
    SHOW = 'SHW',
    SUPERFECTA = 'SFC',
    SF4 = 'SF4', // Super4
    SWINGER = 'SWG',
    TIERCE = 'TRC',
    TRIFECTA = 'TRI',
    TRITA = 'TRT',
    TRIO = 'TRO',
    TWO_OF_FOUR = 'TOF',
    WIN = 'WIN',
    WIN_PLACE_SHOW = 'WPS',
    WIN_SHOW = 'WS',
}

export enum BetSpecialType {
    H2H = 'h2h',
    SPECIAL = 'special',
    NORMAL = 'normal',
    EXOTIC = 'exotic',
}

/**
 * Bet Status
 */
export enum BetStatus {
    OPEN = 'OPN',
    WINNER = 'WIN',
    LOSER = 'LSD',
    REFUND = 'RFD',
    CANCELED = 'CNC',
    INVALID = 'INV',
}

/**
 * Event Type
 */
export const enum EventType {
    NORMAL = 'NOR',
    SPECIAL = 'SPC',
    ANTEPOST = 'ANT',
    H2H = 'H2H',
}

/**
 * All marks type
 */
export enum MarksType {
    FIRST = '1',
    SECOND = '2',
    THIRD = '3',
    FOURTH = '4',
    FIFTH = '5',
    COMBINATION = 'c',
}

/**
 * All betting type
 */
export const BETTING_MARKETS_ORDER: BetType[] = [
    BetType.WIN,
    BetType.PLACE,
    BetType.SHOW,
    BetType.WIN_SHOW,
    BetType.PLACE_SHOW,
    BetType.WIN_PLACE_SHOW,
    BetType.EACHWAY,
    BetType.ITA,
    BetType.TRITA,
    BetType.EXACTA,
    BetType.TRIFECTA,
    BetType.SUPERFECTA,
    BetType.QUINELLA,
    BetType.SWINGER,
    BetType.TRIO,
]

/**
 * All exotic betting types
 */
export const BETTING_EXOTIC_TYPES: BetType[] = [
    BetType.MULTI_4,
    BetType.MULTI_5,
    BetType.MULTI_6,
    BetType.MULTI_7,
    BetType.PICK_5,
    BetType.QUARTE_PLUS,
    BetType.QUINTE_PLUS,
    BetType.QUINELLA,
    BetType.SUPERFECTA,
    BetType.SF4,
    BetType.SWINGER,
    BetType.TIERCE,
    BetType.TRIFECTA,
    BetType.TRIO,
    BetType.TWO_OF_FOUR,
    BetType.EXACTA,
]

/**
 * All PMU - France - betting types
 */
export const BETTING_PMU_TYPES: BetType[] = [
    BetType.TWO_OF_FOUR,
    BetType.QUARTE_PLUS,
    BetType.QUINTE_PLUS,
    BetType.SF4,
    BetType.TIERCE,
    BetType.MULTI_4,
    BetType.MULTI_5,
    BetType.MULTI_6,
    BetType.MULTI_7,
    BetType.PICK_5,
]

/**
 * H2H betting market
 */
export const BETTING_MARKET_H2H = 'H2H'

/**
 * Special betting market
 */
export const BETTING_MARKET_SPECIAL = 'SPC'

/**
 * Right now just 5% German tax is considered
 */
export const BETTING_GERMAN_TAX: number = 5

/**
 * Betting payout odds values
 */
export const BETTING_PAYOUT_ODDS: OBR.Common.ObjectNumber<number> = {
    1.03: 1.03030304,
    1.06: 1.0625,
    1.07: 1.07142858,
    1.08: 1.08333334,
    1.09: 1.0909091,
    1.11: 1.11111112,
    1.12: 1.125,
    1.13: 1.13333334,
    1.14: 1.14285715,
    1.15: 1.15384616,
    1.16: 1.16666667,
    1.18: 1.18181819,
    1.22: 1.22222223,
    1.26: 1.26666667,
    1.28: 1.28571429,
    1.33: 1.33333334,
    1.36: 1.36363637,
    1.42: 1.42105264,
    1.44: 1.44444445,
    1.47: 1.47058824,
    1.53: 1.53333334,
    1.57: 1.57142858,
    1.61: 1.61538462,
    1.62: 1.625,
    1.66: 1.66666667,
    1.72: 1.72727273,
    1.83: 1.83333334,
    1.9: 1.90909091,
    1.95: 1.95238096,
    2.37: 2.375,
    2.62: 2.625,
    2.87: 2.875,
    3.12: 3.125,
    3.37: 3.375,
    4.33: 4.33333334,
}

/**
 * Betting Engine errors
 */
export const enum BettingError {
    MAX_ONE_BANKER = 'max_one_bank',
    MAX_N_BANKERS = 'max_n_bank',
    MAX_ONE_BANKER_PER_COLUMN = 'max_one_bank_per_col',
    IDENTICAL_BANKERS = 'same_bank',
}

export type NumBets = number

export const enum BetProvider {
    COL = 'COL',
    SIS = 'COL',
    WIFT = 'WIFT',
    XGT = 'XGT',
    XTC = 'XTC',
    RB = 'RB',
}

/**
 * Betting rules
 * value: array of columns that are preseneted for each bet type
 * e.g. used to show / hide columns in racecard
 * multi: bet number multiplier. Applied to bet types where combinations are not possible.
 *      Default value is 1 or null for bets with more than one column (exotic bets),
 *      for betTypes: 'WP', 'WS', 'PS' value is 2, for betType 'WPS' value is 3
 *  e.g. used in bet validation
 */
export const RULES: {
    [key in BetType]?: {
        value: MarksType[]
        multi: number | null
        oneMarkPerColumnAndRow?: boolean
    }
} = {
    [BetType.WIN]: {
        value: [MarksType.FIRST],
        multi: 1,
    },
    [BetType.PLACE]: {
        value: [MarksType.FIRST],
        multi: 1,
    },
    [BetType.SHOW]: {
        value: [MarksType.FIRST],
        multi: 1,
    },
    [BetType.EACHWAY]: {
        value: [MarksType.FIRST],
        multi: 2,
    },
    [BetType.WIN_SHOW]: {
        value: [MarksType.FIRST],
        multi: 2,
    },
    [BetType.PLACE_SHOW]: {
        value: [MarksType.FIRST],
        multi: 2,
    },
    [BetType.WIN_PLACE_SHOW]: {
        value: [MarksType.FIRST],
        multi: 3,
    },
    [BetType.ITA]: {
        value: [MarksType.SECOND],
        multi: 1,
    },
    [BetType.TRITA]: {
        value: [MarksType.THIRD],
        multi: 1,
    },
    [BetType.QUINELLA]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    },
    [BetType.SWINGER]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    },
    [BetType.EXACTA]: {
        value: [MarksType.FIRST, MarksType.SECOND, MarksType.COMBINATION],
        multi: null,
    },
    [BetType.TRIO]: {
        value: [MarksType.FIRST, MarksType.SECOND, MarksType.COMBINATION],
        multi: null,
    },
    [BetType.TRIFECTA]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.COMBINATION,
        ],
        multi: null,
    },
    [BetType.SUPERFECTA]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.FOURTH,
            MarksType.COMBINATION,
        ],

        multi: null,
    },
    [BetType.TWO_OF_FOUR]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],

        multi: null,
    },
    [BetType.QUARTE_PLUS]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.FOURTH,
            MarksType.COMBINATION,
        ],
        multi: null,
        oneMarkPerColumnAndRow: true,
    }, //'Quarte+',
    [BetType.QUINTE_PLUS]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.FOURTH,
            MarksType.FIFTH,
            MarksType.COMBINATION,
        ],
        multi: null,
        oneMarkPerColumnAndRow: true,
    }, // 'Quinte+',
    [BetType.SF4]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.FOURTH,
            MarksType.COMBINATION,
        ],
        multi: null,
        oneMarkPerColumnAndRow: true,
    }, //'Super4',
    [BetType.TIERCE]: {
        value: [
            MarksType.FIRST,
            MarksType.SECOND,
            MarksType.THIRD,
            MarksType.COMBINATION,
        ],
        multi: null,
        oneMarkPerColumnAndRow: true,
    }, // Tierce
    [BetType.MULTI_4]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    }, //'Multi4',
    [BetType.MULTI_5]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    }, //Multi5
    [BetType.MULTI_6]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    }, //'Multi6',
    [BetType.MULTI_7]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    }, //'Multi7',
    [BetType.PICK_5]: {
        value: [MarksType.FIRST, MarksType.COMBINATION],
        multi: null,
    }, // 'Pick5'
}

/**
 * SIS Countries
 * Used to determine what label to displey for Forecast/Exacta and Tricast/Trifecta
 */
export const SIS_COUNTRIES = [
    'AE',
    'AR',
    'AU',
    'BH',
    'BR',
    'CL',
    'CZ',
    'ES',
    'GB',
    'HU',
    'IE',
    'IT',
    'MU',
    'MX',
    'PE',
    'SA',
    'US',
    'UY',
]
