import { getWebsiteTLD } from '@obr-core/helpers/settings.helpers'
import { isTouchScreen } from '@obr-core/helpers/app.helpers'

export function initialState(): OBR.Store.AppState {
    return {
        exchange_rates: {} as OBR.Settings.ExchangeRates,
        ip_country: '',
        tm_session_id: null,
        currency: 'EUR',
        loading: false,
        loading_overlay: false,
        error: false,
        error_data: [],
        website_tld: getWebsiteTLD(),
        left_drawer_visible: false,
        right_drawer_visible: false,
        left_menu_visible: false,
        mounted: false,
        brand: import.meta.env.VUE_APP_BRAND,
        languages: {},
        brand_child: import.meta.env.VUE_APP_CHILD_BRAND,
        redirect: null,
        is_touch_screeen: isTouchScreen(),
        is_focus_on_input: false,
        hide_greyhounds: false,
        hide_virtuals: false,
        hide_streams: false,
        use_seo: false,
    }
}
