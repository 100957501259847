/**
 * Accept odds changes all values
 */
export enum AcceptBetsChanges {
    ALL = 'all',
    BIGGER = 'bigger',
    NO = 'no',
}

export const LIMITED_COUNTRIES_IN_COUNTRY_ORDER_LIST = [
    'AE',
    'AR',
    'AT',
    'AU',
    'BE',
    'BH',
    'BR',
    'CA',
    'CH',
    'CL',
    'CN',
    'CZ',
    'DE',
    'DK',
    'DO',
    'ES',
    'FI',
    'FR',
    'GB',
    'HK',
    'HU',
    'IE',
    'IN',
    'IT',
    'JP',
    'KR',
    'MA',
    'MO',
    'MT',
    'MU',
    'MX',
    'NO',
    'NZ',
    'PA',
    'PE',
    'QA',
    'SA',
    'SE',
    'SG',
    'TR',
    'US',
    'UY',
    'ZA',
    'ZW',
]

/**
 * List of time zones
 */
export const PRESET_TIME_ZONE_LIST: { [key: string]: string } = {
    '(UTC-12:00) International Date Line West': 'Etc/GMT+12',
    '(UTC-11:00) Coordinated Universal Time-11': 'Pacific/Pago_Pago',
    '(UTC-10:00) Hawaii': 'Pacific/Honolulu',
    '(UTC-09:00) Alaska': 'America/Anchorage',
    '(UTC-08:00) Baja California': 'America/Santa_Isabel',
    '(UTC-07:00) Pacific Daylight Time (US & Canada)': 'America/Los_Angeles',
    '(UTC-08:00) Pacific Standard Time (US & Canada)': 'America/Los_Angeles',
    '(UTC-07:00) Arizona': 'America/Phoenix',
    '(UTC-07:00) Chihuahua, La Paz, Mazatlan': 'America/Mazatlan',
    '(UTC-07:00) Mountain Time (US & Canada)': 'America/Denver',
    '(UTC-06:00) Central America': 'America/Guatemala',
    '(UTC-06:00) Central Time (US & Canada)': 'America/Chicago',
    '(UTC-06:00) Guadalajara, Mexico City, Monterrey': 'America/Mexico_City',
    '(UTC-06:00) Saskatchewan': 'America/Regina',
    '(UTC-05:00) Bogota, Lima, Quito': 'America/Bogota',
    '(UTC-05:00) Eastern Time (US & Canada)': 'America/New_York',
    '(UTC-04:00) Eastern Daylight Time (US & Canada)': 'America/Detroit',
    '(UTC-05:00) Indiana (East)': 'America/Indiana/Marengo',
    '(UTC-04:30) Caracas': 'America/Caracas',
    '(UTC-04:00) Asuncion': 'America/Asuncion',
    '(UTC-04:00) Atlantic Time (Canada)': 'America/Halifax',
    '(UTC-04:00) Cuiaba': 'America/Campo_Grande',
    '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan': 'America/Santo_Domingo',
    '(UTC-04:00) Santiago': 'America/Santiago',
    '(UTC-03:30) Newfoundland': 'America/St_Johns',
    '(UTC-03:00) Brasilia': 'America/Sao_Paulo',
    '(UTC-03:00) Buenos Aires': 'America/Argentina/Buenos_Aires',
    '(UTC-03:00) Cayenne, Fortaleza': 'Atlantic/Stanley',
    '(UTC-03:00) Greenland': 'America/Godthab',
    '(UTC-03:00) Montevideo': 'America/Montevideo',
    '(UTC-03:00) Salvador': 'America/Bahia',
    '(UTC-02:00) Coordinated Universal Time-02': 'America/Noronha',
    '(UTC-01:00) Azores': 'Atlantic/Azores',
    '(UTC-01:00) Cape Verde Is.': 'Atlantic/Cape_Verde',
    '(UTC) Casablanca': 'Africa/Casablanca',
    '(UTC) Coordinated Universal Time': 'America/Danmarkshavn',
    '(UTC) Edinburgh, London': 'Europe/London',
    '(UTC+01:00) Edinburgh, London': 'Europe/Isle_of_Man',
    '(UTC) Dublin, Lisbon': 'Atlantic/Canary',
    '(UTC) Monrovia, Reykjavik': 'Africa/Abidjan',
    '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna':
        'Europe/Berlin',
    '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague':
        'Europe/Belgrade',
    '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris': 'Africa/Ceuta',
    '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb': 'Europe/Sarajevo',
    '(UTC+01:00) West Central Africa': 'Africa/Lagos',
    '(UTC+01:00) Windhoek': 'Africa/Windhoek',
    '(UTC+02:00) Athens, Bucharest': 'Asia/Nicosia',
    '(UTC+02:00) Beirut': 'Asia/Beirut',
    '(UTC+02:00) Cairo': 'Africa/Cairo',
    '(UTC+02:00) Damascus': 'Asia/Damascus',
    '(UTC+02:00) E. Europe': 'Europe/Helsinki',
    '(UTC+02:00) Harare, Pretoria': 'Africa/Johannesburg',
    '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius':
        'Europe/Helsinki',
    '(UTC+03:00) Istanbul': 'Europe/Istanbul',
    '(UTC+02:00) Jerusalem': 'Asia/Jerusalem',
    '(UTC+02:00) Tripoli': 'Africa/Tripoli',
    '(UTC+03:00) Amman': 'Asia/Amman',
    '(UTC+03:00) Baghdad': 'Asia/Baghdad',
    '(UTC+02:00) Kaliningrad': 'Europe/Kaliningrad',
    '(UTC+03:00) Kuwait, Riyadh': 'Asia/Aden',
    '(UTC+03:00) Nairobi': 'Africa/Addis_Ababa',
    '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk': 'Europe/Kirov',
    '(UTC+04:00) Samara, Ulyanovsk, Saratov': 'Europe/Astrakhan',
    '(UTC+03:30) Tehran': 'Asia/Tehran',
    '(UTC+04:00) Abu Dhabi, Muscat': 'Asia/Dubai',
    '(UTC+04:00) Baku': 'Asia/Baku',
    '(UTC+04:00) Port Louis': 'Indian/Mahe',
    '(UTC+04:00) Tbilisi': 'Asia/Tbilisi',
    '(UTC+04:00) Yerevan': 'Asia/Yerevan',
    '(UTC+04:30) Kabul': 'Asia/Kabul',
    '(UTC+05:00) Ashgabat, Tashkent': 'Antarctica/Mawson',
    '(UTC+05:00) Yekaterinburg': 'Asia/Yekaterinburg',
    '(UTC+05:00) Islamabad, Karachi': 'Asia/Karachi',
    '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi': 'Asia/Kolkata',
    '(UTC+05:30) Sri Jayawardenepura': 'Asia/Colombo',
    '(UTC+05:45) Kathmandu': 'Asia/Kathmandu',
    '(UTC+06:00) Nur-Sultan (Astana)': 'Antarctica/Vostok',
    '(UTC+06:00) Dhaka': 'Asia/Dhaka',
    '(UTC+06:30) Yangon (Rangoon)': 'Asia/Rangoon',
    '(UTC+07:00) Bangkok, Hanoi, Jakarta': 'Asia/Jakarta',
    '(UTC+07:00) Novosibirsk': 'Asia/Omsk',
    '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi': 'Asia/Shanghai',
    '(UTC+08:00) Krasnoyarsk': 'Asia/Krasnoyarsk',
    '(UTC+08:00) Kuala Lumpur, Singapore': 'Asia/Brunei',
    '(UTC+08:00) Perth': 'Antarctica/Casey',
    '(UTC+08:00) Taipei': 'Asia/Taipei',
    '(UTC+08:00) Ulaanbaatar': 'Asia/Choibalsan',
    '(UTC+08:00) Irkutsk': 'Asia/Irkutsk',
    '(UTC+09:00) Osaka, Sapporo, Tokyo': 'Asia/Tokyo',
    '(UTC+09:00) Seoul': 'Asia/Pyongyang',
    '(UTC+09:30) Adelaide': 'Australia/Adelaide',
    '(UTC+09:30) Darwin': 'Australia/Darwin',
    '(UTC+10:00) Brisbane': 'Australia/Brisbane',
    '(UTC+10:00) Canberra, Melbourne, Sydney': 'Australia/Sydney',
    '(UTC+10:00) Guam, Port Moresby': 'Antarctica/DumontDUrville',
    '(UTC+10:00) Hobart': 'Australia/Currie',
    '(UTC+09:00) Yakutsk': 'Asia/Yakutsk',
    '(UTC+11:00) Solomon Is., New Caledonia': 'Pacific/Noumea',
    '(UTC+11:00) Vladivostok': 'Asia/Vladivostok',
    '(UTC+12:00) Auckland, Wellington': 'Pacific/Auckland',
    '(UTC+12:00) Coordinated Universal Time+12': 'Pacific/Tarawa',
    '(UTC+12:00) Fiji': 'Pacific/Fiji',
    '(UTC+12:00) Magadan': 'Asia/Kamchatka',
    '(UTC+12:00) Petropavlovsk-Kamchatsky - Old': 'Asia/Kamchatka',
    "(UTC+13:00) Nuku'alofa": 'Pacific/Tongatapu',
    '(UTC+13:00) Samoa': 'Pacific/Apia',
}

/*
 * Mapping all tz to parent from PRESET_TIME_ZONE_LIST
 */
export const TIMEZONE_CHILD_TO_PARENT: { [key: string]: string } = {
    'Etc/GMT+12': 'Etc/GMT+12',
    'Etc/GMT+11': 'Pacific/Pago_Pago',
    'Pacific/Midway': 'Pacific/Pago_Pago',
    'Pacific/Niue': 'Pacific/Pago_Pago',
    'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
    'Etc/GMT+10': 'Pacific/Honolulu',
    'Pacific/Honolulu': 'Pacific/Honolulu',
    'Pacific/Johnston': 'Pacific/Honolulu',
    'Pacific/Rarotonga': 'Pacific/Honolulu',
    'Pacific/Tahiti': 'Pacific/Honolulu',
    'America/Anchorage': 'America/Anchorage',
    'America/Juneau': 'America/Anchorage',
    'America/Nome': 'America/Anchorage',
    'America/Sitka': 'America/Anchorage',
    'America/Yakutat': 'America/Anchorage',
    'America/Santa_Isabel': 'America/Santa_Isabel',
    'America/Los_Angeles': 'America/Los_Angeles',
    'America/Tijuana': 'America/Los_Angeles',
    'America/Vancouver': 'America/Los_Angeles',
    PST8PDT: 'America/Los_Angeles',
    'America/Creston': 'America/Phoenix',
    'America/Dawson': 'America/Phoenix',
    'America/Dawson_Creek': 'America/Phoenix',
    'America/Hermosillo': 'America/Phoenix',
    'America/Phoenix': 'America/Phoenix',
    'America/Whitehorse': 'America/Phoenix',
    'Etc/GMT+7': 'America/Phoenix',
    'America/Chihuahua': 'America/Mazatlan',
    'America/Mazatlan': 'America/Mazatlan',
    'America/Boise': 'America/Denver',
    'America/Cambridge_Bay': 'America/Denver',
    'America/Denver': 'America/Denver',
    'America/Edmonton': 'America/Denver',
    'America/Inuvik': 'America/Denver',
    'America/Ojinaga': 'America/Denver',
    'America/Yellowknife': 'America/Denver',
    MST7MDT: 'America/Denver',
    'America/Belize': 'America/Guatemala',
    'America/Costa_Rica': 'America/Guatemala',
    'America/El_Salvador': 'America/Guatemala',
    'America/Guatemala': 'America/Guatemala',
    'America/Managua': 'America/Guatemala',
    'America/Tegucigalpa': 'America/Guatemala',
    'Etc/GMT+6': 'America/Guatemala',
    'Pacific/Galapagos': 'America/Guatemala',
    'America/Chicago': 'America/Chicago',
    'America/Indiana/Knox': 'America/Chicago',
    'America/Indiana/Tell_City': 'America/Chicago',
    'America/Matamoros': 'America/Chicago',
    'America/Menominee': 'America/Chicago',
    'America/North_Dakota/Beulah': 'America/Chicago',
    'America/North_Dakota/Center': 'America/Chicago',
    'America/North_Dakota/New_Salem': 'America/Chicago',
    'America/Rainy_River': 'America/Chicago',
    'America/Rankin_Inlet': 'America/Chicago',
    'America/Resolute': 'America/Chicago',
    'America/Winnipeg': 'America/Chicago',
    CST6CDT: 'America/Chicago',
    'America/Bahia_Banderas': 'America/Mexico_City',
    'America/Cancun': 'America/Mexico_City',
    'America/Merida': 'America/Mexico_City',
    'America/Mexico_City': 'America/Mexico_City',
    'America/Monterrey': 'America/Mexico_City',
    'America/Regina': 'America/Regina',
    'America/Swift_Current': 'America/Regina',
    'America/Bogota': 'America/Bogota',
    'America/Cayman': 'America/Bogota',
    'America/Coral_Harbour': 'America/Bogota',
    'America/Eirunepe': 'America/Bogota',
    'America/Guayaquil': 'America/Bogota',
    'America/Jamaica': 'America/Bogota',
    'America/Lima': 'America/Bogota',
    'America/Panama': 'America/Bogota',
    'America/Rio_Branco': 'America/Bogota',
    'Etc/GMT+5': 'America/Bogota',
    'America/Detroit': 'America/Detroit',
    'America/Havana': 'America/Detroit',
    'America/Indiana/Petersburg': 'America/Detroit',
    'America/Indiana/Vincennes': 'America/Detroit',
    'America/Indiana/Winamac': 'America/Detroit',
    'America/Iqaluit': 'America/Detroit',
    'America/Kentucky/Monticello': 'America/Detroit',
    'America/Louisville': 'America/Detroit',
    'America/Montreal': 'America/Detroit',
    'America/Nassau': 'America/Detroit',
    'America/New_York': 'America/Detroit',
    'America/Nipigon': 'America/Detroit',
    'America/Pangnirtung': 'America/Detroit',
    'America/Port-au-Prince': 'America/Detroit',
    'America/Thunder_Bay': 'America/Detroit',
    'America/Toronto': 'America/Detroit',
    'America/Indiana/Marengo': 'America/Indiana/Marengo',
    'America/Indiana/Vevay': 'America/Indiana/Marengo',
    'America/Indianapolis': 'America/Indiana/Marengo',
    'America/Caracas': 'America/Caracas',
    'America/Asuncion': 'America/Asuncion',
    'America/Glace_Bay': 'America/Halifax',
    'America/Goose_Bay': 'America/Halifax',
    'America/Halifax': 'America/Halifax',
    'America/Moncton': 'America/Halifax',
    'America/Thule': 'America/Halifax',
    'Atlantic/Bermuda': 'America/Halifax',
    'America/Campo_Grande': 'America/Campo_Grande',
    'America/Cuiaba': 'America/Campo_Grande',
    'America/Anguilla': 'America/Santo_Domingo',
    'America/Antigua': 'America/Santo_Domingo',
    'America/Aruba': 'America/Santo_Domingo',
    'America/Barbados': 'America/Santo_Domingo',
    'America/Blanc-Sablon': 'America/Santo_Domingo',
    'America/Boa_Vista': 'America/Santo_Domingo',
    'America/Curacao': 'America/Santo_Domingo',
    'America/Dominica': 'America/Santo_Domingo',
    'America/Grand_Turk': 'America/Santo_Domingo',
    'America/Grenada': 'America/Santo_Domingo',
    'America/Guadeloupe': 'America/Santo_Domingo',
    'America/Guyana': 'America/Santo_Domingo',
    'America/Kralendijk': 'America/Santo_Domingo',
    'America/La_Paz': 'America/Santo_Domingo',
    'America/Lower_Princes': 'America/Santo_Domingo',
    'America/Manaus': 'America/Santo_Domingo',
    'America/Marigot': 'America/Santo_Domingo',
    'America/Martinique': 'America/Santo_Domingo',
    'America/Montserrat': 'America/Santo_Domingo',
    'America/Port_of_Spain': 'America/Santo_Domingo',
    'America/Porto_Velho': 'America/Santo_Domingo',
    'America/Puerto_Rico': 'America/Santo_Domingo',
    'America/Santo_Domingo': 'America/Santo_Domingo',
    'America/St_Barthelemy': 'America/Santo_Domingo',
    'America/St_Kitts': 'America/Santo_Domingo',
    'America/St_Lucia': 'America/Santo_Domingo',
    'America/St_Thomas': 'America/Santo_Domingo',
    'America/St_Vincent': 'America/Santo_Domingo',
    'America/Tortola': 'America/Santo_Domingo',
    'Etc/GMT+4': 'America/Santo_Domingo',
    'America/Santiago': 'America/Santiago',
    'Antarctica/Palmer': 'America/Santiago',
    'America/St_Johns': 'America/St_Johns',
    'America/Sao_Paulo': 'America/Sao_Paulo',
    'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Jujuy': 'America/Argentina/Buenos_Aires',
    'America/Argentina/La_Rioja': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Mendoza': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Rio_Gallegos': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Salta': 'America/Argentina/Buenos_Aires',
    'America/Argentina/San_Juan': 'America/Argentina/Buenos_Aires',
    'America/Argentina/San_Luis': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Tucuman': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Ushuaia': 'America/Argentina/Buenos_Aires',
    'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Catamarca': 'America/Argentina/Buenos_Aires',
    'America/Cordoba': 'America/Argentina/Buenos_Aires',
    'America/Jujuy': 'America/Argentina/Buenos_Aires',
    'America/Mendoza': 'America/Argentina/Buenos_Aires',
    'America/Araguaina': 'Atlantic/Stanley',
    'America/Belem': 'Atlantic/Stanley',
    'America/Cayenne': 'Atlantic/Stanley',
    'America/Fortaleza': 'Atlantic/Stanley',
    'America/Maceio': 'Atlantic/Stanley',
    'America/Paramaribo': 'Atlantic/Stanley',
    'America/Recife': 'Atlantic/Stanley',
    'America/Santarem': 'Atlantic/Stanley',
    'Antarctica/Rothera': 'Atlantic/Stanley',
    'Atlantic/Stanley': 'Atlantic/Stanley',
    'Etc/GMT+3': 'Atlantic/Stanley',
    'America/Godthab': 'America/Godthab',
    'America/Montevideo': 'America/Montevideo',
    'America/Bahia': 'America/Bahia',
    'America/Noronha': 'America/Noronha',
    'Atlantic/South_Georgia': 'America/Noronha',
    'Etc/GMT+2': 'America/Noronha',
    'America/Scoresbysund': 'Atlantic/Azores',
    'Atlantic/Azores': 'Atlantic/Azores',
    'Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
    'Etc/GMT+1': 'Atlantic/Cape_Verde',
    'Africa/Casablanca': 'Africa/Casablanca',
    'Africa/El_Aaiun': 'Africa/Casablanca',
    'America/Danmarkshavn': 'America/Danmarkshavn',
    'Etc/GMT': 'America/Danmarkshavn',
    'Europe/Isle_of_Man': 'Europe/Isle_of_Man',
    'Europe/Guernsey': 'Europe/Isle_of_Man',
    'Europe/Jersey': 'Europe/Isle_of_Man',
    'Europe/London': 'Europe/Isle_of_Man',
    'Atlantic/Canary': 'Atlantic/Canary',
    'Atlantic/Faeroe': 'Atlantic/Canary',
    'Atlantic/Madeira': 'Atlantic/Canary',
    'Europe/Dublin': 'Atlantic/Canary',
    'Europe/Lisbon': 'Atlantic/Canary',
    'Africa/Abidjan': 'Africa/Abidjan',
    'Africa/Accra': 'Africa/Abidjan',
    'Africa/Bamako': 'Africa/Abidjan',
    'Africa/Banjul': 'Africa/Abidjan',
    'Africa/Bissau': 'Africa/Abidjan',
    'Africa/Conakry': 'Africa/Abidjan',
    'Africa/Dakar': 'Africa/Abidjan',
    'Africa/Freetown': 'Africa/Abidjan',
    'Africa/Lome': 'Africa/Abidjan',
    'Africa/Monrovia': 'Africa/Abidjan',
    'Africa/Nouakchott': 'Africa/Abidjan',
    'Africa/Ouagadougou': 'Africa/Abidjan',
    'Africa/Sao_Tome': 'Africa/Abidjan',
    'Atlantic/Reykjavik': 'Africa/Abidjan',
    'Atlantic/St_Helena': 'Africa/Abidjan',
    'Arctic/Longyearbyen': 'Europe/Berlin',
    'Europe/Amsterdam': 'Europe/Berlin',
    'Europe/Andorra': 'Europe/Berlin',
    'Europe/Berlin': 'Europe/Berlin',
    'Europe/Busingen': 'Europe/Berlin',
    'Europe/Gibraltar': 'Europe/Berlin',
    'Europe/Luxembourg': 'Europe/Berlin',
    'Europe/Malta': 'Europe/Berlin',
    'Europe/Monaco': 'Europe/Berlin',
    'Europe/Oslo': 'Europe/Berlin',
    'Europe/Rome': 'Europe/Berlin',
    'Europe/San_Marino': 'Europe/Berlin',
    'Europe/Stockholm': 'Europe/Berlin',
    'Europe/Vaduz': 'Europe/Berlin',
    'Europe/Vatican': 'Europe/Berlin',
    'Europe/Vienna': 'Europe/Berlin',
    'Europe/Zurich': 'Europe/Berlin',
    'Europe/Belgrade': 'Europe/Belgrade',
    'Europe/Bratislava': 'Europe/Belgrade',
    'Europe/Budapest': 'Europe/Belgrade',
    'Europe/Ljubljana': 'Europe/Belgrade',
    'Europe/Podgorica': 'Europe/Belgrade',
    'Europe/Prague': 'Europe/Belgrade',
    'Europe/Tirane': 'Europe/Belgrade',
    'Africa/Ceuta': 'Africa/Ceuta',
    'Europe/Brussels': 'Africa/Ceuta',
    'Europe/Copenhagen': 'Africa/Ceuta',
    'Europe/Madrid': 'Africa/Ceuta',
    'Europe/Paris': 'Africa/Ceuta',
    'Europe/Sarajevo': 'Europe/Warsaw',
    'Europe/Skopje': 'Europe/Warsaw',
    'Europe/Warsaw': 'Europe/Warsaw',
    'Europe/Zagreb': 'Europe/Warsaw',
    'Africa/Algiers': 'Africa/Lagos',
    'Africa/Bangui': 'Africa/Lagos',
    'Africa/Brazzaville': 'Africa/Lagos',
    'Africa/Douala': 'Africa/Lagos',
    'Africa/Kinshasa': 'Africa/Lagos',
    'Africa/Lagos': 'Africa/Lagos',
    'Africa/Libreville': 'Africa/Lagos',
    'Africa/Luanda': 'Africa/Lagos',
    'Africa/Malabo': 'Africa/Lagos',
    'Africa/Ndjamena': 'Africa/Lagos',
    'Africa/Niamey': 'Africa/Lagos',
    'Africa/Porto-Novo': 'Africa/Lagos',
    'Africa/Tunis': 'Africa/Lagos',
    'Etc/GMT-1': 'Africa/Lagos',
    'Africa/Windhoek': 'Africa/Windhoek',
    'Asia/Nicosia': 'Europe/Helsinki',
    'Europe/Athens': 'Europe/Helsinki',
    'Europe/Bucharest': 'Europe/Helsinki',
    'Europe/Chisinau': 'Europe/Helsinki',
    'Asia/Beirut': 'Asia/Beirut',
    'Africa/Cairo': 'Africa/Cairo',
    'Asia/Damascus': 'Asia/Damascus',
    'Europe/Helsinki': 'Europe/Helsinki',
    'Europe/Kyiv': 'Europe/Helsinki',
    'Europe/Mariehamn': 'Europe/Helsinki',
    'Europe/Nicosia': 'Europe/Helsinki',
    'Europe/Riga': 'Europe/Helsinki',
    'Europe/Sofia': 'Europe/Helsinki',
    'Europe/Tallinn': 'Europe/Helsinki',
    'Europe/Uzhhorod': 'Europe/Helsinki',
    'Europe/Vilnius': 'Europe/Helsinki',
    'Europe/Zaporizhzhia': 'Europe/Helsinki',
    'Africa/Blantyre': 'Africa/Johannesburg',
    'Africa/Bujumbura': 'Africa/Johannesburg',
    'Africa/Gaborone': 'Africa/Johannesburg',
    'Africa/Harare': 'Africa/Johannesburg',
    'Africa/Johannesburg': 'Africa/Johannesburg',
    'Africa/Kigali': 'Africa/Johannesburg',
    'Africa/Lubumbashi': 'Africa/Johannesburg',
    'Africa/Lusaka': 'Africa/Johannesburg',
    'Africa/Maputo': 'Africa/Johannesburg',
    'Africa/Maseru': 'Africa/Johannesburg',
    'Africa/Mbabane': 'Africa/Johannesburg',
    'Etc/GMT-2': 'Africa/Johannesburg',
    'Europe/Istanbul': 'Europe/Istanbul',
    'Asia/Jerusalem': 'Asia/Jerusalem',
    'Africa/Tripoli': 'Africa/Tripoli',
    'Asia/Amman': 'Asia/Amman',
    'Asia/Baghdad': 'Asia/Baghdad',
    'Europe/Kaliningrad': 'Europe/Kaliningrad',
    'Asia/Aden': 'Asia/Aden',
    'Asia/Bahrain': 'Asia/Aden',
    'Asia/Kuwait': 'Asia/Aden',
    'Asia/Qatar': 'Asia/Aden',
    'Asia/Riyadh': 'Asia/Aden',
    'Africa/Addis_Ababa': 'Africa/Addis_Ababa',
    'Africa/Asmera': 'Africa/Addis_Ababa',
    'Africa/Dar_es_Salaam': 'Africa/Addis_Ababa',
    'Africa/Djibouti': 'Africa/Addis_Ababa',
    'Africa/Juba': 'Africa/Addis_Ababa',
    'Africa/Kampala': 'Africa/Addis_Ababa',
    'Africa/Khartoum': 'Africa/Addis_Ababa',
    'Africa/Mogadishu': 'Africa/Addis_Ababa',
    'Africa/Nairobi': 'Africa/Addis_Ababa',
    'Antarctica/Syowa': 'Africa/Addis_Ababa',
    'Etc/GMT-3': 'Africa/Addis_Ababa',
    'Indian/Antananarivo': 'Africa/Addis_Ababa',
    'Indian/Comoro': 'Africa/Addis_Ababa',
    'Indian/Mayotte': 'Africa/Addis_Ababa',
    'Europe/Kirov': 'Europe/Kirov',
    'Europe/Moscow': 'Europe/Kirov',
    'Europe/Simferopol': 'Europe/Kirov',
    'Europe/Volgograd': 'Europe/Kirov',
    'Europe/Minsk': 'Europe/Kirov',
    'Europe/Astrakhan': 'Europe/Astrakhan',
    'Europe/Samara': 'Europe/Astrakhan',
    'Europe/Ulyanovsk': 'Europe/Astrakhan',
    'Asia/Tehran': 'Asia/Tehran',
    'Asia/Dubai': 'Asia/Dubai',
    'Asia/Muscat': 'Asia/Dubai',
    'Etc/GMT-4': 'Asia/Dubai',
    'Asia/Baku': 'Asia/Baku',
    'Indian/Mahe': 'Indian/Mahe',
    'Indian/Mauritius': 'Indian/Mahe',
    'Indian/Reunion': 'Indian/Mahe',
    'Asia/Tbilisi': 'Asia/Tbilisi',
    'Asia/Yerevan': 'Asia/Yerevan',
    'Asia/Kabul': 'Asia/Kabul',
    'Antarctica/Mawson': 'Antarctica/Mawson',
    'Asia/Aqtau': 'Antarctica/Mawson',
    'Asia/Aqtobe': 'Antarctica/Mawson',
    'Asia/Ashgabat': 'Antarctica/Mawson',
    'Asia/Dushanbe': 'Antarctica/Mawson',
    'Asia/Oral': 'Antarctica/Mawson',
    'Asia/Samarkand': 'Antarctica/Mawson',
    'Asia/Tashkent': 'Antarctica/Mawson',
    'Etc/GMT-5': 'Antarctica/Mawson',
    'Indian/Kerguelen': 'Antarctica/Mawson',
    'Indian/Maldives': 'Antarctica/Mawson',
    'Asia/Yekaterinburg': 'Asia/Yekaterinburg',
    'Asia/Karachi': 'Asia/Karachi',
    'Asia/Kolkata': 'Asia/Kolkata',
    'Asia/Calcutta': 'Asia/Kolkata',
    'Asia/Colombo': 'Asia/Colombo',
    'Asia/Kathmandu': 'Asia/Kathmandu',
    'Antarctica/Vostok': 'Antarctica/Vostok',
    'Asia/Almaty': 'Antarctica/Vostok',
    'Asia/Bishkek': 'Antarctica/Vostok',
    'Asia/Qyzylorda': 'Antarctica/Vostok',
    'Asia/Urumqi': 'Antarctica/Vostok',
    'Etc/GMT-6': 'Antarctica/Vostok',
    'Indian/Chagos': 'Antarctica/Vostok',
    'Asia/Dhaka': 'Asia/Dhaka',
    'Asia/Thimphu': 'Asia/Dhaka',
    'Asia/Rangoon': 'Asia/Rangoon',
    'Indian/Cocos': 'Asia/Rangoon',
    'Antarctica/Davis': 'Asia/Jakarta',
    'Asia/Bangkok': 'Asia/Jakarta',
    'Asia/Hovd': 'Asia/Jakarta',
    'Asia/Jakarta': 'Asia/Jakarta',
    'Asia/Phnom_Penh': 'Asia/Jakarta',
    'Asia/Pontianak': 'Asia/Jakarta',
    'Asia/Saigon': 'Asia/Jakarta',
    'Asia/Vientiane': 'Asia/Jakarta',
    'Etc/GMT-7': 'Asia/Jakarta',
    'Indian/Christmas': 'Asia/Jakarta',
    'Asia/Novokuznetsk': 'Asia/Omsk',
    'Asia/Novosibirsk': 'Asia/Omsk',
    'Asia/Omsk': 'Asia/Omsk',
    'Asia/Hong_Kong': 'Asia/Shanghai',
    'Asia/Macau': 'Asia/Shanghai',
    'Asia/Shanghai': 'Asia/Shanghai',
    'Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
    'Asia/Brunei': 'Asia/Brunei',
    'Asia/Kuala_Lumpur': 'Asia/Brunei',
    'Asia/Kuching': 'Asia/Brunei',
    'Asia/Makassar': 'Asia/Brunei',
    'Asia/Manila': 'Asia/Brunei',
    'Asia/Singapore': 'Asia/Brunei',
    'Etc/GMT-8': 'Asia/Brunei',
    'Antarctica/Casey': 'Antarctica/Casey',
    'Australia/Perth': 'Antarctica/Casey',
    'Asia/Taipei': 'Asia/Taipei',
    'Asia/Choibalsan': 'Asia/Choibalsan',
    'Asia/Ulaanbaatar': 'Asia/Choibalsan',
    'Asia/Irkutsk': 'Asia/Irkutsk',
    'Asia/Dili': 'Asia/Tokyo',
    'Asia/Jayapura': 'Asia/Tokyo',
    'Asia/Tokyo': 'Asia/Tokyo',
    'Etc/GMT-9': 'Asia/Tokyo',
    'Pacific/Palau': 'Asia/Tokyo',
    'Asia/Pyongyang': 'Asia/Pyongyang',
    'Asia/Seoul': 'Asia/Pyongyang',
    'Australia/Adelaide': 'Australia/Adelaide',
    'Australia/Broken_Hill': 'Australia/Adelaide',
    'Australia/Darwin': 'Australia/Darwin',
    'Australia/Brisbane': 'Australia/Brisbane',
    'Australia/Lindeman': 'Australia/Brisbane',
    'Australia/Melbourne': 'Australia/Sydney',
    'Australia/Sydney': 'Australia/Sydney',
    'Antarctica/DumontDUrville': 'Antarctica/DumontDUrville',
    'Etc/GMT-10': 'Antarctica/DumontDUrville',
    'Pacific/Guam': 'Antarctica/DumontDUrville',
    'Pacific/Port_Moresby': 'Antarctica/DumontDUrville',
    'Pacific/Saipan': 'Antarctica/DumontDUrville',
    'Pacific/Truk': 'Antarctica/DumontDUrville',
    'Australia/Currie': 'Australia/Currie',
    'Australia/Hobart': 'Australia/Currie',
    'Asia/Chita': 'Asia/Yakutsk',
    'Asia/Khandyga': 'Asia/Yakutsk',
    'Asia/Yakutsk': 'Asia/Yakutsk',
    'Antarctica/Macquarie': 'Pacific/Noumea',
    'Etc/GMT-11': 'Pacific/Noumea',
    'Pacific/Efate': 'Pacific/Noumea',
    'Pacific/Guadalcanal': 'Pacific/Noumea',
    'Pacific/Kosrae': 'Pacific/Noumea',
    'Pacific/Noumea': 'Pacific/Noumea',
    'Pacific/Ponape': 'Pacific/Noumea',
    'Asia/Sakhalin': 'Asia/Vladivostok',
    'Asia/Ust-Nera': 'Asia/Vladivostok',
    'Asia/Vladivostok': 'Asia/Vladivostok',
    'Antarctica/McMurdo': 'Pacific/Auckland',
    'Pacific/Auckland': 'Pacific/Auckland',
    'Etc/GMT-12': 'Pacific/Tarawa',
    'Pacific/Funafuti': 'Pacific/Tarawa',
    'Pacific/Kwajalein': 'Pacific/Tarawa',
    'Pacific/Majuro': 'Pacific/Tarawa',
    'Pacific/Nauru': 'Pacific/Tarawa',
    'Pacific/Tarawa': 'Pacific/Tarawa',
    'Pacific/Wake': 'Pacific/Tarawa',
    'Pacific/Wallis': 'Pacific/Tarawa',
    'Pacific/Fiji': 'Pacific/Fiji',
    'Asia/Anadyr': 'Asia/Kamchatka',
    'Asia/Kamchatka': 'Asia/Kamchatka',
    'Asia/Magadan': 'Asia/Kamchatka',
    'Asia/Srednekolymsk': 'Asia/Kamchatka',
    'Etc/GMT-13': 'Pacific/Tongatapu',
    'Pacific/Enderbury': 'Pacific/Tongatapu',
    'Pacific/Fakaofo': 'Pacific/Tongatapu',
    'Pacific/Tongatapu': 'Pacific/Tongatapu',
    'Pacific/Apia': 'Pacific/Apia',
}

export const TIMEZONE_DEFAULT = 'Europe/Berlin'
