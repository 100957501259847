import { RunnerFormResource } from '@obr-core/resources/RunnerFormResource'
import { FormTabsType } from '@obr-ui/components/RunnerForm/config'

/**
 * RunnerForm Service
 * Singleton
 */
export class RunnerFormService {
    private static instance: RunnerFormService
    private runnerFormResource: RunnerFormResource

    private constructor() {
        this.runnerFormResource = new RunnerFormResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): RunnerFormService {
        if (RunnerFormService.instance === undefined) {
            RunnerFormService.instance = new RunnerFormService()
        }
        return RunnerFormService.instance
    }

    public async getById(subjectId: string, fields?: FormTabsType) {
        return this.runnerFormResource.getById(subjectId, fields)
    }
}
