/**
 * Provide method to update Tab
 */
export const PROVIDER_UPDATE_TAB = 'PROVIDER_RUNNER_TABLE_UPDATE_TAB'
export const PROVIDER_UPDATE_ALL_RUNNERS =
    'PROVIDER_RUNNER_TABLE_UPDATE_ALL_RUNNERS'
export const PROVIDER_INITIAL_RUNNERS_FORM_STATE =
    'PROVIDER_INITIAL_RUNNERS_FORM_STATE'

export const RUNNER_ROW_SELECTOR_ID = 'runner-row-'
