import { useUser } from '@obr-core/store/user'
import { ErrorService } from '@obr-core/services/ErrorService'

class UserStoreService {
    isLoggedIn(): boolean {
        return useUser().logged_in
    }
    isLoading(): boolean {
        return useUser().loading
    }
    hasBestOddsGuaranteed() {
        return useUser().best_odds_guaranteed === true
    }
    mobile(): string | null {
        return useUser().mobile || null
    }
    currency(): OBR.User.Currency {
        return `${useUser().currency}` as OBR.User.Currency
    }
    currencySettings() {
        return useUser().currency_settings
    }
    streamsMinTurnover14d() {
        return useUser().currency_settings?.streams_access_min_turnover_14d || 0
    }
    country(): string {
        return `${useUser().address.country}`
    }
    language(): string {
        return useUser().getLanguage
    }
    licenseJurisdiction() {
        return useUser().license_jurisdiction
    }
    countriesOrder() {
        return useUser().countries_order
    }
    fullname(): string {
        return useUser().getFullname
    }
    isBonusEnabled() {
        return useUser().no_bonus === false
    }
    oddsFormat() {
        return useUser().odds_format
    }
    balance() {
        return useUser().balance
    }
    withdrawable() {
        return useUser().withdrawable
    }
    bonusBalance() {
        return useUser().bonus_balance
    }
    isTaxable(): boolean {
        return useUser().tax.taxable || false
    }
    taxDeductions() {
        return useUser().tax.deductions
    }
    mediaCountry() {
        return useUser().media_country
    }
    areDogsHidden() {
        return useUser().hide_dogs
    }
    timezone() {
        return useUser().timezone
    }
    shouldOpenBetslipFirstBet() {
        return useUser().open_betslip
    }
    shouldAcceptBetsChanges() {
        return useUser().accept_odds
    }
    acceptOddsSettings() {
        return useUser().accept_odds
    }
    unitSystem() {
        return useUser().unit_system
    }
    isBetConfirmationNeeded() {
        return useUser().betslip_confirmation
    }
    getPreferredStakeSingle() {
        return useUser().preferred_stakes_single
    }
    getPreferredStakeMultiple() {
        return useUser().preferred_stakes_multiple
    }
    getPreferredStakeExotic() {
        return useUser().preferred_stakes_exotic
    }
    getPreferredStakeUS() {
        return useUser().preferred_stakes_usd
    }
    ignoreBookiePreset() {
        return useUser().ignore_bookie_preset
    }

    /* Actions */
    init() {
        return useUser().onInit()
    }
    login(payload: OBR.User.LoginUserInput) {
        return useUser().onLoggedIn(payload)
    }
    logout() {
        return useUser().onLogout()
    }
    softLogout(): void {
        return useUser().onSoftLogout()
    }
    // optimistic logout
    optimisticLogout() {
        this.setLoggedIn(false)

        try {
            this.logout()
        } catch (error: any) {
            const errorService = ErrorService.getInstance()

            errorService.preventDefault(error)
            this.setLoggedIn(true)
        }
    }
    authRequired(sessionExpired: boolean = false) {
        return useUser().onAuthRequired(sessionExpired)
    }
    authSessionError() {
        return useUser().onAuthSessionError()
    }
    depositRequired() {
        return useUser().onDepositRequired()
    }
    getUserId() {
        return useUser().id_account
    }
    getBalance() {
        return useUser().onGetBalance()
    }
    getMinStakeFXD() {
        return useUser().currency_settings?.min_stake_fxd
    }
    getMinStakeACC() {
        return useUser().currency_settings?.min_stake_acc
    }
    getNextRacePostTime() {
        return useUser().next_race?.post_time || null
    }
    getToken() {
        return useUser().token
    }
    /* Mutations */
    setLoggedIn(status: boolean) {
        return useUser().onSetLoggedIn(status)
    }
    setLoggedInNode(status: boolean) {
        return useUser().onSetLoggedInNode(status)
    }
    setUserBalance(balance: OBR.User.Balance | OBR.WebSockets.BalanceInfo) {
        return useUser().onSetUserBalance(balance)
    }
    setUserSettings(settings: OBR.Settings.Payload) {
        return useUser().onSetUserSettings(settings)
    }
    setToken(token: string) {
        return useUser().setToken(token)
    }
    setLanguage(language: string) {
        return useUser().setLanguage(language)
    }
}

export const userStoreService = new UserStoreService()
