import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'

/**
 * App resource
 */
export class AppResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/bootstrap`
    private readonly ssrPath: string = `${HTTP_REST_V2}/ssr`

    /**
     * Get bootstrap
     */
    public async getBootstrap(): Promise<OBR.App.BootstrapResponse> {
        try {
            const res = await http.get(this.apiPath)
            return this.parseBootstrap(res)
        } catch (err: any) {
            errorLogger.createException(err)
            throw err
        }
    }

    public async getSSRBootstrap(): Promise<OBR.App.InitialSSRData> {
        try {
            const response = await http.get(this.ssrPath)
            return response.data
        } catch (error: any) {
            errorLogger.createException(error)
            throw error
        }
    }

    /**
     * Parse bootstrap
     * @param res API response
     */
    private parseBootstrap(
        res: OBR.Http.AjaxResponse<OBR.App.BootstrapResponse>
    ): OBR.App.BootstrapResponse {
        return { ...res.data }
    }
}
