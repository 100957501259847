import { defineStore } from 'pinia'

import { i18n } from '@obr-core/i18n/i18n'
import { I18nService } from '@obr-core/services/I18nService'
import { I18nResource } from '@obr-core/resources/I18nResource'
import { getBaseModules } from '@obr-core/helpers/i18n.helpers'
import { useUser } from '@obr-core/store/user'

import { initialState } from './state'

export const useI18n = defineStore('obr-store/i18n', {
    state: initialState,
    actions: {
        setModulesLoading(status: boolean) {
            this.modules_loading = status
        },
        setLocaleLoading(status: boolean) {
            this.locale_loading = status
        },
        setCountries(countries: Record<string, string>) {
            this.countries = countries
        },
        loadBaseModules() {
            const userStore = useUser()
            const i18nService = I18nService.getInstance()

            const modules = i18nService.filterUnloaded(
                getBaseModules(userStore.logged_in)
            )

            return this.loadModules({ modules })
        },
        async loadBaseLocale() {
            const userStore = useUser()

            this.setLocaleLoading(true)
            let error = null

            try {
                const i18nService = I18nService.getInstance()
                const resource = new I18nResource()
                const response = await resource.loadDateLocaleFile(
                    userStore.locale
                )

                i18nService.setDateLocale(
                    response.default as unknown as OBR.I18n.DateLocale
                )
            } catch (err) {
                error = err
            }

            this.setLocaleLoading(false)

            if (error) {
                throw error
            }
        },
        async loadModules({ modules }: { modules: OBR.I18n.Module[] }) {
            this.setModulesLoading(true)

            let error = null

            try {
                const i18nService = I18nService.getInstance()
                const resource = new I18nResource()
                const locale = i18nService.getLocale()

                const messages = await resource.loadModules(locale, modules)

                // Get already set messages
                const messagesSet = i18n.global.getLocaleMessage(locale)

                // Update cache
                i18nService.updateCachedMessages(messages)

                // Merge and set new messages on plugin
                i18n.global.setLocaleMessage(locale, {
                    ...messagesSet,
                    ...messages,
                })

                // What we have setup now is wrong as TS complains that i18n.global.locale is in legacy mode
                // while it is not. This leads to confusion and errors which devs are not event aware of.
                //
                // Locale is set with .value in non-legacy mode
                // https://vue-i18n.intlify.dev/guide/essentials/scope.html
                ;(i18n.global.locale as any).value = locale

                // check if countries object is empty and if so set it
                if (Object.keys(this.countries).length === 0) {
                    const allMessages = i18n.global.getLocaleMessage(
                        locale
                    ) as any
                    if (allMessages?.generic?.countries) {
                        this.setCountries(allMessages.generic.countries)
                    }
                }
            } catch (err) {
                error = err
            }

            this.setModulesLoading(false)

            if (error) {
                throw error
            }
        },
    },
})
