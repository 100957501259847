/**
 * WebSockets enabled
 */
export const WS_ENABLED: boolean =
    import.meta.env.VUE_APP_WEBSOCKETS_ENABLED === 'true'

/**
 * WebSockets host
 */
export const WS_HOST: string = import.meta.env.VUE_APP_WEBSOCKETS_HOST

/**
 * WebSocket port
 */
export const WS_PORT: string = import.meta.env.VUE_APP_WEBSOCKETS_PORT

/**
 * Secure connection protocol
 */
export const WS_SECURE_PROTOCOL: string = 'https://'

/**
 * Reconnection delay in seconds
 */
export const WS_RECONNECT_DELAY: number = 12000

/**
 * Maximum reconnection attempts
 */
export const WS_MAX_RECONNECT_ATTEMPTS: number = 100

/**
 * Options to be passed as parameters to the connection
 */

// Set timestamp for connection event
// export const CHANNEL: any = null

// // Number of reconnection attempts
// export const RECONNECTION_ATTEMPTS: number = 10

// // Delay reconnection in seconds
// export const RECONNECTION_DELAY: number = RECONNECT_DELAY

// // Maximal reconnection delay
// export const RECONNECTION_DELAY_MAX: number = 600000

// // Set connection security
// export const SECURE: boolean = true

// // Set timestamp for connection event
// export const TIMESTAMP: any = null

/**
 * Transport protocol
 * Default - websocket, fallback - polling
 */
export const WS_TRANSPORTS: string[] = ['websocket', 'polling']

/**
 * Join balance channel
 */
export const WS_JOIN_BALANCE_CHANNEL: boolean =
    import.meta.env.VUE_APP_JOIN_BALANCE_CHANNEL === 'true'

/**
 * Channel Types for user_private_ websocket channel
 */
export const enum UserPrivateChannel {
    BALANCE_INFO = 'node_balanceInfo',
    BET_STATUS = 'node_betStatus',
}
