import {
    Brand,
    BuildClient,
    BuildTarget,
    RBEnv,
    Product,
} from '../../../scripts/env.scripts'
export {
    Brand,
    BrandAlias,
    BuildClient,
    BuildTarget,
    RBEnv,
    Product,
} from '../../../scripts/env.scripts'

/**
 * RaceBets server environment
 */
export const ENV_RB = import.meta.env.VUE_APP_RB_ENV as RBEnv

/**
 * Current brand built
 */
export const ENV_BRAND = import.meta.env.VUE_APP_BRAND as Brand

/**
 * Current target build.
 */
export const ENV_TARGET = import.meta.env.VUE_APP_TARGET as BuildTarget

/**
 * Current client build.
 */
export const ENV_CLIENT = import.meta.env.VUE_APP_CLIENT as BuildClient

/**
 * Product: horse-racing or million-game
 */
export const ENV_PRODUCT = import.meta.env.VUE_APP_PRODUCT as Product
