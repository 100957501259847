import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { FormguideTabsType } from '@obr-ui/components/Formguide/config'

export class FormguideResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/races/formguide`

    public async getBySubject(
        subjectId: string,
        raceId?: string,
        formTab: FormguideTabsType = FormguideTabsType.OVERVIEW
    ) {
        try {
            const resource = this.convertRequestResource(formTab)
            const { data } = await http.get(
                `${this.apiPath}/${subjectId}/${resource}${
                    raceId ? `/${raceId}` : ''
                }`
            )

            return data as OBR.Formguide.Formguide
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    public async getRaceBySubject(
        subjectId: string,
        raceId: string
    ): Promise<OBR.Formguide.FormRace> {
        try {
            const { data } = await http.get(
                `${this.apiPath}/${subjectId}/race/${raceId}`
            )

            return data
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    public async getDuelOpponents(
        subjectId: string,
        searchTerm: string
    ): Promise<OBR.Formguide.SearchHorseResult[]> {
        try {
            const { data } = await http.get(
                `${this.apiPath}/${subjectId}/duelsearch?name=${searchTerm}`
            )

            return data
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    public async getDuelOpponent(
        subjectId: string,
        opponentSubjectId: string,
        raceId: number | string
    ): Promise<OBR.Formguide.DuelsResponse> {
        try {
            const { data } = await http.get(
                `${this.apiPath}/${subjectId}/duels/${
                    raceId ? raceId : '1'
                }/${opponentSubjectId}`
            )

            return data
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    private convertRequestResource(formTab: FormguideTabsType): string {
        if (formTab === FormguideTabsType.ANTE_POST) {
            return 'antepost'
        }

        return formTab
    }
}
