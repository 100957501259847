import { defineStore } from 'pinia'

import { initialState } from './state'

export const useUi = defineStore('obr-store/ui', {
    state: initialState,
    actions: {
        setTabletPortrait(status: boolean) {
            this.tabletPortrait = status
        },
        setTabletLandscape(status: boolean) {
            this.tabletLandscape = status
        },
        setLaptop(status: boolean) {
            this.laptop = status
        },
        setDesktop(status: boolean) {
            this.desktop = status
        },
        setHeaderHeight(height: number) {
            this.headerHeight = height
        },
        setSubHeaderHeight(height: number) {
            this.subHeaderHeight = height
        },
        setIframeHeight(height: number) {
            this.iframeHeight = height
        },
        setIframeTop(top: number) {
            this.iframeTop = top
        },
        setIframeScroll(scroll: number) {
            this.iframeScroll = scroll
        },
        setLastDrawerOpened(drawer: 'left' | 'main' | 'right') {
            this.lastDrawerOpened = drawer
        },
        setScrollMap(key: 'left' | 'main' | 'right', value: number) {
            this.scrollMap[key] = value
        },
        setIsUIResizing(status: boolean) {
            this.isUIResizing = status
        },
        setIsRouteChanging(status: boolean) {
            this.isRouteChanging = status
        },
    },
    getters: {
        isTabletPortrait(state) {
            return state.tabletPortrait
        },
        isTabletLandscape(state) {
            return state.tabletLandscape
        },
        isLaptop(state) {
            return state.laptop
        },
        isDesktop(state) {
            return state.desktop
        },
    },
})
