import { init, postMessage } from '@obr-core/lib/post-messages.manager'

/**
 * Post message service
 */
class PostMessageService {
    private messenger: OBR.PostMessages.Messenger = {}

    constructor() {
        init(this.publish, this.messenger)
    }

    /**
     * Add a message Listener
     * @param messageId
     * @param cb
     */
    public addListener(
        messageId: string,
        cb: (data: OBR.Common.Object<any>) => void
    ) {
        messageId = messageId.toLowerCase()

        if (!this.messenger[messageId]) {
            this.messenger[messageId] = []
        }

        if (!this.messenger[messageId].find(cb)) {
            this.messenger[messageId].push(cb)
        }
    }

    /**
     * Remove a message listener
     * @param messageId
     * @param cb
     */
    public removeListener(
        messageId: string,
        cb: (data: OBR.Common.Object<any>) => void
    ) {
        messageId = messageId.toLowerCase()

        if (this.messenger[messageId]) {
            const index = this.messenger[messageId].indexOf(cb)
            if (index >= 0) {
                this.messenger[messageId].splice(index, 1)
                if (!this.messenger[messageId].length) {
                    delete this.messenger[messageId]
                }
            }
        }
    }

    /**
     * Send a postMessage
     * @param messageId
     * @param payload
     */
    public send(messageId: string, payload: any) {
        postMessage(messageId, payload)
    }

    /**
     * Publish a message event
     * @param event
     * @param messenger
     */
    private publish(
        event: MessageEvent,
        messenger: OBR.PostMessages.Messenger
    ) {
        const messageId = event.data.messageId.toLowerCase()
        const ref = messenger[messageId]

        if (ref) {
            ref.forEach((cb: (data: any) => void) => {
                cb(event.data)
            })
        }
    }
}

export const postMessageService = new PostMessageService()
