import { I18N_DEFAULT_LOCALE } from '@obr-core/config/i18n'
import {
    getCachedMessages,
    mapLocaleToLanguage,
    storeMessages,
} from '@obr-core/helpers/i18n.helpers'

/**
 * I18n Service
 * Manage cached hashmap and modules.
 * Stores locale and date locale settings
 */
export class I18nService {
    private static instance: I18nService
    private locale: OBR.I18n.Locale
    private dateLocale: OBR.I18n.DateLocale | undefined
    private cachedModules: OBR.I18n.Module[] = []

    private constructor(locale: OBR.I18n.Locale) {
        this.locale = locale
        I18nService.instance = this
    }

    public static getInstance(
        locale: OBR.I18n.Locale = I18N_DEFAULT_LOCALE
    ): I18nService {
        if (I18nService.instance === undefined) {
            I18nService.instance = new I18nService(locale)
        }
        return I18nService.instance
    }

    /**
     * Get detected locale
     */
    public getLocale(): OBR.I18n.Locale {
        return this.locale
    }

    /**
     * Get language from locale
     */
    public getLanguage(): OBR.I18n.Locale {
        return mapLocaleToLanguage(this.locale)
    }

    /**
     * Return list of modules required to render components
     * @param modules Any i18n module arrays
     */
    public getRequiredModules(modules: OBR.I18n.Module[]): OBR.I18n.Module[] {
        // Get a unique list of matching modules
        const modulesRequested = Array.from(new Set([...modules]))
        return this.filterUnloaded(modulesRequested)
    }

    /**
     * Get date-fns format locale
     */
    public getDateLocale() {
        return this.dateLocale
    }

    /**
     * Set date-fns format locale
     * @param locale OBR.I18n.DateLocale
     */
    public setDateLocale(locale: OBR.I18n.DateLocale): void {
        this.dateLocale = locale
    }

    /**
     * Set or update list of cached modules
     * @param modules
     */
    public setCachedModules(modules: OBR.I18n.Module[]): void {
        this.cachedModules = modules
    }

    /**
     * Update cached messages in storage
     * @param messages
     */
    public updateCachedMessages(
        messages: OBR.I18n.LocaleMessages<string>
    ): void {
        const cachedMessages = getCachedMessages(this.locale)
        storeMessages(this.locale, {
            ...cachedMessages,
            ...messages,
        })
        this.setCachedModules([
            ...this.cachedModules,
            ...(Object.keys(messages) as OBR.I18n.Module[]),
        ])
    }

    /**
     * Save preferred locale
     * @param locale
     */
    public storeLocale(locale: OBR.I18n.Locale): void {
        this.locale = locale
    }

    /**
     * Return modules not loaded yet (not cached)
     * @param modules Modules needs to be loaded
     */
    public filterUnloaded(modules: OBR.I18n.Module[] = []): OBR.I18n.Module[] {
        return modules.filter((module: OBR.I18n.Module) => {
            return !this.cachedModules.includes(module)
        })
    }

    /**
     *  Returns timezone
     */
    public getTimezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
}
