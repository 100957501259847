/**
 * Brand settings object - passed from build process, defined in settings.json in app/BRAND_NAME folder
 */
export const BRAND_SETTINGS = import.meta.env.VUE_APP_BRAND_SETTINGS
    ? JSON.parse(import.meta.env.VUE_APP_BRAND_SETTINGS)
    : {}

/**
 * Fallback language in case given language was not found
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_FALLBACK_LANG = 'en'

/**
 * Help key name
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_HELP = 'help'

/**
 * Betting rules key name
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_BETTING_RULES = 'betting_rules'

/**
 * Rule 4 key name
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_RULE4 = 'rule4'

/**
 * Rule 4 key name
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_NON_RUNNER_NO_BET =
    'non_runner_no_bet'

/**
 * Is brand B2B
 */
export const BRAND_SETTINGS_IS_B2B = 'isB2B'

/**
 * Popular countries that are use in filtering upcoming races
 */
export const BRAND_SETTINGS_POPULAR_COUNTRIES = 'popular_countries'

/**
 * Offers key name
 */
export const BRAND_SETTINGS_EXTERNAL_LINKS_OFFERS = 'offers'

/**
 * HOST URI
 */
export const BRAND_SETTINGS_HOST_URI = 'host_uri'
